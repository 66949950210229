// Filter pill transitions
.pill-in
  transition: all .3s $easeOutBack .1s, margin-bottom .3s cubic-bezier(0.165, 0.840, 0.440, 1.000) .1s; /* easeOutQuart */
.pill-out
  transition: opacity .3s $easeInBack, transform .3s $easeInBack !important;
  position: absolute;
.pill-pop-move
  transition: all .3s $easeOutBack .2s
  &.pill-move-in
    transition: all .3s $easeOutBack
