.service-menu
  zero-list()
  +below($desktop)
    display: none;
.service-menu__item
  display: inline-block;
  &:last-child
    margin-left: 25px
    +below($wide)
      margin-left: 15px

.service-menu__link
  color $service_menu_text_color
  font-size: 1rem
  text-decoration: none;
  +below($wide)
    font-size: .75rem
  &:hover
    color #fff
    text-decoration: none;
