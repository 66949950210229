$openTransitionTime = 700ms
$revealTransitionTime = 300ms
$delay = 100ms
$mobile_break = 720px

.accessories-pop__overlay
  position: fixed;
  top 0
  bottom: 0
  left 0
  right: 0
  background-color: rgba(0,0,0,0)
  transition: background-color $revealTransitionTime ease-in
  display: none
  z-index: $accessories_overlay
  pointer-events: none;
  &.show-confirmation
    display: flex;
  &.show-dialog
    background-color: rgba(0,0,0,.6)
    pointer-events: all;
  &.close-dialog
    background-color: rgba(0,0,0,0)
    pointer-events: none;

  .row
    width 100%
    display flex
    justify-content space-between
    gap 8px

  .insurance-overview
    display flex
    flex-direction column
    padding 8px
    gap 8px
    border 1px solid #E0E0E0
    border-radius 4px
    background white
    margin-bottom 8px

    .row 
      justify-content flex-start

  .button
    height 48px

  .accessories-buttons__button
    background #514F4F

    &.accessories-buttons__button--continue
      background #FFFFFF
      border 2px solid #514F4F
      color #514F4F

      +mobile()
        display none

    svg
      display none

  .details
    display none
    gap 16px

    &.show
      display flex

  .back-button
    display flex
    gap 8px
    padding 0
    align-items center
    border none
    background inherit
    font-weight 800
    font-size 16px
    line-height 24px
    color $anchor_text_color
    fill $anchor_text_color

.accessories-pop
  max-height: 92vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: absolute;
  background-color: $success_color
  opacity: 0
  height: 55px
  transition: all $openTransitionTime cubic-bezier(0.645, 0.045, 0.355, 1), opacity $revealTransitionTime
  &.show-confirmation
    opacity: 1
  &.show-dialog
    width 700px
    height fit-content
    left calc(50vw - 350px)
    top 4vh
    background-color: #fff
    border-radius: 2px
    box-shadow: 0 5px 30px rgba(0,0,0,.3)
    +below($tablet)
      width 90vw
      left (50vw - (@width / 2))
      top 4vh
      box-shadow: 0 3px 15px rgba(0,0,0,3)
    +below($mobile)
      top 4vh
  &.close-dialog
    opacity: 0
    transform scale3d(20%,20%,0)
    pointer-events: none;

  .overview
    .accessory-card,
    .insurance-card
      .accessory-brand,
      .rating
        +mobile()
          display none

  .details .accessory-atb
    +mobile()
      overflow hidden
      box-shadow 0px -2px 4px -2px rgba(36, 36, 36, 0.1)
      height 72px
      position absolute
      justify-content center
      bottom 0
      left 0
      right 0
      background white
      z-index 10
      padding 10px 12px

.accessories-pop__header
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $success_color
  border-top-left-radius: 2px
  border-top-right-radius: 2px
  color: #fff
  font-size 1.4rem
  transition: all $openTransitionTime ease
  .show-dialog &
    padding: 0 20px
    justify-content: space-between
    background-color: #fff
    border-bottom: solid 1px rgba(0,0,0,.2)
    box-shadow 0 3px 10px -3px rgba(0,0,0,0.3)
    color: $success_color
    height 85px !important
    +below($mobile)
      height: 70px !important
      font-size: 1.2rem

.accessories-pop__inner
  height: 100%
  display: flex;
  align-items: center;

.accessories-pop__header__img
  box(0px)
  opacity: 0
  transition: opacity $revealTransitionTime ease $delay
  .show-dialog &
    box(64px)
    +below($mobile)
      box(56px)
  &.reveal-content
    opacity: 1

.accessories-pop__header__label
  line-height 55px
  white-space: nowrap;
  opacity: 0
  max-width: 0
  transition: all $openTransitionTime
  .show-dialog &
    opacity: 1
    margin-left: 20px
    max-width: 400px;

.accessories-pop__tick
  box(30px)
  margin: 10px
  svg
    box(100%)
    stroke #fff
    stroke-width 2.5
    stroke-dasharray: 50
    stroke-dashoffset: 50
    transition: stroke-dashoffset 800ms ease-out 200ms, stroke $revealTransitionTime
  +below($mobile)
    box(42px)
  &.draw
    svg
      stroke-dashoffset: 0
  &.reveal-content
    svg
      stroke: $success_color


.accessories-pop__header__close
  opacity: 0
  box(0, 30px)
  cursor: pointer;
  overflow: hidden;
  transition: all $revealTransitionTime ease 200ms
  svg
    fill $dark_gray
    box(16px)
  &.reveal-content
    box(30px)
    opacity: .8
    &.header-image
      width: 74px;
      height 74px;

.hidden
  display:none!important;

.accessories__list__heading
  font-size: 1.1rem
  margin: 0
  pointer-events: none;

.accessories__list
  zero-list()
  margin-top: 10px
  border-top: solid 1px rgba(0,0,0,.12)

.accessories__item
  display: flex;
  justify-content: space-between;
  padding: 10px 0
  border-bottom: solid 1px rgba(0,0,0,.12)
  +below($mobile)
    flex-wrap: wrap;
    border-bottom: none

.accessories__item__thumb
  margin-right 15px
  +below($mobile)
    margin-right 0px
    box(65px)

.accessories__item__text-container
  width 450px
  +below($tablet)
    width calc(100% - 95px - 142px)
  +below($mobile)
    width: calc(100% - 75px);

.accessories__item__name
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  +below($mobile)
    font-size: .9rem

.accessories__item__description
  font-size .9rem
  margin-top 14px
  color rgba(0,0,0,.7)
  +below($mobile)
    font-size: .8rem

.accessories__item__price-container
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  width: 150px
  gap: 8px
  +below($mobile)
    width: 100%;
    flex-direction: row
    justify-content: space-between;
    align-items: center;
    margin-top: 10px

.accessories__item__price-inner
  text-align: right;
  +below($mobile)
    text-align left

.accessories__item__price
  font-size: 1.4rem
  +below($mobile)
    font-size 1.2rem

.accessories__item__delivery
  text-align: right;
  font-size: .8rem
  color: rgba(0,0,0,0.7)
  +below($mobile)
    text-align left
  .in-stock
    display: none
  .fa
    display: none

.accessories__item-button
  margin-top: 15px
  flex-shrink: 0
  *
    pointer-events none
  > span
    transition: opacity 200ms
    display: inline-block;
  +below($mobile)
    margin: 0
  &.adding
    > span
      opacity: 0
  &.added
    background-color: $success_color
    pointer-events none;
    > span
      opacity: 0

.accessories__item__tick
  position: absolute;
  top 3px
  left calc(50% - 8px)
  svg
    box(22px)
    stroke #fff
    stroke-width 2.1
    stroke-dasharray: 50
    stroke-dashoffset: 50
    transition: stroke-dashoffset 800ms ease-out 200ms, stroke $revealTransitionTime
  .adding &, .added &
    svg
      stroke-dashoffset: 0

.accessories-buttons
  &__wrapper
    opacity: 0
    background-color: #fff
    display: flex;
    border-top: solid 1px rgba(0,0,0,.2)
    width: 100%
    justify-content: center;
    align-items: center;
    padding 10px 12px
    gap 12px  
    transition: opacity $revealTransitionTime ease
    font-weight 800
    font-size 16px
    +below($tablet)
      font-size 14px
    .button
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 12px;
    >svg 
      width 13px
      height 20px
    &.reveal-content
      opacity: 1
      box-shadow 0 -3px 10px -3px rgba(0,0,0,0.3)
  &__button
    flex 1
    height 48px
    +below($tablet)
      height 36px
    &--continue
      color: $action_color;

