.favorites-widget
  box(80px)
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position relative
  &.active
    cursor: pointer;
  +below($desktop)
    box(40px, 50px)
    margin-right: 5px;
    transition: all 150ms
    .search-active &
      transform: scale(0);
      opacity: 0

.favorites-widget__icon
  box(50%)
  svg
    width 100%
    height 34px
    fill $favorites_widget_icon_color
    stroke $favorites_widget_icon_stroke_color
    stroke-width $favorites_widget_icon_stroke_width
    +below($desktop)
      visibility: hidden;
      height 50px
  .active &
    svg
      fill $favorites_widget_icon_color_active
      stroke $favorites_widget_icon_stroke_color_active
      visibility: visible;
    +below($desktop)
      height: $header_height_mobile;
      width: 32px;
      svg
        fill $favorites_widget_icon_color_mobile
        stroke $favorites_widget_icon_stroke_color_mobile

.favorites-widget__label
  display: block;
  text-align: center;
  color: $header_widget_text_color
  font-size: $header_widget_font_size
  font-weight: $header_widget_font_weight;
  line-height: 1.2
  .active &
    color $header_widget_active_text_color;
  +below($desktop)
    display: none

.favorites-widget__amount
  visibility: hidden;
  pointer-events: none;
  position: absolute;
  circle(22px)
  background-color: $action_color
  color: $action_text_color
  text-align: center;
  top 11px
  right 13px
  line-height: 22px
  .active &
    visibility: visible;
    animation-name: zoomIn;
    animation-duration: .4s;
  +below($desktop)
    circle(15px)
    line-height: 15px
    top 9px
    right 2px
    font-size: .6rem
    background-color: $header_widget_amount_background_color
    color: $header_widget_amount_text_color

//Animation
@keyframes zoomIn
  0%
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  50%
    opacity: 1;
