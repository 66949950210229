.cof__summary
  width: 100%
  background-color: $cof_section_footer_bg
  padding: 40px 30px
  border: $cof_section_border_darker
  +below($mobile)
    padding: 30px 8px 20px


.cof__summary-inner
  lost-center $cof_center_col_width
  .summary-row
    display: flex
    width: 100%
    justify-content: space-between;
    color: $cof_li_item_font_color
    font-size .875rem
    padding: 2px 0
    align-items baseline
    &.product-discount
      font-weight 700
      color: $cof_price_color
    &.total-row
      margin-top: 8px
      padding-top: 8px
      font-size 1rem
      align-items: baseline;
    .cof__price-tax-label
      text-align: right;

.cof__summary-product
  width 100%
  border-bottom $cof_summary_line
  padding-bottom 10px
  margin-bottom 10px

.cof__summary-product-name
  font-weight bold
  text-transform uppercase