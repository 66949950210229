.vip-A 
  display: none !important;

.toc-description-outer
  @media(max-width: 1199px){
    width: 100%;
  }

.superspecs-toc
  margin: 5px 0 0 0!important
  padding-bottom: 0!important
  
.accessories-toc
  margin-bottom: 0!important;
  padding-bottom: 0!important;

.video-description-toc
  padding-bottom: 0!important;

.toc-nav
  border-bottom: 1px solid #fff;
  position: sticky;
  z-index: 4;
  background-color: #fff;
  width: 100%;
  transition: 0.5s
  top: 0;
  @media(max-width: 1023px){
    top: 56px;
  }

.toc-active
  border-bottom: 1px solid #E0E0E0
  box-shadow: 0 18px 14px -16px #e0e0e0
  
 .specifications-toc
  width: 100%

.toc-list
    width: $page_width
    padding 16px 0 2px 0
    list-style-type none
    gap: 36px
    display flex
    margin auto

    @media(max-width: 1199px){
        gap 36px
        width 100vw
        padding 16px 20px 2px 20px
        overflow scroll
        scroll-behavior smooth
        scrollbar-width none;  /* Firefox */
        
        &::-webkit-scrollbar {
            display: none;
        }
    }

    @media(max-width: 768px){
        gap 32px
    }

.toc-item a
    color: #838383
    font-size: 16px;
    font-weight: 800;
    text-transform: uppercase;
    white-space: nowrap;
    &:hover
      text-decoration: none

    @media(min-width 768px){
        font-size: 18px;
    }

@css {
    @media only screen and (hover: hover) {
        .toc-item a:hover {
            color: #000;
        }
    }
}

.toc-item.compareSection
    display: none

.active-section a
    transition-delay .2s
    border-bottom 4px solid $brand_color;
    color #000

.toc-gap
  @media(max-width:1024px) {
    padding:10px
  }

.toc-review-mobile
  @media(min-width 1024px) {
    display: none !important
  }

.toc-review-desktop
  @media(max-width 1023px) {
    display: none !important
  }

.menu-section
  padding: 48px 12px 0px 12px;   
  scroll-margin: 36px;
  @media(max-width 1024px){
    scroll-margin: 80px;
  }

.vip__reviews__outer-variantC
  background-color: #ececec;
  padding-top: 0;
  padding-bottom: 48px;
  @media(min-width 1024px){
  width: 100%;
  }

.vip__specifications__energy-variant-c
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  flex-grow: 2;
  +below($desktop)
    width: 75%;
    align-items: flex-start;
    align-self: center;
  img
    margin-top: 10px
    height: 550px;
    +below($desktop)
      height: 75%;

.vip__superspecs__outer-variant-c
  background-color: #fff;

.super-specs-variant-c
 @media(max-width 1023px) {
    display none !important
 }

.super-specs-variant-c-mobile
    @media(min-width 1024px) {
    display none !important
  } 

.vip__superspecs-toc
  sectionInner($vip_inner_section_border_color)
