.terms__wrapper
  pageWrapper(750px)

.terms__date
  color: #999;

.terms__section
  h2
    color: $brand_color
    font-size: 1.4rem
