$carousel-gutter = 7px;

.VueCarousel-wrapper
  padding-top: $carousel-gutter;
  padding-bottom: $carousel-gutter;

.VueCarousel-navigation-button
  display: flex;
  align-items: center;
  width: $content_slider_arrow_size !important
  height: $content_slider_arrow_size !important
  background-color: $content_slider_arrow_background !important
  padding: $content_slider_arrow_padding !important
  svg
    box(100%)
    fill: $content_slider_arrow_fill !important

.VueCarousel-navigation-button:focus
  outline: none !important;

.VueCarousel-navigation-prev
  +below($wide)
    left: 30px !important;

.VueCarousel-navigation-next
  +below($wide)
    right: 30px !important;

.VueCarousel-dot:focus
  outline: none !important;

.VueCarousel-dot
  background-color: $content_slider_dot_background_color_inactive !important;

.VueCarousel-dot--active
  background-color: $content_slider_dot_background_color_active !important;

.global__carousel-slide
  padding-left: $carousel-gutter;
  padding-right: $carousel-gutter;
  +below($mobile)
    padding-left: 35px;
    padding-right: 35px;

.VueCarousel .VueCarousel-inner .VueCarousel-slide
  user-select: auto;