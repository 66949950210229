
.wag-tabs
  position: relative;
  margin-bottom: 10px;

.wag-tabs__item
  display: inline-block;
  margin: 0 4px;
  padding: 6px 8px;
  letter-spacing: 0.8px;
  color: gray;
  text-decoration: none;
  border: none;
  background-color: transparent;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  transition: all 0.25s;
  font-size: .875rem;

.wag-tabs__item_active
  color: black;

.wag-tabs__item:hover
  border-bottom: 2px solid gray;
  color: black;

.wag-tabs__item:focus
  outline: none;
  border-bottom: 2px solid gray;
  color: black;

.wag-tabs__item:first-child
  margin-left: 0;

.wag-tabs__item:last-child
  margin-right: 0;

.wag-tabs__active-line
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  background-color: $brand_color;
  transition: transform 0.4s ease, width 0.4s ease;