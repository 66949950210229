.overlay-box-relative-parent
    position relative

.overlay-box
    position absolute
    top 0
    left 0
    z-index 5
    margin-left -10px
    margin-top 10px
    padding 10px 15px 0px
    background-color #fff
    box-shadow 2px 2px 8px rgba(0,0,0,.4)
    border 1px solid $gray_background_light
    border-radius 3px
    opacity 0
    transition opacity 350ms ease
    pointer-events none
    &:before
        content ''
        position absolute
        display block
        box(14px)
        transform: rotate(315deg);
        border solid 1px transparent
        border-right-color: $gray_background_light
        border-top-color: $gray_background_light
        background: #fff
        left 14px
        top -8px
    &.overlay-active
        opacity 1
        pointer-events all
    .overlay-box-close
        margin-top: 10px
        border-top 1px solid $gray_background_light
        text-align center
        display flex
        justify-content center
    .overlay-box-close-link
        font-size 0.75rem
        cursor pointer
        padding 10px
