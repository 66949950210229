.vip__specifications__outer
  sectionOuter(#fff)
  -webkit-tap-highlight-color: rgba(0,0,0,0)
  +below($desktop)
    display: none

.vip__specifications
  sectionInner($vip_inner_section_border_color)

.vip__specifications__container
  display flex
  justify-content space-between
  +below($desktop)
    flex-direction column


.vip__specifications__table
  flex-grow: 5;
  +below($desktop)
    width: 100%;

.vip__specifications__sheet
  float: right;

.vip__specifications__energy
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  flex-grow: 2;
  +below($desktop)
    width: 100%;
    align-items: flex-start;

  img
    margin-top: 10px
    height: 550px;

.vip__specifications__header
  width: 100%;
  padding: 6px 10px;
  font-weight: bold;

.vip__specifications__list
  margin-bottom: 22px;

.vip__specifications__line
  display: flex;
  padding: 6px;
  position relative
  &:nth-child(odd)
    background: $specifications_row_color
  +below($wide)
    font-size: 0.9rem

.vip__specifications__description
  display: flex;
  align-items: center;
  width: 50%
  padding-left 7px
  +below($tablet)
    width: 65%

.vip__specifications__value
  width: 50%
  +below($tablet)
    width: 35%

.vip__specifications__manual
  display: flex;
  margin-top: 30px;
  padding-bottom: 10px;

  svg
    width: 70px;
    height: 70px;

.vip__specifications__manual__text
  display: flex;
  flex-direction: column;
  padding-left: 26px;
  padding-top: 12px;

.vip__specifications__manual__header
  font-weight: bold
