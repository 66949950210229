.cof__order
  background-color: $cof_section_bg

.cof__products
  border-left $cof_section_border
  border-right: $cof_section_border
  padding: 30px 50px
  width: 100%
  +below($page_width)
    padding: 30px
  +below($mobile)
    padding: 5px

.cof__products-table
  width: 100%
  position relative
  th
    padding: 10px 0 10px
    text-transform uppercase
    font-size .7rem
    color: rgba(0,0,0,.4)
    font-weight normal
    white-space nowrap
    border-bottom none
    +below($mobile)
      padding-bottom 5px
  tr
    background-color: $cof_prod_table_bg
    border-top: $cof_section_border
    &:last-child
      border-bottom: $cof_section_border
    &:nth-child(2n)
      background-color: $cof_prod_table_bg
  thead tr
    border-top: none
  td
    padding 0

  .number-cell
    text-align right
    font-size .9rem
    +below($mobile)
      font-size .875rem


.cof__products-item-price-heading
.cof__products-price-heading
  text-align right

.cof__products-item-price-heading
  +below(750px)
    display: none

th.cof__products-item-amount-heading
th.cof__products-price-heading
  +below($mobile)
    padding-right 10px

tr.cof__product-out-of-row
  border-top: none
  td
    padding: 10px 20px 10px 0
    font-size .7rem
    text-transform uppercase
    color: rgba(0,0,0,.4)


.cof__product-out-of-label
  background-color: $action_color
  color #fff
  margin-left: 3px
  padding: 0 2px


.cof__product-wrap
  display: flex
  max-width: 400px
  align-items: flex-start;
  +below($mobile)
    img
      box(60px)

.cof__product-plus
  display: inline-block;
  padding: 0 40px 0 20px
  line-height: 80px
  vertical-align: top;
  +below($desktop)
    display: none

.cof__product-img-wrap
  padding: 15px
  display block
  background-color $cof_prod_img_bg
  border-left $cof_prod_img_border_left
  border-right $cof_prod_img_border_right
  position relative
  img
    box(80px)
  +below($mobile)
    padding 15px 5px
    img
      box(45px)

.cof__product-info
  margin-left: 10px
  max-width: 250px
  display: flex
  flex-direction: column;
  padding: 15px 0
  +below($mobile)
    margin-left 10px
    max-width 110px
    overflow hidden
    font-size .9rem


.cof__product-brand
  font-family: $font_family_header
  font-weight 300
  text-transform: uppercase;
  margin-bottom: 0
  a
    color #888

.cof__product-brand__acc
  font-family $font_family_base
  color #888

.cof__product-acc-label
  font-family: $font_family_base
  margin-bottom: 0
  color #888
  text-transform: uppercase;
  white-space nowrap

.cof__product-count
  background-color: $action_color
  font-size .65rem
  color #fff
  padding: 1px 6px
  border-radius: 3px
  align-self: flex-start;

.cof__product-model
  font-size: .8rem
  margin-bottom 8px
  a
    color $body_text_color
  +below($mobile)
    margin-bottom 0

.cof__product-stock
  color: $in_stock_color
  font-size: .7rem
  &.not-in-stock
    color $not_in_stock_color
  +below($mobile)
    display none

.cof__product-soldby
  font-size .7rem
  margin-top 4px
  +below($mobile)
    display none

.cof__product-accesory-link
  font-size .7rem
  margin-top 10px
  text-decoration: underline;

.cof__product-amount-wrapper
  position: relative;
  width 60px
  white-space nowrap
  input
    width: 40px
  +below($mobile)
    width 30px


.cof__product-amount
  appearance none
  text-align: right;
  border-radius: 3px
  border $cof_section_border
  padding: 3px 6px 3px
  color rgba(0,0,0,.7)
  width: 100%
  -moz-appearance: textfield
  &::-webkit-inner-spin-button
    display none
  +below($mobile)
    padding: 2px 6px
    font-size .8rem

.cof__product-discount
.cof__product-item-price
  +below(750px)
    display: none

.cof__product-discount
  color $cof_price_color
.cof__products__mobile-price
  color $cof_price_color
  display block
  font-size .7rem
  line-height 1
  +above($tablet)
    display none

.cof__product-delete
  display: inline-block;
  width: 40px
  color: $cof_prod_delete_color
  font-weight bold
  font-size 1.2rem
  cursor: pointer;
  opacity .5
  transition opacity 150ms
  padding-right 8px
  &:hover
    opacity 1
  +below($page_width)
    width: 30px
  +below($mobile)
    width 20px
    padding-right 5px