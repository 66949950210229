.vip__accessories__outer
  sectionOuter(#fff)
  align-items: baseline
  justify-content: flex-start;
  width 100%

  .read-more-link
    margin 0
    padding 4px 0

  .accessory-image img
    padding 0

  .glide__slide .content > *
    gap 4px

  .content    
    .accessory-atb button
      height 48px

  .glide__arrow
    border none
    background inherit

  .glide__slide
    .header
      flex-direction column
      align-items stretch

    .accessory-image
      flex 0

    h3
      font-size 18px

      +mobile()
        font-size 16px

  +below($mobile)
    .accessories-carousel[data-mobile-slides="hide"]
      .glide__arrows, .glide__bullets
        display none

  +below($tablet)
    .accessories-carousel
      width 100%

    .glide__arrows
      display none

    +above($mobile + 1)
      .accessories-carousel[data-tablet-slides="hide"]
        .glide__bullets
          display none

  +above($tablet + 1)
    .accessories-carousel[data-desktop-slides="hide"]
      width 100%

      .glide__arrows, .glide__bullets
        display none

.vip__accessories__inner
  sectionInner(#fff)
  display flex
  flex-direction column

.vip__accessories__list__heading
  font-size: 1.8rem
  pointer-events: none;

.vip__accessories__list
  display: flex;
  flex-wrap: wrap;
  margin: -10px 0 0 -10px;
  +below($desktop)
    margin: 0;

.vip__accessories__item
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: calc(100%/4 - 10px);
  margin: 10px 0 0 10px;
  padding: 12px;
  border: solid 1px rgba(0,0,0,.12);
  +below($desktop)
    lost-column 1/1
    margin: 0 0 12px 0

.vip-expandable-text 
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  margin-top: 4px;
  margin-bottom: 12px;

.vip-expandable-text-fade {
  position: relative;
  height: 3rem;
}
.vip-expandable-text-fade:after {
  content: "";
  text-align: right;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 70%;
  height: 1rem;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), white 110%);
  pointer-events: none;
}

.vip__accessories__item__description
  display: flex
  cursor: pointer;

.vip__accessories__item__image
  margin-right 10px
  img
    max-width: 80px

.vip__accessories__item__text
  font-size: .75rem;
  line-height: 1rem;
  overflow hidden;
  h3
    margin-top: 0;
    margin-bottom: 0;
    font-size: .9rem;
    line-height: 1.2rem;

.vip__accessories__item__price-container
  align-items: baseline;
  display: flex;
  justify-content: right;
  margin-right: 12px;

.vip__accessories__item__price
  font-size: 1rem
  font-weight: 700
  margin-right: 16px

.vip__accessories__item-button
  *
    pointer-events none
  > span
    transition: opacity 400ms ease 300ms
    display: inline-block;
  +below($mobile)
    margin: 0
  &.adding
    > span
      opacity: 0
  &.added
    background-color: $success_color
    pointer-events none;
    > span
      opacity: 0

.accessories__modal-button
  flex-shrink: 0
  +above($mobile)
    margin-top: 12px;

.vip__accessories__item__tick
  position: absolute;
  top 3px
  left calc(50% - 8px)
  svg
    box(22px)
    stroke #fff
    stroke-width 2.1
    stroke-dasharray: 50
    stroke-dashoffset: 50
    transition: stroke-dashoffset 600ms ease-out 200ms, stroke 300ms
  .adding &, .added &
    svg
      stroke-dashoffset: 0

.vip__accessories__expandable-trigger
  align-items: center;
  color: $anchor_text_color
  cursor: pointer
  display: flex;
  margin: 12px 0 24px 10px
  width: 100%;
  +below($desktop)
    margin: 0 0 24px 0;

.vip__accessories__expandable-trigger__hidden
  display: none;

.vip__accessories__expandable-block
  width 100%
  visibility: hidden;
  max-height: 0
  opacity: 0;
  display: flex;
  flex-wrap: wrap;
  transition:all 0.3s ease;
  overflow:hidden;
  &.show-section
    opacity: 1;
    visibility: visible;
    max-height: 1200px
    +below($tablet)
      max-height: 3000px

.vip__accessories__icon
  font-size: 1.4rem;
  border: 1px solid #3f92da;
  padding: 0 3px;
  line-height: 1;
  display: inline-block;
  border-radius: 6px;
  margin-right: 6px
