.srp__category__carousel-wrapper
.srp__category__carousel-header
  display: none

.srp__category__carousel-carousel
  .VueCarousel
    .VueCarousel-wrapper
      .VueCarousel-inner
        visibility: visible !important;

.srp__category__carousel
  padding: 0 0 0 0
  +below($tablet)
    display:none;
    
  .srp__category__carousel-header
    lost-center $page_width
    align-items: flex-end;
    padding-top: 24px;
    margin: 0 0 0 8px;
    display:block;
    +below($wide)
      padding: 0 15px
    +below($mobile)
      padding: 0 10px
    h2
      margin: 0 10px 8px 0
      [tooltip]::after
        width: 390px;
    span
      +below($tablet)
        display: none;

  .srp__category__carousel-wrap
    display: flex;
    width: "calc(100% + (2 * %s))" % $srp_gallery_spacing
    margin: 0 auto;
    +below($wide)
      width: 100%

  .srp__category__carousel-carousel
    width: 100%
    +below($wide)
      width: 100%
    .VueCarousel
      .VueCarousel-pagination
        .VueCarousel-dot-container
          margin: 0 0 16px 0 !important
          .VueCarousel-dot
            margin-top: 0 !important
          .VueCarousel-dot--active
            margin-top: 0 !important

  .srp__category__carousel-highlight
    position: relative;
    display: flex;
    width: 24%
    margin-left: 1%;
    height: 100%
    margin-top: 8px;
    img
      height: 100%;
      width: 100%;
    +below($wide)
      display: none

  .srp__category__carousel-grid-image-label
    background: $brand_color;
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    font-size: 1.15rem;
    color: #fff;
    opacity: .85;
    width: 100%;
    text-align: center;
    text-transform: none;

  .category__carousel__product__card-image img
    max-height: 129px
    +below($tablet)
      max-height: 140px;

  .category__carousel__product__card-specs
    display: none;

  .category__carousel__product__card-bottom
    height: auto
    margin-top: 0

  //Glide specific rules
  .category__carousel__glide__arrow--left,
  .category__carousel__glide__arrow--right

  .category__carousel__glide__bullets
    +below($tablet)
      display: flex;
      bottom: -10px;
