.altapay.container
  .wag-panel
    border 8px solid #DBDBDB
    margin-bottom 14px
    background #fff

    .wag-panel__header
      font-family: sans-serif;
      font-weight: bold;
      border-bottom 1px solid #DBDBDB
      margin 0
      padding 10px
      +below($tablet)
        font-size 18px

    .wag-panel__content
      padding 10px

  //Black border panel. Used to show a summary of an order
  .black-panel
    border-top 1px solid #B2B2B2
    border-right 1px solid #B2B2B2
    border-left 1px solid #B2B2B2
    background #fff

    .black-panel__header
      font-family: sans-serif;
      font-weight: bold;
      border-bottom 1px solid #B2B2B2
      margin 0
      padding 13px 14px 14px 14px
      +below($tablet)
        font-size 18px

      .black-panel__order-id
        font-size 12px
        float right
        font-weight normal
        margin-top 7px

    .black-panel__order-line
      border-bottom 1px solid #B2B2B2
      margin 0
      padding 14px

    .black-panel__order-total
      font-size 18px
      font-weight bold
