.vip__blog__outer
  width 100%

.vip__blog__banner
  background-color $gray_background
  background-image: url('//images.wagcdn.com/f/design/hverdagen-banner-bg-christmas.jpg')
  background-size cover
  background-position center center
  height 250px
  +below($tablet)
    height 180px

.vip__blog
  sectionInner($vip_inner_section_border_color)
  margin-top -180px
  +below($wide)
    padding 0px 30px
  +below($desktop)
    padding 0px 20px
  +below($tablet)
    margin-top -145px
  +below($mobile)
    padding 0px 10px
  .vip__blog__title
    margin-bottom 25px
    height 80px
    +below($tablet)
      height 60px
      margin-bottom 15px
  .vip__blog__posts
    display flex
    justify-content center
    +below($tablet)
      flex-direction column
  .vip__blog__post
    min-height 200px
    background-color #fff
    border 1px solid $gray_background
    padding 20px
    width 50%
    +below($tablet)
      padding 10px
      width 100%
    &.left__post
      margin-right 10px
      +below($tablet)
        margin-right 0
        margin-bottom 15px
    &.right__post
      margin-left 10px
      +below($tablet)
        margin 0
    a
      &:hover
        text-decoration none

  .blog__post__img
    position relative
    width 100%
    padding-top 54%
    background-size cover
    margin-bottom 15px
    &.loading::before
       content: "";
       circle(90px)
       position: absolute;
       box-sizing: border-box;
       left 50%
       top 50%
       margin-left -45px
       margin-top -45px
       border: 5px solid rgba(0, 0, 0, 0.3)
       border-top: 5px solid $brand_color
       animation: sweep 600ms infinite ease;

  .blog__post__title
    margin 0
    margin-bottom 20px
    line-height 1.2
    font-size 28px
    font-weight bold
    display block
    color $black
    +below($tablet)
      font-size 20px

  .blog__post__text
    margin 0
    display block
    color $black
    +below($tablet)
      font-size 14px

.vip__blog__button
  margin-top 30px
  text-align center
  +below($mobile_small)
    .button
      display inline-block
      min-width 80%
      margin 0 auto 10px
  a
    &:hover
      text-decoration none
