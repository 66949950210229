body
  min-height: 100vh
  width 100%
  &.modal-dialog-open, &.show-menu
    height 100vh
    overflow-x: hidden;
    position: relative;
    z-index $body_Z

img
  max-width 100%

.content-wrapper
  max-width: $page_width
  margin-left: auto
  margin-right: auto
  +below($wide)
    padding: 0 30px
  +below($desktop)
    padding: 0 20px
  +below($mobile)
    padding: 0 10px
