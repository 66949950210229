.replacement-container
  width: 100%;
  text-align: center
  background-color: $black
  color: #fff;
  padding: 30px;
  .srp__featured__product-highlight &
    padding: 10px;
    font-size: .875rem;
    min-height: 328px;
  h2
    margin-top: 0
    color: #fff;

.replacement-container__step
  display none
  &.active
    display: flex;
    flex-direction: column;
    align-items: center;
    .srp__featured__product-highlight &
      justify-content: center;
      height: 100%;

.replacement-container__form
  width: 500px;
  .srp__featured__product-highlight &
    width: 100%
  +below($tablet)
    width: 100%;
