sizes = 0 5 10 15 20 25 30 35 40 45 50 55 60 65 70 75 80 85 90 95
for num in sizes
  .mb-{num}
    margin-bottom: unit(num, px) !important
  .mt-{num}
    margin-top: unit(num, px) !important
  .ml-{num}
    margin-left: unit(num, px) !important
  .mr-{num}
    margin-right: unit(num, px) !important
  .pb-{num}
    padding-bottom: unit(num, px) !important
  .pt-{num}
    padding-top: unit(num, px) !important
  .pl-{num}
    padding-left: unit(num, px) !important
  .pr-{num}
    padding-right: unit(num, px) !important