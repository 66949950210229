.srp-top-filter
  width: 100%;
  /* If this goes live, this is the correct styling
  padding-bottom: 16px;
  border-bottom: 13px solid $neutral_lightest_color;
  margin-bottom: 10px;
  */
  visibility: hidden;
  pointer-events: none;
  height: 0;
  padding: 0;
  margin: 0;
  border: none;
  +above($desktop)
    display:none !important;

.srp-top-filter__content
  standardSection()
  display: flex;
  overflow-x: scroll;
  flex-direction: column;
  .button 
    margin-right: 6px;

.srp-top-filter__buttons
  display: flex;

.top-filter-button
  padding: 0 10px;
  font-size: .9rem;
  min-width: 90px;
  line-height: 42px;
  margin-right: 5px;
  border: 1px solid $neutral_light_color;
  background-color: rgba(255, 255, 255, 1);
  color: $black; 
  border-radius: $btn_border_radius;
  text-align center
  cursor: pointer;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  &.checked
    border-color: $brand_color