.basket-widget
  box(80px)
  border-radius: $header_widget_border_radius
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position relative
  &:hover
    text-decoration: underline;
    color $header_widget_icon_color
  &.full
    background-color: $header_widget_active_background_color
  +below($desktop)
    display: none;
    box(50px)
    transition: all 150ms
    &.full
      display: flex;
      background-color: transparent
    .search-active &
      transform: scale(0);
      opacity: 0

.basket-widget__icon
  box(50%)
  svg
    width 100%
    height 34px
    fill $header_widget_icon_color
    +below($desktop)
      height: 50px;
  .full &
    z-index 2
    svg
      fill $header_widget_active_icon_color
    &.do-animation
      animation basketIconAnimation 0.3s 1 cubic-bezier(0.445, 0.05, 0.55, 0.95)
    +below($desktop)
      height: $header_height_mobile;
      width: 32px;
      svg
        fill #fff

.basket-widget__label
  display: block;
  text-align: center;
  color: $header_widget_text_color
  font-size: $header_widget_font_size
  font-weight: $header_widget_font_weight;
  line-height: 1.2
  .full &
    color $header_widget_active_text_color
  +below($desktop)
    display: none

.basket-widget__amount
  display: none;
  position: absolute;
  circle(22px)
  background-color: $action_color
  color: $action_text_color
  text-align: center;
  top 11px
  right 13px
  line-height: 22px
  .full &
    display: block;
    z-index 3
    &.do-animation
      animation basketAmountAnimation 0.75s 1 cubic-bezier(0.445, 0.05, 0.55, 0.95)
  +below($desktop)
    circle(15px)
    line-height: 15px
    top 9px
    right 5px
    font-size: .6rem
    background-color: $header_widget_amount_background_color
    color: $header_widget_amount_text_color

@keyframes basketIconAnimation
  50%
    transform scale(0.85)
@keyframes basketAmountAnimation
  0%
    right 26px
    margin-top 8px
    transform scale(0.5)
    z-index 1
  20%
    right 26px
    margin-top 8px
    transform scale(0.5)
    z-index 1
  60%
    margin-top -15px
    transform scale(1.2)
    z-index 1
