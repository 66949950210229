.blog__page
  .main-content
    background-color: #f3f3f3;

.blog__container
  position relative
  width $page_width
  max-width 100%
  margin 0 auto 40px
  +below($wide)
    width: 100%;
    padding 0 30px
  +below($desktop)
    padding 0 20px
  +below($mobile)
    padding 0 10px

.blog__main-headline
  max-width: $page_width
  margin: 20px auto;
  font-size 2rem
  +below($tablet)
    margin: 20px auto 10px;
    font-size 1.5rem
  +below($mobile)
    font-size 1.3rem

.blog__second-headline
  +below($tablet)
    font-size 1.2rem
  +below($mobile)
    font-size 1rem

.blog__main-content
  lost-center 100%

.blog__left-col
  lost-column 3/4 2 30px
  +below($desktop)
    lost-column 3/4 2 20px
  +below($tablet)
    lost-column 1/1

.blog__right-col
  lost-column 1/4 2 30px
  +below($desktop)
    lost-column 1/4 2 20px
  +below($tablet)
    lost-column 1/1
    text-align center
    margin-top: 30px

.blog__featured
  lost-center $page_width
  display block
  box-shadow: 1px 3px 8px rgba(0,0,0,.2)
  margin-bottom: 30px
  border-radius: 2px
  padding-bottom: 8px
  overflow: hidden;
  background-color: #fff
  transition all 0.2s ease-in
  &:hover
    text-decoration: none;
    transform: scale(1.03);
  +below($desktop)
    margin-bottom: 20px
  +below($mobile)
    margin-bottom: 15px
  a
    color $body_text_color
    text-decoration none
  img
    width: 100%


.blog__grid
  lost-flex-container: row;
  a
    color $body_text_color
    text-decoration none

.blog__grid-entry
  display block
  lost-column: 1/2 2 30px;
  box-shadow: 1px 3px 8px rgba(0,0,0,.2)
  margin-bottom: 30px
  border-radius: 2px
  overflow: hidden;
  transition all 0.2s ease-in
  background-color: #fff
  +below($desktop)
    lost-column: 1/2 2 20px;
    margin-bottom: 20px
  +below($tablet)
    lost-column: 1 2 20px;
  +below($mobile)
    margin-bottom: 15px
  &:hover
    text-decoration: none;
    transform: scale(1.05);

.blog__grid-entry-image
  position relative
  width 100%
  padding-bottom 56%
  background-color #eee
  background-size cover
  &.loading::before
     content: "";
     circle(90px)
     position: absolute;
     box-sizing: border-box;
     left 50%
     top 50%
     margin-left -45px
     margin-top -45px
     border: 5px solid rgba(0, 0, 0, 0.3)
     border-top: 5px solid $brand_color
     animation: sweep 600ms infinite ease;

.blog__grid-meta,
.blog__featured-meta
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin: 8px 15px 0
  a
    color: #fff;

.blog__grid-header,
.blog__featured-header
  padding: 10px 15px 16px
  margin: 0
  font-size: 1.4rem
  line-height: 1.2
  font-weight: bold

.blog__grid-category
  category-pill()

.blog__grid-date
  font-size .7rem
  color: rgba(0,0,0,.6)
  white-space: nowrap;
