// Circle loading animation
@keyframes loading-circle
  to
    transform rotate(360deg)

// Fading elements into view
@keyframes fadeIn
  0%
    opacity 0
  100%
    opacity: 1

// Popping elements into view (Works nicely with fadeIn)
@keyframes popIn
  0%
    transform scale(0.8)
  100%
    transform scale(1)

@keyframes sweep
  to
    transform: rotate(360deg)

@keyframes counterSweep
  to
    transform: rotate(-360deg)

@keyframes pulse
  65%
    transform scale(1.3)

@keyframes pulseToggle
  0%
    transform: scale3d(1, 1, 1)
  40%
    transform: scale3d(1.03, 1.03, 1.03)
  80%
    transform: scale3d(1, 1, 1)

@keyframes bounce
  0%, 20%, 50%, 80%, 100%
    transform: translateY(0);
  40%
    transform: translateY(-30px);
  60%
    transform: translateY(-15px);

@keyframes stretchdelay
  0%, 40%, 100%
    transform: scaleY(0.4);
  20%
    transform: scaleY(1.0);
@keyframes dash
  from
    stroke-dashoffset: 1000;
  to
    stroke-dashoffset: 0;