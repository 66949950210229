.vip__product-pairs__outer
  sectionOuter(#fff)
  +below($desktop)
    display none

.vip__product-pairs
  sectionInner($vip_inner_section_border_color)
  border-top none
  &.desktop
    +below($desktop)
      display none
  &.mobile
    display none
    +below($desktop)
    // display block
      padding 0

// DESKTOP
// Header Bar
.vip__product-pairs__header-bar
  display flex
  align-items center
  background-color $product_pair_header_bg_color
  height 50px
  margin-bottom 20px
  span
    height 100%
    align-items center
  .usp
    position relative
    display flex
    background-color $product_pair_header_usp_bg_color
    font-size $product_pair_header_usp_font_size
    font-weight $product_pair_header_usp_font_weight
    color #fff
    font-family $product_pair_header_usp_font_family
    border-right $product_pair_header_usp_border_right
    padding: 0 2px 0 3px
    span
      text-transform uppercase
      padding $product_pair_header_usp_padding
    .brand_whiteaway &
      &:after
        content ''
        position absolute
        top 0
        right -15px
        width 0
        height 0
        border-top 25px solid transparent
        border-bottom 25px solid transparent
        border-left 15px solid $product_pair_header_usp_bg_color
  .text
    display none
    padding $product_pair_header_text_padding
    flex-grow 2
    border $product_pair_header_text_border
    border-left none
    color $product_pair_header_text_color
    &.active
      display flex
      animation: fadeIn .3s 1 ease-in

// Pair Preview
.vip__product-pairs__pair-preview
  display flex
  align-items center
  justify-content space-between

.vip__pair-preview__main-product-wrap,
.vip__pair-preview__pair-product-wrap
  position relative
  width 36%

  // - top
  .top
    width 100%
    min-height 185px
    display flex
    align-items center
    border 1px solid #ccc
    border-bottom none
    padding 10px 0px 5px
    +below($wide)
      padding 15px 0px 10px
  .product-image-and-campaign-wrap
    position relative
    display flex
    flex-direction column
    width 40%
    margin-right 10px
  .product-image
    padding 10px
    text-align center
    img
      max-width 100%
      max-height: 142px
  .product-banner
    position absolute
    bottom 10px
    left 0
    background-color $offer_background_color
    color $offer_text_color
    font-size 0.625rem
    padding 2px 10px 3px
    text-align center
    .shop_SE &
      background-color $offer_background_color_se
      color $offer_text_color_se
    &.hide-discount
      display none
  .banner-top
    font-weight bold
    font-size 0.75rem
  .product-info-price-button-wrap
    width 60%
    margin-bottom 10px
  .product-info-and-price-wrap
    position relative
    display flex
    justify-content space-between
    width 100%
    padding 10px 0px 0px
  .product-info
    display flex
    flex-direction column
    min-width 0
    padding-right 10px
    width 100%
  .title
    font-family: $font_family_header
    font-weight: $brand_font_weight
    text-transform uppercase
    color $brand_font_color
    truncate()
    padding-bottom 5px
    span
      font-family: $font_family_base
      color: #333
      text-transform: none;
      font-size: .875rem
  .rating
    padding-bottom 5px
  .usp-line
    font-size 0.815rem
    max-height 35px
    margin-bottom 5px
    *
      margin 0
  .product-button-wrap
    margin-top 10px
    display inline-block
  .button
    box-sizing border-box
  .price
    font-size 1.75rem
    padding 0 10px 0 0
    line-height 1
  .has-stroke
    mixStroke()

  // - bottom
  .bottom
    padding 6px 10px 5px
    background-color #aaa
    color #fff
    display flex
    justify-content space-between
    align-items center
    font-size 0.815rem
    line-height 1

  .category
    display none
    &.active
      display block
      animation: popIn .3s 1 ease-out, fadeIn .3s 1 ease-in

  .see-more-partners,
  .close-more-partners
    cursor pointer
    display none
    span
      margin-top 1px
      margin-left 5px
      height 0
      width 0
      border-left 6px solid transparent
      border-right 6px solid transparent
      border-top 6px solid #fff
    &.active
      display flex
      align-items center
      animation: fadeIn .3s 1 ease-in
    &:hover
      text-decoration underline

  .close-more-partners
    span
      margin-top -1px
      border-top none
      border-bottom 6px solid #fff

.vip__pair-preview__pair-product-wrap
  .bottom
    background-color $product_pair_partner_bottom_bg_color

.vip__pair-preview__pair-product
  &.top
    display none
    &.active
      display flex
      > div
        animation: popIn .3s 1 ease-out, fadeIn .3s 1 ease-in

.vip__pair-preview__plus-icon,
.vip__pair-preview__equal-icon
  width 4%
  line-height 1
  font-size 2.5rem
  text-align center

.vip__pair-preview__price-section
  max-width 20%
  display flex
  flex-direction column
  align-items flex-end
  margin-bottom 6px
  .before-price-and-text
    color #aaa
    display: none;
    visibility: hidden;
    &.active
      display: inline-block;
    &.visible
      visibility: visible;
  .before-price-stroke
    mixStroke(rgba(80, 80, 80, 0.5))
  .price-wrapper
    display: flex
    align-items: baseline;
  .pair-price
    display none
    padding-left 5px
    font-size 1.7rem
    &.active
      display: inline-block;

.vip_pair-button
  display none
  &.active
    display block

// Partner List
.vip__product-pairs__partner-list
  margin-top 10px
  border-top $product_pair_partner_list_border_top
  background-color #ccc
  padding 30px 0px
  display none
  &.active
    display flex
    animation: popIn 0.4s 1 ease-out, fadeIn 0.4s 1 ease-in

.vip__partner-list__product-box
  position relative
  display flex
  flex-direction column
  align-items center
  background-color #fff
  padding 20px 0px 15px
  width 17%
  margin-right 2.5%
  min-width 0
  cursor pointer
  transition: all .3s ease
  *
    pointer-events: none;
  &:first-child
    margin-left 2.5%
  &:last-child
    margin-right 2.5%
  &.active,
  &:hover
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.5);
    transform: scale(1.04);
  img
    max-height 120px
    max-width: 100%
    margin-bottom 10px
  .title
    font-family: $font_family_header
    font-weight: $brand_font_weight
    text-transform uppercase
    color $brand_font_color
    max-width: 100%
    truncate()
    span
      font-family: $font_family_base
      color: #333
      text-transform: none;
      font-size: .875rem
  .rating
    margin-bottom 2px
  .usp-line
    font-size 0.815rem
  .savings
    margin-top 5px
    margin-bottom 2px
    color #aaa
    font-size 0.815rem
  .price
    font-weight bold
    line-height 1
    font-size 1.5rem
    margin-top 10px
  .campaign-sign
    margin-top 10px
    background-color $offer_background_color
    color $offer_text_color
    padding 5px
    font-size 0.75rem
    line-height 1
    font-style italic
    .show_SE &
      background-color $offer_background_color_se
      color $offer_text_color_se

.vip__partner-list__product-box__radio-button
  position absolute
  top 0
  right 20px
  padding 10px

.vip__product-pairs-swapper
  display: none;
  &.active
    display: flex;

// MOBILE
// Header Bar Mobile
.vip__product-pairs__header-bar-mobile
  display flex
  flex-direction column
  align-content center
  padding 15px
  background-color $brand_color
  color #fff
  span
    text-align center
  .set-type
    font-size 0.815rem
    padding-bottom 3px
  .usp
    font-size 1.25rem
    text-transform uppercase
    background-color transparent
    font-family $product_pair_header_usp_font_family
    span
      padding-left 4px

.vip__product-pairs__alternative-text
  padding 6px 10px
  line-height 1
  background-color #ccc
  color #888
  font-size 0.815rem
  text-align center

.vip__product-pairs__product-gallery-wrap
  position relative
  background-color #fff
  border-left 1px solid #ccc
  border-right 1px solid #ccc
  width 100%
  overflow hidden

.vip__product-pairs__product-gallery
  position relative
  min-width 1000%
  display flex
  margin-left -0%
  transition: margin-left 450ms $easeOutCubic
  &.dragging
    transition: transform 0ms
    cursor: grabbing;

.vip__product-pairs__product-gallery__product-wrap
  position relative
  width 10%
  display flex
  justify-content center

.vip__product-pairs__product-gallery__product
  position relative
  display flex
  align-items center
  width 100%
  max-width 460px
  padding 20px 20px 40px
  *
    pointer-events: none;
  .image
    width 30%
    text-align center
  img
    max-width 100%
    max-height 120px
  .product-info
    display flex
    flex-direction column
    width 70%
    min-width 0
    padding-left 20px
  .title
    color $brand_color
    truncate()
    padding-bottom 2px
  .rating
    padding-bottom 3px
  .usp-line
    font-size 0.815rem
  .price
    margin-top 5px
    font-size 1.75rem

.vip__product-pairs__product-gallery__left,
.vip__product-pairs__product-gallery__right
  position absolute
  top 50%
  margin-top -30px
  cursor pointer
  display none
  *
    pointer-events: none;
  &.active
    display block
  svg
    fill #aaa
    box(30px)

.vip__product-pairs__product-gallery__left
  left 0
  padding 10px 10px 10px 5px

.vip__product-pairs__product-gallery__right
  right 0
  padding 10px 5px 10px 10px

.vip__product-pairs__product-gallery__dots
  display flex
  justify-content center
  position absolute
  bottom 15px
  width 100%
  div
    circle(10px)
    border 1px solid #777
    background-color #ccc
    margin 0 1.5px
  .active
    background-color #777

.vip__product-pairs__footer
  position relative
  padding 15px 10px 10px
  background-color #ccc
  display flex
  flex-direction column
  align-items center
  .price-wrap
    margin-bottom 10px
  .price
    font-size 2rem
    padding-left 3px
    padding-right 10px
    &.changed
      animation: popIn .3s 1 ease-out, fadeIn .3s 1 ease-in
  .old-price
    color #999
  .stroke
    mixStroke(rgba(80, 80, 80, 0.7))
