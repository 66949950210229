$border-color = rgba(0,0,0,.15)

.compare-products-outer
  position fixed
  bottom 0
  left 0
  z-index $compare_products
  width 100%
  background-color rgba(0, 0, 0, 0.65);
  +below($tablet)
    display none


// Closed
.compare-products-wrap__closed
  position relative
  standardSection()
  display flex
  justify-content flex-end
  align-items center
  height 50px
  font-family $srp_compare_font
  color #fff
  cursor pointer
  &:hover
    .compare-products-wrap__closed__arrow
      transform: rotate(-90deg) scale(1.15)

.compare-products-wrap__closed__arrow
  box(18px)
  transform rotate(-90deg)
  margin-right 10px
  transition: all 0.2s ease
  svg
    box(18px)
    fill #fff

.compare-products-wrap__closed__compare-text
  font-size 1.125rem
  margin-right 10px

.compare-products-wrap__closed__compare-abundance
  circle(24px)
  background-color $brand_color
  flex-center-children()

// Open
.compare-products-wrap__open
  position relative
  standardSection()
  display flex
  margin: 15px auto;

.compare-products-wrap__open__product-list
  flex-grow 2
  display flex

.open__product-list__product-column
  position relative
  overflow: hidden;
  box(120px, 130px)
  background-color #333
  flex-grow 2
  padding 10px
  margin-right 15px
  flex-center-children()
  > span
    color #aaa
    font-size 0.75rem
    text-align center
    &.choose-one-more
      color #fff

  &.active
    justify-content flex-start
    background-color #fff
  +below($desktop)
    flex-direction column

.open__product-list__product-column__img
  width 33%
  margin-right 7px
  text-align center
  +below($desktop)
    width 100%
    margin 10px 0 5px
  img
    max-width 100%
    max-height 80px
    +below($desktop)
      max-height 50px

.open__product-list__product-column__text
  text-transform uppercase
  position relative
  width 67%
  +below($desktop)
    width 100%
  span
    display block
    color #777
    overflow hidden
  .brand
    color: $brand_color
    font-family $srp_compare_font
    max-height 44px
    width 100%
    truncate()
  .name
    font-size 0.825rem
    max-height 35px
  +below($desktop)
    max-width 90%
    span
      max-width 100%
      truncate()
      text-align center

.open__product-list__product-column__remove
  position absolute
  top 3px
  right 7px
  cursor pointer
  svg
    box(12px)
    fill: #777
    transition: all 0.2s ease
    &:hover
      transform: scale(1.15)
      fill $black

.compare-products-wrap__open__compare-button
  display flex
  flex-direction column
  margin-left: 10px
  .disabled
    cursor default
    color #aaa
    &:hover
      opacity 1
  .active
    cursor pointer
    background-color $action_color
    color #fff
    &:hover
      transform: none
  span
    margin-top 10px
    max-width 110px
    color #fff
    font-size 0.75rem
    text-transform none
  .info-symbol
    flex-center-children()
    circle(16px)
    float left
    margin 0 5px 0 0
    background-color #fff
    color rgba(0, 0, 0, 0.65);
    font-family $srp_compare_font
    font-style normal
    font-weight bold

.compare-products-wrap__open__close
  position absolute
  top -65px
  right 5px
  box(50px)
  background-color rgba(0, 0, 0, 0.65);
  flex-center-children()
  cursor pointer
  .icon-wrap
    box(20px)
    transform: rotate(90deg)
    transition: all 0.2s ease
    svg
      box(100%)
      fill #fff
  &:hover
    .icon-wrap
      transform: rotate(90deg) scale(1.15)
  +below($wide)
    right 35px
  +below($desktop)
    right 25px

// Full
.compare-list__add-to-cart
  > span
    pointer-events: none;
    transition: opacity 200ms
    display: inline-block;
  &.adding
    > span
      opacity: 0
  &.added
    background-color: $success_color
    pointer-events none;
    > span
      opacity: 0

.compare-list__tick
  position: absolute;
  top 7px
  left calc(50% - 8px)
  pointer-events: none;
  svg
    box(22px)
    stroke #fff
    stroke-width 2.1
    stroke-dasharray: 50
    stroke-dashoffset: 50
    transition: stroke-dashoffset 800ms ease-out 200ms, stroke 300ms
  .adding &, .added &
    svg
      stroke-dashoffset: 0

.compare-products-wrap__full
  standardSection()
  height 100vh
  display flex
  flex-direction column
  align-items center
  justify-content flex-end

.compare-products-wrap__full__close
  display flex
  align-items center
  justify-content flex-end

.full__close__clickable
  display flex
  align-items center
  padding 10px 0 10px
  font-family $srp_compare_font
  color #fff
  cursor pointer
  span
    padding-top 2px
  .icon-wrap
    box(18px)
    transform rotate(90deg)
    transition: all 0.2s ease
    margin-right 10px
  svg
    box(100%)
    fill #fff
  &:hover
    .icon-wrap
      transform: rotate(90deg) scale(1.15)

.compare-products-wrap__full__inner
  position relative
  max-height 90vh
  overflow: auto;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  box-shadow: 0 0 14px rgba(0,0,0,.5)

.compare-2-products
  width 60%
  .compare-column
    width 33.33%
  +below($desktop)
    width 80%
.compare-3-products
  width 80%
  .compare-column
    width 25%
  +below($desktop)
    width 100%
.compare-4-products
  width 100%
  .compare-column
    width 20%

.compare-products-wrap__full__product-info-grid
  display flex
  flex-direction column


.full__product-info-grid__header
  width 100%
  display flex
  justify-content center

.full__product-info-grid__header__info-column
  padding 5px 10px 10px
  background-color #fff
  display flex
  justify-content center
  align-items center
  +below($desktop)
    padding 0px 5px 5px

.full__product-info-grid__header__product-column
  position relative
  padding 30px 10px 0px
  display flex
  flex-direction column
  align-items center
  background-color #fff
  border-left 1px solid $border-color
  +below($desktop)
    padding 10px 5px 0px

.header__product-column__image
  text-align center
  img
    box(80px)
    margin 10px 0

.header__product-column__title
  flex-center-children-column()
  margin-bottom 10px
  .brand
    color: $brand_color
    font-family $srp_compare_font
  .name
    color $black
    font-size 0.825rem
    truncate()
    overflow hidden
    text-align center
    max-width: 180px

.header__product-column__rating
  margin-bottom 3px
  .star-rating__back svg, .star-rating__front svg
    box(16px)
    margin-right 3px

.header__product-column__price
  font-size 1.5rem
  margin-bottom 7px
  &.stroke
    mixStroke()

.full__product-info-grid__header__product-column
  div.campaign-banner__wrap
    &:after
      display none
  div.header__product-column__banner
    width: 100%
    margin 0
    padding 0
    .campaign-banner
      overflow inherit
      min-height 40px
    span
      line-height 1.2
      font-size 0.625rem !important
  div.campaign-banner__info
    width 100%
    min-height 40px
    padding 10px 15px 10px 10px
    &:before
      .brand_whiteaway &
        display none
      .brand_skousen &
        left 0
        transform none

.header__product-column__banner__extra-info
  color $srp_faded_dark_color
  font-size 0.625rem
  text-align center
  line-height 1.1
  padding 5px 0px

.header__product-column__banner__remove
  position absolute
  flex-center-children()
  font-size 1.5rem
  line-height 1
  padding 10px 10px 0 0
  top 0
  right 0
  cursor pointer
  svg
    transition: all 0.2s ease
    box(16px)
    fill $srp_fav_and_compare_inactive

.header__product-column__banner__save-as-favorite
  position absolute
  top 10px
  left 10px
  display flex
  align-items center
  font-size 0.8rem;
  color $srp_fav_and_compare_inactive
  cursor pointer
  .icon-wrap
    margin-right 3px
    box(18px, 16px)
  svg
    box(18px, 16px)
    fill $srp_fav_and_compare_inactive
    transition: .25s all ease-out
  &.saved
    .icon-wrap
      animation 0.35s pulse ease
      svg
        fill $srp_fav_and_compare_active

.full__product-info-grid__specs
  width 100%
  display flex
  justify-content center
  &.show-differences
    color: rgba(0,0,0,0.7)
    .is-different
      background-color rgba(0,0,0,.09)
      font-weight bold
      color: rgba(0,0,0,1)


.full__product-info-grid__specs__info-column
  background-color #fff

.full__product-info-grid__specs__product-column
  position relative
  background-color #fff
  display flex
  flex-direction column
  align-items center

.specs__info-column__section-header
  display flex
  align-items center
  justify-content flex-start
  padding-left 10px
  background-color #5C5C5C
  height 38px
  width 100%
  color #fff
  line-height 1

.specs__info-column__spec-name
  display flex
  align-items center
  justify-content flex-start
  padding-left 15px
  width 100%
  height 38px
  border-left 1px solid $border-color
  border-bottom 1px solid $border-color
  font-size 0.8rem
  background-color: rgba(0,0,0,0.03)

.specs__product-column__section-header
  background-color #5C5C5C
  height 38px
  width 100%

.specs__product-column__spec
  flex-center-children()
  width 100%
  height 38px
  text-align center
  border-left 1px solid $border-color
  border-bottom 1px solid $border-color
  font-size 0.8rem

.full__product-info-grid__footer
  width 100%
  display flex
  justify-content center

.full__product-info-grid__footer__info-column
  height 100px
  background-color #fff

.full__product-info-grid__footer__product-column
  background-color #fff
  border-left 1px solid $border-color
