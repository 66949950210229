

.vip__video-description__outer
  sectionOuter($description_section_background_color, $vip_section_padding)
  +below($desktop)
    display: none

.vip__video-description
  sectionInner($vip_inner_section_border_color)

.vip__accordion-content
  padding-bottom: 32px
  sectionInnerAccordian($vip_inner_section_border_color)

.vip__video-description__container
  display: flex
  justify-content: space-between;
  font-size: 1rem;
  +below($desktop)
    flex-direction: column;

.vip__video-description__text
  width: 40%
  max-width: 700px
  margin-right: 4%;
  .gallery-hidden &
    width: auto;
  +below($desktop)
    width: 100%;
    margin: 0
  p
    margin-top: 0;
  .vip__video-description__only-text &
    width: 100%
    max-width: 100%
    margin: 0

  .vip__help__text
    +above($mobile)
      display: none;

.vip__video-description__video
  width: 56%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  +below($desktop)
    width: 100%;
    margin-top: 20px
    align-items: flex-start;

  .gallery__details__outer
    justify-content: flex-end;
    +below($mobile)
      justify-content: center;

  .gallery__details__inner
    cursor: default;

  .gallery__details, .gallery__details__img
    box-shadow: 2px 2px 8px rgba(0,0,0,.3)
    box(650px, 380px)
    +below($wide)
      box(500px, 300px)
    +below($desktop)
      box(650px, 380px)
    +below($tablet)
      box(94vw, 70vw)

  .gallery__details__video
    background-color: #333
    &.rendered
      img
        display none
      .gallery__details__play-icon
        display none

  .gallery__details__play-icon__inner
    box(20%, 16%)

  // THUMBS
  .gallery__thumbs_outer
    overflow: hidden;
    box(412px, 70px)
    position: relative;
    margin: 20px auto 0;
    +below($mobile)
      display: flex;
      justify-content: center;
      box(100%, 20px)
    &.hide-thumbs
      display: none;

  .gallery__thumbs
    white-space: nowrap;
    position: absolute;
    display: flex;
    align-items center
    top 0
    left 0
    cursor: pointer
    transition: left 400ms cubic-bezier(0.645, 0.045, 0.000, 0.880)
    +below($mobile)
      position: static;
      width: 90vw
      justify-content: center;
    .displayed
      .gallery__thumb
        border: solid 2px $brand_color
        +below($mobile)
          border: solid 2px $brand_color
          background-color $brand_color
          &.thumb__is__video
            .gallery__thumbs__video-icon__inner
              background-color transparent

  .gallery__thumb-wrapper
    position: relative;

  .gallery__thumb
    position: relative;
    box($thumb_size)
    margin: 0 8px 0 0
    border solid 1px $gray_border
    border-radius: $gallery_thumb_radius;
    img
      border-radius: $gallery_thumb_radius;
    picture
      width 100% //ie11 fix
    +below($mobile)
      img
        display none
      &.thumb__is__video
        circle(20px)
        border solid 2px $black
        margin: 0 3px
    &.thumb__is__video
      display flex
      justify-content center
      align-items center
      flex-direction column

  .gallery__thumbs__video-icon
    position absolute
    box(100%)
    display flex
    justify-content center
    align-items: center;
    top 0
    left 0

  .gallery__thumbs__video-icon__inner
    box(50%, 40%)
    background-color: alpha($brand_color, .8)
    display: flex;
    align-items: center;
    justify-content: center;
    &:after
      content: ''
      display: block;
      box(0)
      border: 9px solid transparent
      border-left-color #fff
      border-top-width: 5px
      border-bottom-width: 5px
      border-right-width: 0
      margin-left 3px
    &.youtube
      background-color $youtube_color
      border-radius 15%
    +below($mobile)
      box(100%)
      background-color: transparent
      &:after
        border-left-color $black
        .displayed &
          border-left-color #fff

