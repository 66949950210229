.srp-intermingle-banner
  position relative
  display block
  width 100%
  margin-bottom 12px
  .grid &
    width calc(100% - 20px)
    margin-left 10px
    margin-right 10px
  .has-link
    cursor pointer
  img
    vertical-align:bottom;

.srp-intermingle-banner__date
  width: 100%;
  background-color: $campaign_end_date_background_color
  font-size: .8rem
  color: $campaign_end_date_text_color
  border-left: 1px solid $campaign_end_date_border_color
  border-right: 1px solid $campaign_end_date_border_color
  border-bottom: 1px solid $campaign_end_date_border_color
  border-bottom-right-radius: $campaign_end_date_border_radius
  border-bottom-left-radius: $campaign_end_date_border_radius
  text-align: center;
  padding: 10px 5px
