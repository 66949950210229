.vip__description
  font-size: 1rem
  line-height: 1.46
  min-height: 30px

.vip__description__text
  margin-top: 15px
  margin-bottom: 15px
  overflow: hidden;
  transition: height 300ms ease-in
  position: relative;

.vip__description__header
  font-size: 1.2rem
  line-height: 1
  font-weight: bold
  margin: 0 0 17px 0
  font-family: $font_family_base;

.vip__list-price
  font-size 0.7rem

.vip__description__rating
  display none
  align-items center
  cursor pointer
  margin-bottom: 18px
  +above($desktop + 1)
    display: flex;

.vip__description__rating.vip-C
  display flex

.vip__description__rating-mobile
  display none
  align-items center
  margin-bottom: 18px
  justify-content: flex-start
  cursor pointer
  +below($desktop)
    display: flex;

.description__text__ellipsis
  background linear-gradient( to right, rgba(255,255,255, 0) 0%, rgba(255,255,255, 1) 60%)
  padding: 0 10px 0 40px
  position: absolute;
  right 0
  bottom 0
  transition: opacity 300ms
  .expanded &
    opacity: 0


.vip__description__toggle
  color: $anchor_text_color
  cursor: pointer;
  &:hover
    text-decoration: underline;
  &.showing
    .vip__description__show-label
      display: none;
    .vip__description__hide-label
      display: inline;

.vip__description__hide-label
  display: none;

.vip__description__bullets
  margin-bottom: 24px
  .list-item
    font-size: 1rem
    &:before
      top 7px
    .brand_tretti &
      &:before
        top 9px

.vip__help__text
  display: flex
  border-left: 4px solid $brand_color
  margin-bottom: 25px;
  padding: 5px 0 5px 12px;
  font-size: .75rem;
  line-height: 1.3;
  .brand_skousen &,
  .brand_tretti &
    border: 0;
    padding: 5px 0 5px 0;
    svg
      box(46px, 28px)
      margin-right: 12px
  .brand_tretti &
    svg
      margin-top: 3px
      fill: #b2b2b2
  +below($tablet)
    margin-top: 20px;

.vip__help__header
  font-weight: bold;

.vip__description-bottom
  align-items: center
  display: flex
  justify-content: space-between

.vip__description-bottom-left
  display: flex
  justify-content: flex-start

.vip__description-bottom-right
  display: flex
  justify-content: flex-end
