.category__carousel__product__card
    shopCard()

    &.recommender
        border: 2px solid $brand_color

.category__carousel__product__card-image
    display: flex
    justify-content: center;
    align-items: center;
    width: 100%

    &.loading::before
        content: "";
        circle(90px)
        position: absolute;
        box-sizing: border-box;
        left 50%
        top 50%
        margin-left -45px
        margin-top -45px
        border: 5px solid rgba(0, 0, 0, 0.3)
        border-top: 5px solid $brand_color
        animation: sweep 600ms infinite ease;

    &.loading
        display block
        width 100%
        max-height 230px
        padding-top 230px

        img
            display: none

    .no-click &
        pointer-events: none;

.category__carousel__product__card-top
    display: flex;
    flex-direction: column;
    padding: 14px 14px 0px 14px
    position relative

.category__carousel__image-section__image
    max-height 160px
    max-width 180px
    margin-bottom 5px
    +below($tablet)
        max-width: 120px;

.category__carousel__product__card-badge
    position absolute
    top: 0
    left: 0
    transform: scale(0.8);
    z-index: 3

.category__carousel__product__card-top-data
    display: flex
    justify-content: center;
    align-items: center;
    margin-top: 5px
    height 24px

.category__carousel__product__card-divider
    height: 1px
    background-color: #eee

.category__carousel__product__card-middle
    display: flex
    padding: 4px 14px 0px 14px
    justify-content: space-between;
    height 58px

.category__carousel__product__card-title
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    line-height: 1
    width: 65%

    &:hover
        text-decoration: none;

.category__carousel__product__card-name
    font-family: $font_family_header
    font-style: $font_family_header_style
    color: $shop_card_header_color
    font-size: 1.1rem
    text-transform: uppercase;

.category__carousel__product__card-npk
    color: $body_text_color
    font-size: .8rem

.category__carousel__product__card-prices
    line-height: 1.2
    justify-content: space-between;
    align-items: center;
    .srp-product-box__add-to-cart-desktop
        display: inline-flex
        margin: 0 0 0 0
        .f
            margin 0 0 0 0
            display flex
            justify-content flex-end
        .button
            min-width 100px
            padding 10px 16px
            line-height 1
            display inline-flex
            align-items center
        .icon-basket-wrap
            box(20px)
            margin-left 10px
            svg
                fill #fff
        .icon-check-wrap
            position: absolute;
            top 7px
            left calc(50% - 8px)
            pointer-events: none;
            svg
                fill #fff
            box(22px)
            stroke #fff
            stroke-width 2.1
            stroke-dasharray: 50
            stroke-dashoffset: 50
            transition: stroke-dashoffset 800ms ease-out 200ms, stroke 300ms
        .adding .icon-check-wrap svg,
        .added .icon-check-wrap svg
            stroke-dashoffset: 0

.category__carousel__product__card-price-and-atb-button-wrap
    justify-content: space-between
    display: flex
    -webkit-box-align: center
    -ms-flex-align: center
    align-items: center
            
.category__carousel__product__card-price
    font-size: 1.5rem
    color: $price_color
    display: inline-flex

.category__carousel__product__card-offer-text
    top: 28px;
    background-color: $offer_background_color
    color: $offer_text_color
    padding: 2px 6px;
    font-size: 0.7rem;
    font-style: italic;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: flex;
    min-height: 18px;
    max-width: fit-content;

.category__carousel__product__card-offer-text-filler
    top: 28px;
    padding: 2px 6px;
    font-size: 0.7rem;
    font-style: italic;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: flex;
    min-height: 18px;

.category__carousel__product__card-bottom
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 14px 14px 14px
    color: $body_text_color
    font-size: .9rem
    height 60px

.category__carousel__product__card-spec
    truncate()
    max-width: 240px;

.category__carousel__product__card-energy-anchor
    &:hover
        text-decoration none

.category__carousel__product__card-recommender__label,
.category__carousel__product__card-last-seen__label-1
.category__carousel__product__card-last-seen__label-2
.category__carousel__product__card-last-seen__label-3
    padding: 7px 14px
    color: #fff
    z-index: 1
    width: 100%
    text-align: center

.category__carousel__product__card-recommender__label
    background-color: $brand_color

.category__carousel__product__card-last-seen__label-1
    background-color: $brand_color_variation
    border-top-left-radius: "calc(%s - 1px)" % $shop_card_border-radius
    border-top-right-radius: "calc(%s - 1px)" % $shop_card_border-radius

.category__carousel__product__card-last-seen__label-2,
.category__carousel__product__card-last-seen__label-3
    visibility: hidden