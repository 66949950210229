.career__values
  background: #dbdbdb;

.career__values-wrapper
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;

.career__value
  display: flex;
  background-color: #fff;
  margin-bottom: 20px;
  +below($tablet)
    display: block;
  &:nth-child(even)
    flex-direction: row-reverse;
  div
    width:50%;
    +below($tablet)
      width: 100%;
  h3
    font-size: 1.7rem;
  img
    vertical-align: bottom;
    width: 100%

.career__value-text
  padding: 0 20px 20px 20px;
  font-size: .875rem
