.promos
  //lost-utility: edit;

.promos__inner
  lost-center $page_width
  +below($wide)
    padding: 0 20px

.promos__heading
  margin-top: 30px

.promos__grid
  width 100%
  lost-flex-container row

.promos__promo
  lost-column 1/4 4
  margin-bottom: 30px
  border solid 1px $gray_border
  padding: 10px 15px
  transition: transform 200ms ease-in
  border-radius 2px
  &:hover
    transform: scale(1.07);
  a
    color: $font_color_base
    text-decoration none
    display: flex;
    flex-direction: column;
  +below($desktop)
    lost-column 1/2 2
  +below($mobile)
    lost-column 1 1


.promo__img
  align-self: center;
  height: 200px

.promo__title
  font-size 1.1rem
  margin-top: 10px
  font-family: $font_family_header
  truncate()


.promo__rating
  display: flex;
  align-items: center;
  .rating-stars, .rating-stars__back, .rating-stars__front__inner
    box(90px, 16px)
    svg
      box(16px)

.promo_rating-avr,
.promo__abundance
  margin-left: 8px
  font-size: .8rem

.promo__spec-list
  zero-list()
  font-size: .7rem
  margin-top: 10px
  li
    truncate()

.promo__prices
  margin-top: 10px

.promo__price
  font-size 1.6rem

.promo__price-before
  margin-left: 8px
  text-decoration: line-through;
  color: rgba(0,0,0,.7)
