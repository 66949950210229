$uspHeight = 45px
$uspHeightMobile = 34px
$rotateWidth = 300px
.usp
  background-color: $usp_bar_background_color
  text-align: center;
  +below($desktop)
    padding: 1px

.usp__inner
  lost-center $page_width
  justify-content: space-between;
  align-items: center;
  height: $uspHeight
  position: relative;
  perspective: 1000
  +below($wide)
    padding: 0 20px
  +below($tablet)
    height: $uspHeightMobile
  &.active-streamer
    justify-content: flex-start;
    +below($desktop)
      display: block;
      height: auto;
      padding: 0

.usp__links
  color #666666
  font-size: 12px
  display: flex;
  justify-content: space-between;
  margin 0
  width: 435px
  position: relative;
  +below($desktop)
    .active-streamer &
      margin 7px auto


  +below($tablet)
    display: block;
    transform-style: preserve-3d;
    height: $uspHeightMobile
    width: $rotateWidth
    margin: auto !important
    animation spin-usp 14s infinite
.usp__links-right
  right 0
  display flex
  justify-content space-between
  +below($desktop)
    display:none;
  .usp__link-right
    margin-left 35px
.usp__link
  display: flex
  align-items: center;
  color #666666
  text-transform: $usp_bar_text_transform;
  font-weight: $usp_bar_font_weight;
  font-size: $usp_bar_font_size;
  font-family: $usp_bar_font_family;
  white-space: nowrap;
  +between($desktop + 1, $wide)
    .active-streamer &
      margin-right 20px
      .shop_LPH &
        margin-right 60px
  &:hover
    text-decoration: none
    color: $usp_bar_link_color
  svg
    box(31px, 21px)
    margin-right: 5px
    fill: #222939
  +below($tablet)
    box($rotateWidth, $uspHeightMobile)
    position: absolute;
    backface-visibility: hidden;
    top 0
    left 0
    font-size: $usp_bar_font_size_mobile
    justify-content: center;
    svg
      box(20px)
      margin-right: 6px

.usp__rating
  margin-left: 10px;
  display: flex;
  align-items: center;

  svg
    box(16px)

.usp__face1
  +below($tablet)
    transform: translateZ(5px) rotateX(0deg)
.usp__face2
  +below($tablet)
    transform: translateY(-8.5px) translateZ(-5px) rotateX(120deg)
.usp__face3
  +below($tablet)
    transform: translateY(8.5px) translateZ(-5px) rotateX(240deg)


@keyframes spin-usp{
  0% {
    transform: rotateX(0deg)
  }
  23% {
    transform: rotateX(0deg)
  }
  33% {
    transform: rotateX(-120deg)
  }
  56% {
    transform: rotateX(-120deg)
  }
  66%{
    transform: rotateX(-240deg)
  }
  89%{
    transform: rotateX(-240deg)
  }
  100%{
    transform: rotateX(-360deg)
  }
}
