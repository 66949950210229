.cof__confirmation
  background-color: $cof_section_bg

.cof__confirmation-header
  display: flex;
  margin-top: 40px
  margin-bottom: 20px;
  justify-content: space-between;
  padding-bottom: 10px;
  border-bottom: 1px solid $gray_border
  +below($tablet)
    display: block;
    text-align: center;
    flex-wrap: wrap;
    margin-top: 20px

.cof__confirmation-header-text
  font-weight: bold;

.cof__confirmation-header-date
  color: #585858
  font-size: .8rem
  span
    margin: 0 8px

  +below($tablet)
    margin-top: 10px
    
.cof__confirmation-box
  lost-center $cof_center_col_width
  flex-direction: column;
  .cof__summary
    background-color: transparent;
    border: 0;
    padding: 0;

.cof__confirmation-box-header
  color: $brand_color
  text-transform: uppercase;
  border-bottom: 1px solid $gray_border
  padding-bottom: 3px;
  margin-bottom: 10px;

.cof__confirmation-box-content
  margin-bottom: 25px;
  font-size: 0.875rem;
  color: #5a5a5a;
  &:last-child
    margin-bottom: 0

.cof__confirmation-box-row
  padding: 2px 0;
