.career__positions
  background: $career_positions_bg_color
  h2
    color: #fff

.career__positions-inner
  display: flex;
  justify-content: center;

.career__positions-listings
  width: 800px
  insetBox(50px 65px)
  +below($wide)
    width 100%
  +below($mobile)
    padding: 20px

.career__positions-job
  insetBoxItem()
  truncate()

.career__positions-no-jobs
  text-align: center;

.career__positions-divider
  height: 34px
