$category_banner_width = 1340px

.category-banner
  a
    text-decoration none
  +below($mobile)
    background-color: transparent !important;

.category-banner__inner
  lost-center $category_banner_width
  height: 220px;
  background-size: cover;
  display: flex;
  align-items: center;
  +below($wide)
    width: 100%;
    height: 140px;
    +below($mobile)
      background-image: none !important;
      height: auto;
      margin: 10px 0 0 0


  h1, p
    margin-left: (($category_banner_width - $page_width)/2)
    color: #fff;
    text-shadow: 0 0 6px #111;
    margin-top:0
    margin-bottom:5px;
    +below($wide)
      margin-left: 30px;
    +below($desktop)
      margin-left: 20px;
    +below($mobile)
      margin-left: 10px;
      color: #333;
      text-shadow: none;

  h1
    font-size: 3.7em;
    +below($wide)
      font-size: 2.8em;
      +below($mobile)
        font-size: 1.8em;
  p
    font-size: 1.7em;
    +below($mobile)
      display: none
