.bv__reviews__container
  max-width: $page_width;
  width: 100%;
  margin: 0 auto;
  +below($wide)
    padding 20px 30px 30px
  +below($desktop)
    padding 20px 20px 30px
  +below($tablet)
    padding 20px 20px
  +below($mobile)
    padding 20px 10px

.bv__reviews__product
  display: flex;
  align-items: center;
  max-width: 50%;
  padding: 10px;
  border: 1px solid $gray_border;
  margin: 18px 0;
  color: $body_text_color;
  background-color: #fff;
  +below($desktop)
    margin-top: 0
  +below($tablet)
    max-width: 100%;

  &:hover
    text-decoration: none;

  h3
    margin 0;

.bv__reviews__product__arrow
  width: 40px;
  height: 40px;

.bv__reviews__product__img
  margin-right: 5px;
  min-width: 70px;
