.vip__heading__outer
  position: relative;
  width $page_width
  max-width: 100%
  +below($wide)
    padding: 0 30px 9px
  +below($desktop)
    padding: 0 20px 9px
  +below($mobile)
    padding: 4px 10px 

.vip__heading
  width 100%
  border-bottom 1px solid $gray_background
  display: flex;
  justify-content: space-between;
  padding-bottom: 3px
  margin: 25px 0 0
  +below($desktop)
    align-items center
    margin: 10px 0 0
  +below($tablet)
    border none
    padding-bottom: 0
  h1
    brandHeader()

.vip__heading__secondary
  font-size 1rem
  margin-left 10px
  +below($desktop)
    margin-left 0
    display: block;
    font-size .9rem
  +below($tablet)
    font-size .8rem
  +below($mobile)
    font-size .7rem

.vip__heading__img
  max-height: 30px
  max-width: 80px
  padding-bottom: 5px;
  +below($tablet)
    display: none;

.vip__heading__npk
  position: absolute;
  right: 0
  top: -30px
  font-size: .75rem
  color: #999
  +below($wide)
    display: none
