.cof__preloader-wrapper
  display: flex
  visibility: hidden;
  pointer-events: none;
  width: 100%;
  height: 100%;
  top: 0
  background-color: #fff
  z-index: $cof_progress_Z
  transition all 300ms ease-in
  opacity 0
  position: fixed;
  align-items: center;
  justify-content: center;
  cursor:progress;
  flex-direction: column;
  &.in-progress
    visibility: visible;
    opacity .7
    pointer-events: all;

.cof__preloader
  width: 80px;
  height: 50px;
  text-align: center;
  div
    background-color: $action_color;
    height: 100%;
    width: 6px;
    display: inline-block;
    animation: stretchdelay 1.2s infinite ease-in-out;
  .rect2
    animation-delay: -1.1s;
  .rect3
    animation-delay: -1.0s;
  .rect4
    animation-delay: -0.9s;
  .rect5
    animation-delay: -0.8s;

.cof__preloader-text
  font-size: 1.3rem
