.vip__featured__product
  position: relative;
  background-color: #fff
  border-bottom: 3px solid #dcdcdc
  box-shadow: 0 2px 5px 0 #ccc;
  padding: 15px 0
  top:0
  width: 100%
  transition: all 400ms cubic-bezier(0.175, 0.885, 0.320, 1.1)
  transform-origin: center top;
  transform scale(1)
  &.hide-featured-product
    display: none
  +below($tablet)
    display: none

.vip__featured__product-close
  position: absolute;
  line-height: 1
  right: 12px
  top: 5px
  font-size: 1.7rem
  color: #999
  cursor: pointer;

.vip__featured__product-wrap
  lost-center $page_width
  align-items: center;
  +below($wide)
    padding: 0 15px

.vip__featured__product-image
  width: 70px
  margin-right: 25px
  +below($desktop)
    margin-right: 20px

.vip__featured__product-info
  display: flex
  flex-direction: column;
  flex-grow: 2
  line-height: 1

.vip__featured__product-header
  display: flex
  align-items: baseline;
  a:hover
    text-decoration: none;

.vip__featured__product-attention
  color: $brand_color
  font-family: $font_family_header
  font-size: 1.2rem
  margin-right: 7px
  text-transform: uppercase;

.vip__featured__product-npkid
  color: #999
  font-size: .875rem

.vip__featured__product-usp
  margin-top: 12px

.vip__featured__product-price-info
  display: flex
  margin-top: 12px
  align-items: baseline;
  +below($desktop)
    margin-top: 20px

.vip__featured__product-rating
  margin-right: 12px

.vip__featured__product-price
  font-size: 1.5rem
  margin-right: 12px

.vip__featured__product-sale
  font-size: .7rem
  span
    background-color: $offer_background_color
    font-style: italic;
    padding: 3px 5px

.vip__featured__product-button
  font-size: .9rem
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 2px transparent;
  border-radius: 60px
  background-image: linear-gradient(white, white), radial-gradient(circle at top left, $brand_color,$brand_color_variation);
  background-origin: border-box;
  background-clip: content-box, border-box;
  font-weight: bold;
  color $brand_color
  transition: all 400ms cubic-bezier(0.175, 0.885, 0.320, 1.1)
  transform scale(1)
  &:hover
    transform scale(1.075)
    text-decoration: none;
  +below($desktop)
    position: absolute;
    right: 15px
    bottom: 15px
    font-size: .8rem
  div
    margin: 12px 20px
    +below($desktop)
      margin: 8px 14px
