
.takeover
  background-color: $black
  width: 100%
  overflow: hidden;
  max-height: 0
  transition: max-height 600ms $easeInOutCubic
  &.show
    max-height: 1080px
    box-shadow: -2px 2px 8px rgba(0,0,0,.5)
  //Disabled on mobile
  +below($desktop)
    display: none;

.takeover__inner
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%
  padding-bottom: 39.7%
  margin: 0 auto;
  opacity: 0
  transition: opacity 300ms
  transition-delay: 700ms
  .show &
    opacity: 1
  +below($mobile)
    padding-bottom: 108%


.takeover__overlay
  position: absolute;
  top 0
  bottom: 0
  left 0
  right 0
  display: flex;
  justify-content: center;
  align-items: flex-end;

.takeover__cta-wrapper
  width: 100%
  padding: 30px 0 10px 0
  text-align: center;
  background: linear-gradient(to top, rgba(0,0,0, .6), rgba(0,0,0,0))
  +below($desktop)
    padding: 20px

.takeover__cta
  +below($desktop)
    font-size 1.1rem
    line-height 40px
    padding 0 30px

.takeover__close-x
  position: absolute;
  top 10px
  right: 30px
  color: rgba(255,255, 255, .5)
  font-size: 2rem
  cursor: pointer;
  &:hover
    color: rgba(255,255, 255, 1)

.takeover__close
  display: block;
  color: #fff
  margin-top: 10px
  +below($mobile)
    font-size .9rem

.takeover__date-end
  color:rgba(255, 255, 255, .7);
  font-size .8rem
  margin-top: 20px
