
expand-arrow()
  content: ''
  display: block;
  box(0)
  position: absolute;
  right 0
  top 9px
  border solid 4px transparent
  border-top-color: $anchor_text_color

.text-expand
  overflow: hidden;
  position: relative;
  &.transition-active
    transition: all 300ms ease-in

.text-expand__links
  position: absolute;
  padding-right: 4px
  right 0
  bottom 0
  color: $anchor_text_color

.text-expand__ellipsis
  padding: 0 10px 0 40px
  transition: opacity 300ms
  opacity: 0
  .minimized &
    opacity: 1

.text-expand__show-label, .text-expand__hide-label
  transition: all 500ms

.text-expand__hide-label
  opacity: 0
  pointer-events: none;
  max-width: 0
  display: inline-block;

.text-expand__show-label
  position: relative;
  padding-right: 11px
  &:after
    expand-arrow()


.text-expand__scroll-to
  margin-left: 12px
  padding-right: 11px
  cursor: pointer;
  position: relative;
  &:after
    expand-arrow()

.text-expand__toggle
  cursor: pointer;
  display none
  &:hover
    text-decoration: underline;
  &.showing
    .text-expand__show-label
      opacity 0
    .text-expand__hide-label
      opacity: 1
      pointer-events: all
      max-width: 200px
