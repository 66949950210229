.expandable-section
  position: relative;
  overflow: hidden;
  &.transition-active
    transition: all 500ms cubic-bezier(0.47, 0, 0.745, 0.715) 100ms

.expandable-section__toggle
  box(100%, 100px)
  position: absolute;
  display: flex
  bottom: 0
  padding-bottom: 6px
  cursor: pointer;
  justify-content: center;
  align-items: flex-end;
  background linear-gradient( to bottom, rgba(255,255,255, 0) 0%, rgba(255,255,255, 1) 100%)

  &:after
    content ""
    box(20px)
    display: block;
    border transparent 3px solid
    border-right-color $content_slider_dot_border_color
    border-bottom-color $content_slider_dot_border_color
    transform: rotate(45deg);
    transition: transform 300ms ease-in 600ms

    .section-expanded &
      transform: rotate(-135deg);
