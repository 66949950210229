.inhouse__outer
  sectionOuter(#E4E4E4, 20px 0)
  margin-top: 20px;

  &.blue__ocean
    background: url(//images.wagcdn.com/f/design/skou/blue-ocean.jpg);
    background-size: cover;

.inhouse
  sectionInner()
  h3
    margin: 0 0 7px 0;
    display: inline-block;

.inhouse__head
  display: flex;
  align-items: baseline;
  height: 40px

.inhouse__offline-label
  margin-left 6px
  position:relative
  background-color $danger_color
  color: #fff
  padding: 0px 6px
  border-radius: 3px
  font-size .9rem

.data__tab
  display: flex;
  justify-content: space-between;
  h4
    font-weight: bold;

.inhouse__data-numbers
  flex-grow: 2

.tab__panel__product-info-column
  display flex

.tab__panel__postcode
  .shop_SKOU.b2b &,
  .shop_SKOUNO.b2b &,
  .brand_tretti.b2b &
    display none
  border 1px solid #ccc
  margin 10px 0 0 20px
  padding 5px
  .recalculated_data
    display none

.tab__panel__postcode-row
  display flex
  justify-content space-between
  align-items center
  font-size 0.675rem
  margin-bottom 5px
  &:last-child
    margin-bottom 0
  label
    margin-bottom 1px
  input
    border 1px solid #ccc
    width 70px
    height 18px
    margin-left 20px
    padding 0px 3px
  .just_button
    border 1px solid #ccc
    border-radius 3px
    background-color #fff
    width 70px
    height 18px
    padding 0px 0px 1px
    &:hover
      background-color #ececec

.tab__panel__calculator
  border 1px solid #ccc
  margin-left 20px
  padding 5px

.tab__panel__calculator-row
  display flex
  justify-content space-between
  align-items center
  font-size 0.675rem
  margin-bottom 5px
  &:last-child
    margin-bottom 0
  label
    margin-bottom 1px
  input
    border 1px solid #ccc
    width 70px
    height 18px
    margin-left 20px
    padding 0px 3px
    &:disabled
      background-color #ececec

.tab__panel__calculator-reset
  border 1px solid #ccc
  border-radius 3px
  background-color #fff
  width 70px
  height 18px
  padding 0px 0px 1px
  &:hover
    background-color #ececec

.data__list
  padding: 0;
  list-style-type: none;
  margin: 0 0 25px 0;

  a
    color: #333;
    text-decoration: underline;

  &:last-child
    margin: 0;

.data__list__object
  text-decoration: underline;
  cursor: pointer;

.table__list
  lost-flex-container: row;
  max-width: 500px;
  margin: 0 0 25px 0;

  &:last-child
    margin: 0;

  dt,
  dd
    padding: 0;
    margin: 0;
    lost-column 1/2 2
    vertical-align: top;

.data__video
  position: relative;
  width: 350px;
  height: 200px;

.data__accessories
  display: flex;
  flex-wrap: wrap;

.data__accessory
  display: flex;
  flex-basis: 25%;
  margin-bottom: 14px;

.data__accessory__img
  flex-basis: 25%;
  margin-right: 10px;
  display: flex;
  align-items: center;

  img
    margin: auto;

.data__accessory__info
  flex-basis: 75%;
  font-size: 0.8rem;

  h4
    margin-top: 0;
    margin-bottom: 5px;

.data__sales__text
  margin-bottom: 10px;

  .sales-text
    display: none;

//Status colors
.inhouse
  .label-k0
    color: $b2b_k0_color
    font-weight: bold;

  .label-k1
    color: $b2b_k1_color
    font-weight: bold;

  .label-k2
    color: $b2b_k2_color;
    font-weight: bold;

  .extra-info
    color: $b2b_extra_info_color

  .online-price
    color: $brand_color

.inhouse__printed__price
  display: none;

.data__sales__text
  display: flex;
  padding-top: 14px;
  justify-content: flex-end;
