body.dragging
  user-select none


// Range slider
.range-slider
  position: relative;
  height 20px
  display flex
  +below($desktop)
    height 24px

.range-slider__line
  box(100%, 6px)
  border-radius: 8px;
  box-shadow: inset 1px 1px 2px rgba(0, 0, 0, 0.3);
  background-color: $range_slider_active_color;
  margin auto

.range-slider__handle
  position: absolute;
  circle(20px)
  box-shadow: $range_slider_dot_shadow
  border: $range_slider_dot_border
  background-color: $range_slider_dot_background_color
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  top 0
  transition all 150ms ease
  &.end-handle
    right 0
  &.start-handle
    left 0
  .dragging &
    transition none
  +below($mobile)
    circle(24px)

.range-slider__handle-line
  width: 1px;
  height: 50%;
  background-color: $range_slider_dot_lines_color;
  margin: 0px 2px;
  pointer-events: none;

.range-slider__handle::before,
.range-slider__handle::after
  content: '';
  width: 1px;
  height: 30%;
  background-color: $range_slider_dot_lines_color;
  pointer-events: none;
