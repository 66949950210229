

// DEFINED BREAKPOINTS
$mobile_small = 320px
$mobile = rupture.mobile-cutoff = 479px
$phablet = 550px
$mobile_landscape = 737px
$tablet = 767px
$desktop = 1023px
$wide = 1199px


define-breakpoint($name)
	&:after
		content $name
		display none
​
body
	+below($phablet)
		define-breakpoint('mobile')
		+retina()
			define-breakpoint('mobile_retina')
	+above($phablet + 1 )
		define-breakpoint('phablet')
	+above($tablet + 1)
		define-breakpoint('tablet')
	+above($desktop + 1)
		define-breakpoint('desktop')
	+above($wide + 1)
		define-breakpoint('wide')
