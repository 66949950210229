.checkbox__card
  position: relative;
  display flex
  align-items center
  cursor pointer
  input[type=checkbox]
    position: absolute
    visibility: hidden

.checkbox__card
  input[type=checkbox]:checked
    & ~ .checkbox__custom
      svg
          stroke-dashoffset 0

