.cof__mobile-wrapper
  margin-bottom 30px
  display flex
  .custom-text-input-wrapper
    margin-right 0 !important
  .text-input__validation-message
    width 300px
  .custom-text-input
    border-top-right-radius 0
    border-bottom-right-radius 0
  
.cof__mobile-label
  background-color $cof_service_mobile_color
  color #fff
  padding 0 15px
  display flex
  align-items center
  font-size .75rem
  max-width 240px
  position relative
  transition all 200ms
  max-height: 45px
  border-top-right-radius $input_border_radius
  border-bottom-right-radius $input_border_radius
  &:before
    content ''
    display block
    box(0)
    position absolute
    border solid 6px transparent
    border-right-color $cof_service_mobile_color
    left -12px
    top 50%
    margin-top -6px