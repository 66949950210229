.blog__category-nav
  background-color: rgba(0,0,0,0.6)
  margin-bottom: -41px
  position: relative;
  z-index: 2

.blog__category-nav__inner
  display: flex;
  justify-content: space-between;
  max-width: $page_width
  margin: auto
  align-items: center;
  +below($tablet)
    overflow: scroll;
  ul
    zero-list()
    margin-left: -20px
    white-space: nowrap;
    +below($desktop)
      margin-left: -15px
    +below($mobile)
      margin-left: -10px


  li
    display: inline-block;
  +below($page_width)
    padding: 0 15px

.blog__category-nav__link
  position: relative;
  line-height: 40px
  padding: 0 20px
  color: rgba(255, 255, 255, .9)
  display: block;
  text-decoration: none;
  transition: background-color 150ms ease
  &:hover
    background-color: rgba(255, 255, 255, .1)
    text-decoration: none;
  +below($page_width)
    padding: 0 15px
    font-size .9rem
  +below($mobile)
    font-size .8rem
    padding: 0 10px


.blog__category-nav__link-background
  position: absolute;
  display: block;
  opacity: 0
  left 5%
  right: 5%
  bottom: 20%
  top 20%
  border-radius: 4px
  pointer-events: none;
  z-index: -1
  .active &
    opacity: .7

.blog__category-nav-name
  pointer-events: none;

.blog__category-nav-amount
  font-size .8rem
  color rgba(255,255,255,.6)
  margin-left: 3px
  pointer-events: none;
  +below($mobile)
    font-size .7rem

.blog__to-shop-btn
  line-height: 31px;
  padding: 0 14px;
  +below($desktop)
    display: none;
