.video-play-icon
  position: absolute;
  box(100%)
  top 0
  left 0
  display: flex;
  align-items: center;
  justify-content: center;

.video-play-icon__inner
    box(120px, 60px)
    background-color: alpha($brand_color, .8)
    transition: background-color 500ms
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer
    &.youtube
      background-color alpha($youtube_color, .95)
      border-radius 15%
    &:hover
      background-color: alpha($brand_color, .95)
    &.youtube:hover
      background-color alpha($youtube_color, .8)
    &:after
      content: ''
      display: block;
      box(0)
      border: 20px solid transparent
      border-left-color #fff
      border-top-width: 15px
      border-bottom-width: 15px
      border-right-width: 0
      margin-left 5px
