.favorites-list-panel
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-width 200px
  font-size: 0.9rem;
  +below($tablet)
    min-width initial
  +below($phablet)
    width: 100%;
    justify-content: center;

.favorites-list-panel__btn-save
  margin-left: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover
    text-decoration: underline;

.favorites-list-panel__btn-save
  transition: all 300ms;
  &.prod-added
    text-decoration: none;
    cursor: default;

  svg
    box(26px, 24px)
    margin-left: 4px;
    fill $favorites_heart_fill
    stroke $favorites_heart_stroke
    stroke-width 7px;

  &.prod-added
    svg
      fill $brand_color;
      stroke $brand_color;

.favorites-full
  position: fixed;
  background: rgba(0,0,0,0);
  left 50vw
  top 40vh
  box(20px)
  border-radius: 20vh
  z-index: $favorites_overlay
  visibility: hidden;
  pointer-events: none;
  overflow: hidden;
  transition: background 100ms, all 500ms cubic-bezier(0.165, 0.840, 0.440, 1.000)
  &.show-list
    top 0
    left 0
    width: 100vw
    height: 100vh
    visibility: visible;
    pointer-events: all;
    border-radius: 0
    background: rgba(0,0,0,.7)
    .favorites-full__inner
      width: $page_width
      transition: all 300ms;
      transition-delay: 400ms;
      +below($wide)
        width: 90vw
        margin-top: 0

.favorites-full__inner
  display: flex
  flex-direction: column;
  width: 0;
  margin: 5vw auto 0 auto;

.favorites-full__heading
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  p
    color: #fff
    font-size: 1.5rem
    margin-bottom: 5px
    font-family: $font_family_header

.favorites-full__close
  display: flex
  padding: 10px 0 10px;
  font-family: $font_family_header
  color: #fff;
  cursor: pointer;

.favorites-full__close-icon
  box(18px)
  transform: rotate(90deg);
  transition: all 0.2s ease;
  margin-right: 10px;
  svg
    box(100%)
    fill: #fff;
  &:hover
    transform: rotate(90deg) scale(1.15)

.favorites-full__list
  display: flex
  background-color: #d4d4d4
  flex-wrap: wrap
  padding: 0 .5px 1px .5px
  max-height: 84vh;
  overflow-y: auto;
  +below($tablet)
    max-height: 74vh;

.favorites-list__item
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  padding: 40px 10px 20px
  width calc(25% - 1px)
  background-color: #fff
  margin: 1px .5px 0 .5px
  +below($desktop)
    width calc(33.333333333% - 1px)
  +below($tablet)
    width calc(50% - 1px)
  +below($mobile)
    width calc(100% - 1px)


.favorites-list__item__brand-logo
  max-height 20px
  max-width 100px
  position absolute
  left 15px
  top 15px

.favorites-list__item__img
  width: 130px
  pointer-events none

.favorites-list__item__stars
  display: none

.favorites-list__item__brand
  font-size $brand_font_size
  font-weight: $brand_font_weight
  color $brand_font_color
  text-transform uppercase
  margin-top: 10px;

.favorites-list__item__npkid
  font-family $font_family_base
  line-height: 1
  font-size 1rem
  font-weight: 700;
  margin-bottom: 15px

.favorites-list__item__price
  margin-top: 6px
  font-size: 1.3rem

.favorites-list__item__offer
  background-color: $offer_background_color;
  color: $offer_text_color;
  font-style: italic;
  padding: 2px 4px;
  font-size: .75rem

.favorites-list__item__buttons
  margin-top: 10px
  display: flex
  .button
    margin: 0 5px
  
.favorites-list__item__delete
  position: absolute;
  right: 15px
  top: 15px
  cursor: pointer;
  svg
    pointer-events: none;
    box(18px)
    fill #888

.favorits-list__add-to-cart
  > span
    pointer-events: none;
    transition: opacity 200ms
    display: inline-block;
  &.adding
    > span
      opacity: 0
  &.added
    background-color: $success_color
    pointer-events none;
    > span
      opacity: 0

.favorites-list__tick
  position: absolute;
  top 0
  left calc(50% - 15px)
  pointer-events: none;
  svg
    box(32px)
    stroke #fff
    stroke-width 2.5
    stroke-dasharray: 50
    stroke-dashoffset: 50
    transition: stroke-dashoffset 800ms ease-out 200ms, stroke 300ms
  .adding &, .added &
    svg
      stroke-dashoffset: 0