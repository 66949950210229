.preloader
  display:none;
  width:100%;
  height:100%;
  background:rgba(0, 0, 0, .7);
  position:fixed;
  z-index:999999;

  &.show-loader
    display: block;

.preloader__holder
  display: flex;
  align-items: center
  position: relative;
  circle(120px)
  background:#fff;
  margin: 300px auto;
  box-shadow: 0 0 3px 1px rgba(50, 50, 50, 0.50);
  text-align: center;
  border-radius: 200px

.preloader__spinner
  circle(100px)
  border: 10px solid rgba(0, 0, 0, .1)
  border-top: 10px solid $brand_color;
  animation: sweep 600ms infinite ease;
  margin: auto;

.preloader__loading
  filter:blur(2px);
