.vip__shortcuts-desktop
  width 100%
  display: flex;
  flex-direction: column
  align-items: flex-start;
  margin-bottom: 12px;
  a
    line-height: 1
    margin-bottom: 12px
  +below($desktop)
    display: none

.vip__shortcuts-mobile
  margin-bottom: 25px;
  a 
    display: flex;
    align-items: center;
  svg
    box(13px);
    fill: $anchor_text_color;
    margin-left: 2px;
    margin-top: 2px;
  +above($desktop + 1)
    display: none;
