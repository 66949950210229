.vip__alt-products__outer
  sectionOuter(#fff)

.vip__alt-products
  sectionInner($vip_inner_section_border_color)


.recommended__previous, .recommended__next
  c-slider-controls(30px)
  +below($wide)
    display: none !important

.recommended__previous
  left -40px

.recommended__next
  right: -40px

.alternative__product__slide
  .shop_SKOU &, .shop_SKOUNO &
    padding 4px

.recommended__slide__card
  position relative
  width: 24%
  margin-right: 1.5%
  border $shop_card_border
  box-shadow $shop_card_shadow
  border-radius $shop_card_border-radius
  padding: 20px
  display: flex;
  flex-direction: column;
  justify-content: flex-start
  align-items: center;
  &:last-child
    margin-right: 1px
  a
    position relative
    width: 100%
    text-align: center;
    &.loading::before
       content: "";
       circle(90px)
       position: absolute;
       box-sizing: border-box;
       left 50%
       top 50%
       margin-left -45px
       margin-top -45px
       border: 5px solid rgba(0, 0, 0, 0.3)
       border-top: 5px solid $brand_color
       animation: sweep 600ms infinite ease;
    &.loading
      display block
      width 100%
      max-height 230px
      padding-top 230px
      img
        display: none
    .no-click &
      pointer-events: none;
  img
    width: 100%
    max-width: 230px

  +below($wide)
    width: 32.3%
    &:nth-child(3)
      margin-right: 1px
    &:nth-child(4)
      display: none;
  +below($tablet)
    width 49%
    margin-right: 2%
    &:nth-child(2)
      margin-right: 1px
    &:nth-child(3)
      display: none
  +below($mobile)
    width 100%
    &:nth-child(1)
      margin-right: 0
    &:nth-child(2)
      display: none;

.recommended__card__title
  font-family: $font_family_base
  margin: 8px 0 0
  font-size: 1.2rem
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%
  a
    color: $body_text_color
    .no-click &
      pointer-events: none;


.recommended__card__review
  display: flex;
  justify-content: space-between;
  font-size: .9rem
  margin-top: 4px
  .rating-stars,
  .rating-stars__back,
  .rating-stars__front__inner
    box(90px, 16px)
    svg
      box(16px)
.recommended__card__review__avg
  color: $brand_color
  margin-left: 5px
.recommended__card__review__qty
  margin-left: 5px

.recommended__card__price
  font-size: 2.1rem
  margin-top: 10px
