.vip__services
  margin: $servies_margin
  padding: $servies_padding
  border-top: $services_border_style
  border-bottom: $services_border_style
  border-radius: $services_border_radius
  background-color: $services_background_color
  +below($desktop)
    margin-top: 5px
  +below($mobile)
    font-size 13px
  +mobile()
    margin-top: 20px

  .text-expand__toggle
    left 0
    text-align right

.vip__services__inner
  line-height 1.5

.vip__services__line
  display: flex;
  justify-content: space-between;

.vip__services__price
  font-weight: bold

.vip__services__terms
  font-weight: normal
  color: $body_text_color
  font-style: italic;
