$bar-width = 2px
$trigger-color = rgba(255,255,255,1)
$trigger-active-color = rgba(0,0,0,.5)

$hamburger-line
  height $bar-width
  background-color: $trigger-color
  position: absolute;
  display: block;

.menu-trigger
  display: none;
  position: absolute
  right: 0
  top 0
  box($header_height_mobile)
  outline: none
  +below($desktop)
    display: block;

.menu-trigger__bar
  @extend $hamburger-line
  left 16px
  right 16px
  transition: all 600ms ease
  &.top
    top 18px
    .trigger-close &
      transform: translate3d(1px,10px, 0) rotate3d(0,0,1,45deg)
  &.center
    top 23px
    .trigger-close &
      background-color: transparent;
  &.bottom
    top 28px
    .trigger-close &
      transform: translate3d(1px, 0px, 0) rotate3d(0,0,1,-45deg)

.menu-trigger_label
  color: $trigger-color
  font-size: .5rem
  text-decoration: none;
  position: absolute;
  bottom 12px
  left 16px
  right: 16px
  transition: opacity 350ms ease
  text-transform: uppercase;
  text-align: center;
  .trigger-close &
    opacity: 0
