.cof__section-footer
  border $cof_section_border_darker
  background-color $cof_section_footer_bg
  justify-content: flex-end;
  text-align: right;
  width: 100%
  display flex
  +below($mobile)
    border none
    border-top: $cof_section_border_darker

.cof__footer-price-wrapper
  padding: 15px 50px
  +below($mobile)
    padding: 12px 20px

.cof__footer-button-wrapper
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 15px 35px
  +below($mobile)
    padding: 12px 20px

.cof__price-label
  opacity: .9

.cof__price-amount
  font-size 1.5rem
  margin-left: 5px

.cof__price-tax-label
  display: block;
  font-size .8rem
  color $cof_section_footer_label_color

.cof__footer-b2b-wrapper
  width 300px
  .summary-row
    display: flex
    width: 100%
    justify-content: space-between;
    color: $cof_li_item_font_color
    font-size .875rem
    padding: 2px 0
    align-items baseline
