.career__video
  width: 100%
  overflow: hidden;
  height: 500px;
  video
    object-fit: cover;
    width:100%;
    height:100%;

.career__wrapper
  pageWrapper()

.career__block
  padding: 50px 0;
  h1
    margin-top: 0

.career__block-header
  font-size: 1.8rem;
  text-align: center;
  margin-top: 0;
  margin-bottom: 20px

.career_center-text
  display: flex;
  justify-content: center;
  div
    max-width: 800px

.career__block-intro
  text-align: center;
  margin-bottom: 30px;

.career__top-text
  max-width: 800px;
