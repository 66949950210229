.main-content
  .srp__page &
    background-color $srp_background_color

.srp
  padding-top 15px

.srp-body
  standardSection()
  display flex
  margin-bottom 50px

.srp-meta-top
  standardSection()

.srp-footer
  standardSection()
  margin-bottom 60px
  .srp-footer__text-column
    max-width: 750px
