.styleguide__content
  background-color: #fff
  box-shadow: 0 0 15px rgba(0,0,0, .3)
  padding: 0 70px 50px
  min-height: 800px
  width 875px
  position: relative
  z-index: 1

.styleguide__section-heading
  font-size: 1rem
  font-family: $font_family_base
  color: $neutral_dark_color
  border-bottom solid 1px $neutral_light_color
  padding-bottom 15px
  margin-top: 60px

.section-heading__number
  font-size: .75rem

.styleguide__subheading
  font-family: $font_family_base
  font-weight: bold
  font-size: 1.6rem
  position: relative
  padding-bottom: 8px
  margin: 40px 0 50px
  &:after
    content: ''
    display: block
    box(45px, 3px)
    background-color: $neutral_light_color
    position: absolute
    bottom: 0

.styleguide__label
  font-weight: bold
  color $brand_color
  margin-top 25px

.styleguide__label-gray
  color: $neutral_dark_color
  margin-top: 20px

// TYPO SECTION
.styleguide__font-example-wrap
  display: flex
  justify-content: space-between
  dl
    width: 30%
    margin-top: 6px
  dt, dd
    display: inline-block
    margin 0 0 3px 0
  dt
    color $neutral_dark_color
    width 115px

.styleguide__font-example
  width 65%
  margin-top: 6px
  &.bold
    font-weight: bold


// COLOR SECTION

.styleguide__color-wrap
  lost-flex-container row

.styleguide__color
  lost-column 1/4 4 0
  height: 180px
  position: relative;
  //padding-bottom: 25%
  //position: relative
  > div
    position: absolute
    bottom 0
    padding: 15px
    background-color: inherit
  span
    display: block
    color #fff
    font-size: .8rem

  //Base colors
  &.brand-color
    background-color: $brand_color
  &.brand-color-var
    background-color: $brand_color_variation
  &.brand-color-var2
    background-color: $brand_color_variation_2
  &.brand-color-dim
    background-color: $brand_color_dim
  &.action-color
    background-color: $action_color
  &.action-text-color
    background-color: $action_text_color
  &.neutral-color
    background-color: $neutral_color
  &.neutral-dark-color
    background-color: $neutral_dark_color
  &.neutral-light-color
    background-color: $neutral_light_color
  &.neutral-lightest-color
    background-color: $neutral_lightest_color
  &.black-color
    background-color: $black
  &.dark-gray-color
    background-color: $dark_gray

  //Offer colors
  &.offer-color
    background-color: $offer_background_color
  &.offer-text-color
    background-color: $offer_text_color
  &.offer-color-se
    background-color: $offer_background_color_se
  &.offer-text-color-se
    background-color: $offer_text_color_se

  //Typography colors
  &.link-color
    background-color: $anchor_text_color
  &.headings-color
    background-color: #222
  &.body-color
    background-color: $body_text_color

  //Stock status colors
  &.in-stock-color
    background-color: $in_stock_color
  &.not-in-stock-color
    background-color: $not_in_stock_color

  //State colors
  &.success-color
    background-color: $success_color
  &.danger-color
    background-color: $danger_color
  &.warning-color
    background-color: $warning_color


// BUTTONS

.styleguide__buttons-wrap
  lost-flex-container row

.styleguide__buttons-column
  lost-column 1/2


// ICONS

.styleguide__icons
  lost-flex-container row

.styleguide__svg-wrap
  lost-column 1/6 6 40px
  height: 50px
  display: inline-block
  margin-bottom: 40px
  svg
    box(100%, 40px)
  &:hover
    svg
     stroke: $neutral_dark_color

.styleguide__svg-title
  color: $neutral_dark_color
  font-size: .7rem
  truncate()
  text-align: center

.styleguide__energy-label-wrap
  margin-bottom: 20px
  display: flex;
  div
    margin-right: 10px

.styleguide__energy-stars-wrap
  display: flex
  align-items: center;
  span
    margin-left: 5px
