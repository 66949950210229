sectionOuter(background-color)
  width 100%
  padding 30px 0px 60px
  background-color background-color
  +below($wide)
    padding 20px 30px 40px
  +below($desktop)
    padding 20px 20px 40px
  +below($tablet)
    padding 20px 20px
  +below($mobile)
    padding 10px 12px

sectionInner(border-color = transparent)
  width: $page_width
  margin: 0 auto;
  border-top: 2px solid border-color
  +below($wide)
    width: 100%;
  +below($mobile)
    padding 10px 0px 25px 0
  h2
    font-size: 1.8rem
    border-left:  $vip_headers_border;
    padding-left:  $vip_headers_padding;
    +below($mobile)
      font-size 1.3rem
      margin: 0px 0px 10px 0px

sectionInnerAccordian(border-color = transparent)
  width: $page_width
  margin: 0 auto;
  +below($wide)
    width: 100%;
  +below($mobile)
    padding 10px 0px 25px 0
  h2
    font-size: 1.8rem
    padding-left:  $vip_headers_padding;
    +below($mobile)
      font-size 1.3rem
      margin: 0px 0px 10px 0px
