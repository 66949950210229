.vip__price-box
  background-color: $gray_background_light
  border: $vip_price_box_border
  box-shadow: $vip_price_box_box_shadow
  border-radius: $vip_price_box_border_radius

.vip__price-box-wrapper
  display: flex
  justify-content: space-between;
  padding: $vip_price_box_top_padding
  +below($mobile)
    padding: $vip_price_box_top_padding_mobile

.vip__price-box-product
  line-height: 1
  align-items: flex-start 

.vip__price-box-label
  font-size: .875rem
  color: #888

.vip__price-box-price
  position: relative;
  display: inline-block;
  font-size: 1.625rem
  color: $price_color
  overflow: hidden;
  +below($mobile)
    margin-bottom: 10px
  &.stroke
    &:after
      content: ''
      position absolute
      width 97%
      left -2%
      top 50%
      border-bottom 2px solid rgba(80, 80, 80, 0.8)
      transform skewY(-11deg)

.vip__price-box-discount
  flex-grow: 2

.vip__price-box-buttons
  display: flex
  margin-top: 4px
  padding: $vip_price_box_bottom_padding
  +below($mobile)
    padding: $vip_price_box_bottom_padding_mobile

.vip__price-add-to-cart-wrap
  flex-grow: 2
  display flex
  flex-direction column
  .button-action
    transform none
  button
    .brand_tretti &
      border-radius: 0;
    *
      pointer-events none

    &.button-secondary
      display flex
      gap 8px
      align-items center
      justify-content: center
      flex-grow 1
      background-color #ffffff
      color #514f4f
      outline 2px solid #514f4f
      outline-offset -2px

      :hover
        background-color: #F5FBF5

      svg
        box(24px)
        fill #514f4f

.vip__price-favorites-save
  flex-center-children()
  background-color: $vip_price_box_save_button_background_color
  border: 1px solid $vip_price_box_save_button_border_color
  width: 66px
  margin-left: $vip_price_box_save_button_gutter
  cursor: pointer;
  .icon-wrap
    flex-center-children()
  svg
    fill: $vip_price_box_save_button_icon_background_color
    transition: .25s all ease-out
    width: 40px
    height: 30px
  &.prod-added
    .icon-wrap
      animation 0.3s add-as-favorite ease
    svg
      fill $brand_color

.vip__price-box_product-info
  padding: 16px 14px 12px

.vip__price-box-delivery-date
  display: none;
  padding: 0 16px 16px 16px;
  margin-top: -8px;

.vip__price-exclusive-delivery-text
  top: 28px;
  color: $dark_gray
  font-size: .7rem
  font-style: italic;
  display: flex
  justify-content: left
  padding-left: 14px
  line-height: .9rem;
