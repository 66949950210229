.cof__payment-header
  display: flex;
  padding-top: 15px;
  padding-left: 15px;
  +below($mobile)
    padding-left: 7px;

.cof__payment-wrapper
  margin: 0 auto;
  max-width: 670px;
.cof__section-heading
  padding-left: 30px;
  padding-right: 30px;
  text-transform: none;
  +below($tablet)
    font-size 1.2rem

.cof__payment-container
  margin: 0 auto;
  max-width: 670px;
  +below($page_width)
    padding-left: 15px
    padding-right: 15px
  +below($mobile)
    padding-left: 7px
    padding-right: 7px

.cof__payment-inner
  background-color: #fff;
  border-left: $cof_section_border;
  border-right: $cof_section_border;
  border-bottom: $cof_section_border;
  padding: 30px;
  width: 100%;

.cof__payment-total
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #ccc
  padding-top: 8px;
  line-height: 1;

.cof__payment-inner
  position: relative;

.cof__payment-total-label
  font-weight: bold;

.cof__payment-total-price
  font-size: 1.6rem;

.cof__payment-tax-label
  font-size: .875rem
  text-align: right;

.cof__payment-buttons
  margin-top: 36px;
  display: flex;
  justify-content: space-between;
  a
    margin-bottom: 20px;
  +below($mobile)
    flex-direction: column;
    align-items: center

.cof__payment-footer
  padding: 15px 0;
  margin-top: 50px;
  border-top: $cof_section_border
  font-size: 0.875rem;
  color: rgba(0,0,0,0.4);
