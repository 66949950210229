.cof__calendar-header
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 25px
  h3
    margin: 0
    font-size: 1.1rem
  +below($tablet)
    flex-direction: column;
    align-items: flex-start;
    h3
      margin-bottom: 10px

.cof__calendar-toggle
  display: flex;
  align-items: center;
  font-size: .875rem
  border: 1px solid $gray_border
  cursor: pointer;
  span
    color: #999;
    margin-left: 5px
  +below($tablet)
    width: 100%

.cof__calendar-toggle-icon,
.cof__calendar-toggle-arrow
  display: flex
  align-items: center;
  justify-content: center;
  box(30px)

.cof__calendar-toggle-icon
  background-color: rgba(250,250,250,1)
  margin-right: 6px
  svg
    box(18px)

.cof__calendar-toggle-arrow
  svg
    transition: transform 200ms ease;
    box(14px)
  &.open
    svg
      transform rotate(90deg)
  +below($tablet)
    margin-right: 6px
    flex-grow: 1;
    justify-content: flex-end;

.cof__calendar-matrix-wrapper
  position: relative;
  overflow: hidden;
  width: 90%
  +below($tablet)
    width: 80%
  +below($mobile)
    width: 70%

.cof__calendar-matrix-slides
  white-space: nowrap;
  transition: transform 200ms ease

.cof__calendar-matrix
  display: inline-flex;
  justify-content: flex-start;
  width: 100%

.cof__calendar-body
  display: flex
  overflow: hidden;
  margin: 14px 0

.cof__calendar-intervals-header,
.cof__calendar-day-header
  display: flex
  align-items: center;
  justify-content: center;
  height: 60px

.cof__calendar-day-express-header
  position: absolute;
  margin-top: -27px;
  width: 67px
  +below($tablet)
    width: calc(100% / 3)

.cof__calendar-day-express-header-title
  background-color: #8DC73F
  color: white
  text-transform: uppercase
  font-size: 0.75rem
  text-align: center
  padding-top: 5px
  padding-bottom: 5px

.cof__calendar-day-header
  flex-direction: column;
  border-bottom: 1px solid $gray_border
  text-transform: capitalize;
  .weekday
    font-size: .875rem
  .date
    color: #999
    font-size: .75rem
  &.disabled
    color rgba(170, 170, 170, 1)

.cof__calendar-day
  width: calc(100% / 7)
  margin-top: 27px
  &:last-child
    .cof__calendar-slot
      border-right: 1px solid $gray_border
  +below($tablet)
    width: calc(100% / 3)
  &.express
    border-left: 1px solid #8DC73F
    border-right: 1px solid #8DC73F
    border-bottom: 1px solid #8DC73F

.cof__calendar-intervals
  width: 10%
  color: #999
  font-size: .875rem
  margin-top: 29px
  +below($tablet)
    width: 20%
  +below($mobile)
    width: 30%

.cof__calendar-interval,
.cof__calendar-slot
  display: flex
  justify-content: center;
  align-items: center;
  height: 46px

.cof__calendar-slot
  border-bottom: 1px solid $gray_border
  border-left: 1px solid $gray_border
  font-size: .875rem
  cursor: pointer;
  &:hover
    background-color: #f8f8f8
  &.disabled
    cursor: default;
    background-color: rgba(235,235,235,1)
    pointer-events: none;
  &.free
    color: $brand_color
  &.selected
    background-color: $brand_color
    color: #fff

.cof__calendar-navigator
  display: flex
  justify-content: space-between;
  margin-left: 10%
  width: 90%
  +below($tablet)
    width: 80%
    margin-left: 20%
  +below($mobile)
    width: 70%
    margin-left: 30%

.cof__calendar-navigator-icon
  circle(26px)
  background-color: $brand_color
  display: flex;
  align-items: center;
  justify-content: center;
  svg
    box(12px)
    fill: #fff

.cof__calendar-navigator-back
.cof__calendar-navigator-forward
  cursor: pointer;
  font-size: .875rem
  display: flex;
  align-items: center

.cof__calendar-navigator-back
  span
    margin-left: 8px

.cof__calendar-navigator-forward
  span
    margin-right: 8px

$clock-icon-size = 26px

.cof__calendar-clock-icon
  circle($clock-icon-size)
  display: flex
  position: relative
  align-items: center
  justify-content: center
  border: 2px solid $brand_color
  &:after
    position: absolute
    content: ""
    height: "calc(%s / 2 - 4px)" % $clock-icon-size
    width: 2px
    margin-top: "calc(-%s / 4 + 3px)" % $clock-icon-size
    margin-left: 1px
    background-color: $brand_color
  &:before
    position: absolute
    content: ""
    height: 2px
    width: "calc(%s / 2 - 4px)" % $clock-icon-size
    margin-top: 1px
    margin-left: "calc(-%s / 4 + 3px)" % $clock-icon-size
    background-color: $brand_color
