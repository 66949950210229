.srp-empty-result
  padding 55px 70px
  background-color: #fff
  border-radius: 2px
  box-shadow: 1px 1px 10px rgba(0,0,0,.3)
  margin 40px
  +below($wide)
    margin 40px 20px
    padding: 50px
  +below($tablet)
    background-color: transparent
    text-align: center;
    padding: 0
    margin: 40px 0
    box-shadow: none

.srp-empty-result__title
  font-family $font_family_header
  font-size 2.5rem
  line-height 1.2
  margin-bottom 14px
  max-width: 570px
  +below($wide)
    font-size 1.9rem
    max-width: none;
  +below($tablet)
    font-size 1.7rem
  +below($mobile)
    font-size 1.5rem

.srp-empty-result__sub-title-desktop
  +below($tablet)
    display none

.srp-empty-result__sub-title-mobile
  color: rgba(0,0,0,.5)
  display: none
  +below($tablet)
    display: block;

.srp-empty-result__call-box
  padding 40px 50px
  background-color: $srp_empty_call_box_background_color
  color rgba(255,255,255,.9)
  position: relative;
  margin-top: 60px
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius 8px
  text-align left
  +below($wide)
    padding 40px
  +below($tablet)
    padding 30px 20px
  +below($mobile)
    padding 20px
  img
    circle(150px)
    border solid 3px #fff
    margin-right: 34px
    +below($wide)
      circle(120px)
      margin-right: 25px
    +below($tablet)
      circle(100px)
      margin-right: 20px
  a
    text-transform uppercase
    font-size 2.5rem
    font-family $font_family_header
    color #fff
    text-decoration none
    +below($wide)
      font-size 1.8rem
    +below($mobile)
      font-size 1.2rem
      text-decoration: underline;
  p
    margin: .3em 0
    +below($mobile)
      font-size .875rem
  .call-box-text__call-mobile
    display none
    +below($mobile)
      display: inline
  .call-box-text__call-desktop
    +below($mobile)
      display: none
  .call-box-text__after
    font-style: italic;
    font-size .875rem
    +below($mobile)
      font-size .7rem
  &:before
    content ''
    display: block;
    position: absolute;
    box(0)
    border solid 30px transparent
    border-right-color $srp_empty_call_box_background_color
    transform rotate(-45deg)
    top -29px
    left: 15px
