.cof__date-picker-wrapper
  display: flex
  width: 50%;
  +below($tablet)
    width 100%

.cof__date-picker-icon
  height: 100%
  box(45px)
  background: $neutral_dark_color
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer
  svg
    fill: #fff
    box(20px)
    margin-bottom: 2px

.cof__date-picker
  flex-grow: 2
  .cell
    font-size: .875rem
    &.selected
      border: 1px solid $action_color
      background: $action_color !important
      color: #fff
    &:hover
      border: 1px solid $action_color !important
  header
    .up
      color: $brand_color
      font-weight: bold
      text-transform: uppercase;
