.career__about
  background-color: $brand_color;
  color: #fff;
  h2
    font-size: 2rem
    margin: 0
    line-height: 1
    color: #fff;
  p
    margin: 0
    +below($desktop)
      margin-bottom: 14px

.career__about-wrapper
  display: flex;
  justify-content: space-between;
  align-items: center;
  +below($desktop)
    align-items: flex-start;
    flex-direction: column;
