.usp__site-streamer
  display: none
  background: $streamer_background_color
  position: absolute;
  right: 0
  padding: 0 50px
  margin: 7px 0
  height calc(100% - 14px)
  max-width: 40%
  width: 100%
  transition: all 500ms ease
  top 0
  color: $streamer_text_color
  +below($wide)
    right 20px
    padding: 0 30px
    max-width: 35%
  +below($desktop)
    right 0
    max-width: 100%
    height: 100%
    margin: 0
    position: relative;
    padding: 5px 20px
  .brand_skousen &,
  .brand_tretti &
    position: relative;
    margin: 0
    padding: 5px 10px
    right: 0
    max-width: 100%
  .active-streamer &
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  .manufactor__page &
    display: none
  &.truncated
    cursor: pointer;
  &.show-truncated
    margin 0
    height 100%
    max-width: 100%
    cursor: default;
    .streamer-icon__angle
      opacity 0
  p
    margin: 0
  a
    color $streamer_text_color
    text-decoration: underline;

.usp__site-streamer__text
  font-size 1rem
  &.truncated:after
    opacity: 1
    .show-truncated &
      opacity: 0

.usp__site-streamer__details
  font-size: .8rem
  max-height 0
  opacity: 0
  overflow: hidden;
  transition: all 500ms
  .show-truncated &
    max-height: 80px
    opacity: 1

.streamer-icon__angle,
.streamer-icon__times
  display flex;
  box(12px)
  line-height: inherit;
  position: absolute;
  right: 10px
  top: 10px
  cursor: pointer;
  pointer-events: none;
  .show-truncated &
    pointer-events: auto;
  svg
    box(12px)
    fill: $streamer_text_color