html
  font-size: $font_size_base; //THIS IS THE DEFAULT BASE OF REM CALCULATIONS

body
  font-family: $font_family_base;
  line-height: $font_line_height_base;
  color: $font_color_base;

h1
  font-family: $font_family_header;
  font-weight: $font_family_header_weight;
  text-transform: $font_family_header_transform;
  font-size $font_size_h1;
  font-style: $font_family_header_style;
  +below($desktop)
    font-size: 1.6rem
  +below($tablet)
    font-size: 1.45rem

h2, h3, h4, h5, h6
  font-family: $font_family_header_alternate;
  font-weight: $font_family_header_alternate_weight;
  font-style: $font_family_header_alternate_style;
  text-transform: $font_family_header_alternate_transform;

h1, h2, h3, h4
  margin-top: 1.2em
  margin-bottom: .4em
  overflow-wrap: break-word;
  word-break: break-word;
  hyphens: auto;
  color $font_family_header_color

h3
  font-size: 1.3rem
h4
  font-size: 1rem
a
  text-decoration: none
  color: $anchor_text_color;
  &:hover
    text-decoration: underline;

p
  margin: .6em 0
