.tretti-header__outer
  display: flex;
  flex-direction: column;
  .usp__site-streamer
    +below($desktop)
      order: 3;
  .usp
    +below($desktop)
      order: 2;
  .tretti-header
    +below($desktop)
      order: 1;

.tretti-header
  display: flex;
  height: $header_height;
  width: 100%;
  background-color: #f7f7f7;
  border-bottom: 1px solid #eee;
  justify-content: center;
  +below($desktop)
    z-index: $mobile_menu_Z;
    height: $header_height_mobile;
    background-color: $brand_color;
    position: fixed;
    border-bottom: 0;

.tretti-header__placeholder
  +below($desktop)
    height: $header_height_mobile;

.tretti-header__inner
  display: flex;
  position: relative;
  width: $page_width;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 30px 0;
  +above($desktop)
    .blog__page &
      padding:0;
  +below($wide)
    padding: 0 30px 30px 30px;
  +below($desktop)
    width: 100%;
    padding: 0 $header_height_mobile 0 20px;
  +below($mobile)
    padding: 0 $header_height_mobile 0 10px;

  .header-main__widgets
    +above($desktop)
      display: flex;
      position: absolute;
      right: 0;
      top: 114px;
      z-index: 999;

  .favorites-widget,
  .basket-widget
    +above($desktop)
      position: relative;
      box(70px);
      background-color: transparent;
      svg
        fill: #fff;
  
  .search__submit
    background-color: transparent;
    svg
      fill: #333;