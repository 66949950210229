/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active
  transition: all .5s ease;


.slide-fade-leave-active
  transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  max-height: 700px

.slide-fade-enter, .slide-fade-leave-to
  opacity: 0
  max-height 0


.cof__services
  background-color: $cof_section_bg
  +below($mobile)
    background-color #fff

.cof__services-inner
  lost-center $cof_center_col_width
  flex-flow: column;
  padding-top 30px
  h3
    margin-top 0
    +below($mobile)
      font-size 1rem

.service-cards-disabled
  opacity .5
  pointer-events none

.cof__services-zip-wrapper
  flex-wrap: wrap;
  margin-bottom 30px
  display flex
  align-items flex-start
  .custom-text-input-wrapper
    margin-right 0 !important
  .text-input__validation-message
    width 300px
  .custom-text-input
    border-top-right-radius 0
    border-bottom-right-radius 0

.cof__services-zip-island
  width: 100%
  margin-top: 10px

.cof__zip-label
  background-color $cof_service_zip_background
  color #fff
  padding 0 15px
  display flex
  align-items center
  font-size .75rem
  max-width 240px
  position relative
  transition all 200ms
  height 45px
  border-top-right-radius $input_border_radius
  border-bottom-right-radius $input_border_radius
  &:before
    content ''
    display block
    box(0)
    position absolute
    border solid 6px transparent
    border-right-color $cof_service_zip_background
    left -12px
    top 50%
    margin-top -6px
  &.found
    background-color $gray_background
    color rgb(144,144,144)
    font-size .875rem
    text-transform capitalize
    &:before
      display none



.cof__services-card
  box-shadow: inset 0 0 6px rgba(0,0,0,0.2)
  padding: 20px 20px 15px
  margin-bottom: 30px
  background-color #fff
  border $cof_section_border_darker
  +below($mobile)
    box-shadow: none
    padding: 0
    margin-bottom: 40px
    border none

.services-card__product
  margin-bottom: 20px
  .cof__product-wrap
    max-width none
  .cof__product-img-wrap
    border solid 1px #e6e6e6
    padding 15px
    img
      box(90px)
  .cof__product-info
    max-width none
  +below($mobile)
    margin-bottom 15px
    margin-left: 0
    border $cof_section_border
    .cof__product-img-wrap
      border none


.services-card__service-group ul
  zero-list()
  margin-bottom: 25px
  &:last-child
    margin-bottom 0

.cof__services-item
  display: flex
  justify-content: space-between;
  align-items: center;
  border solid 1px #ccc
  border-radius $cof_service_border_radius
  width: 100%
  padding: 12px 20px
  margin-bottom: 10px
  background-color: rgba(250,250,250,1)
  font-size .875rem
  white-space: nowrap;
  color $cof_li_item_font_color
  transition: margin-bottom 300ms ease 500ms
  &:hover
    cursor pointer
  input
    margin-right: 10px
  &.checked
    border 1px solid $cof_selection_border_color
    box-shadow: inset 0px 1px 5px rgba(0,0,0,0.2), 0 0 1px 0 $cof_selection_border_color
  &.expanded
    margin-bottom: 0
    border-bottom-left-radius: 0
    border-bottom-right-radius: 0
    transition: margin-bottom 100ms ease 0ms
  +below($tablet)
    span, p
      display: block;
      max-width: 200px
      truncate()
  +below($mobile)
    padding: 12px 15px


squareTrigger()
  box(12px)
  border solid 1px #999
  border-radius: 2px
  &:before, &:after
    background-color: #999

roundTrigger()
  circle(14px)
  background-color: #ccc
  &:before, &:after
    background-color: #fff


trigger()
  cursor: pointer;
  position: relative;
  display: flex
  transition: transform 200ms ease-out
  &:before
    content: ''
    position: absolute;
    left 50%
    margin -4px 0 0 -1px
    top 50%
    display: block;
    box(2px, 8px)
  &:after
    content: ''
    margin: auto;
    display: block;
    box(8px, 2px)
  +below($mobile)
    margin-left 10px


.cof__services-info-trigger
  margin-left 20px
  trigger()
  .expanded &
    transform: rotate(180deg);
    &:before
      opacity 0

.brand_whiteaway .cof__services-info-trigger
  roundTrigger()
body:not(.brand_whiteaway) .cof__services-info-trigger
  squareTrigger()

.cof__services__price
  flex-grow: 2
  text-align right
  .service-cards-disabled &
    display none

.cof__service-details
  background-color: rgba(245,245,245,.8)
  color $cof_service_details_font_color
  padding: 15px 0
  border solid 1px #ccc
  border-top: none
  margin-bottom: 10px
  font-size .875rem

.service-details__text-block
  padding: 0 20px
  ul
    list-style: disc
    padding-left 20px
  li
    margin-bottom 5px

.service-details__opt-in
  padding: 20px 20px 25px 40px
  background-color: $brand_color
  color #fff
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  &.disabled
    opacity: .8
    pointer-events: none;
  .checkbox__label
    max-width: 80%

.cof__services-card__delivery-link
  font-size .8rem
  text-align right
  margin-top 18px
  +below($mobile)
    text-align left

.cof__comment-trigger-wrapper
  display flex
  align-items center
  cursor pointer
  +below($mobile)
    font-size .875rem

.cof__comment-trigger
  squareTrigger()
  trigger()
  margin-right 10px
  &.expanded
    transform: rotate(180deg);
    &:before
      opacity 0
