.vip__mobile__offcanvas-items
  width: 100%
  +above($desktop + 1)
    display: none

.vip__mobile__offcanvas-cards
  +above($desktop + 1)
    display: none

.vip__mobile__offcanvas-card
  position: fixed;
  background: #fff
  padding: 20px 16px 120px 16px
  z-index: $vip_off_canvas_Z
  top: 0
  right: -200vw
  height: 100vh
  overflow-y: scroll
  smooth-scroll()
  width: 100vw
  transition: right 500ms ease-in-out
  &.card-active
    right: 0

.vip__mobile__offcanvas-item
  display: flex
  align-items: center;
  border-bottom 1px solid #ccc
  width: 100%
  padding: 12px 20px
  background-color: rgba(250,250,250,1)
  cursor: pointer;
  &:first-child
    border-top 1px solid #ccc
  > svg:first-child
    box(28px)
    fill: $brand_color
    margin-right: 15px
    min-width: 30px
  > svg:last-child
    box(22px)
    min-width: 22px;
    fill: #5f5f5f
  > span
      flex-grow: 1
      +below($mobile)
        font-size: .875rem
  .in-stock
    color: $in_stock_color
    margin-right: 5px
  .rating-stars
    margin-right: 5px
  .item-campaign
    background-color: $srp_neutral_bg_color
    padding 5px 10px

.vip__mobile__offcanvas-card-close
  display: flex;
  align-items: center;
  cursor:pointer;
  color: $anchor_text_color
  svg
    box(22px)
    margin-right: 5px
    fill: $anchor_text_color

.vip__mobile__offcanvas-card-header
  display: flex;
  align-items: center;
  margin-top: 24px
  margin-bottom: 18px
  flex-wrap: wrap;
  line-height: 1
  .card-header-icon
    display: flex
    align-items: center;
    margin-right: 8px
    svg
      box(28px)
      fill: $brand_color
  h2
    font-size: 1.5rem
    margin: 0 10px 0 0
    text-transform: capitalize;

.vip__mobile__offcanvas-stars
  display: flex
  align-items: center
  .rating-stars
    margin-right: 5px

.vip__mobile__offcanvas-insurance
  display: flex;
  justify-content: space-between;
  span
    font-weight: bold

.vip__mobile__offcanvas-campaign-header
  background-color: $srp_neutral_bg_color
  padding 5px 10px
