.product__card
  shopCard()
  &.recommender
    border: 2px solid $brand_color

.product__card-image
  display: flex
  justify-content: center;
  align-items: center;
  width: 100%
  &.loading::before
     content: "";
     circle(90px)
     position: absolute;
     box-sizing: border-box;
     left 50%
     top 50%
     margin-left -45px
     margin-top -45px
     border: 5px solid rgba(0, 0, 0, 0.3)
     border-top: 5px solid $brand_color
     animation: sweep 600ms infinite ease;
  &.loading
    display block
    width 100%
    max-height 230px
    padding-top 230px
    img
      display: none
  .no-click &
    pointer-events: none;

.product__card-top
  display: flex;
  flex-direction: column;
  padding: 14px 14px 10px 14px
  position relative

.product__card-badge
  position absolute
  top:0
  left: 0
  transform: scale(0.8);
  z-index: 3

.product__card-top-data
  display: flex
  justify-content: center;
  align-items: center;
  margin-top: 10px
  height 24px

.product__card-divider
  height: 1px
  background-color: #eee

.product__card-middle
  display: flex
  padding: 14px
  justify-content: space-between;
  margin-bottom: 4px
  height 70px

.product__card-title
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  line-height: 1
  width: 65%
  &:hover
    text-decoration: none;

.product__card-name
  font-family: $font_family_header
  font-style: $font_family_header_style
  color: $shop_card_header_color
  font-size: 1.1rem
  text-transform: uppercase;

.product__card-npk
  color: $body_text_color
  font-size: .8rem

.product__card-prices
  line-height: 1.2
  justify-content: space-between;
  align-items: center;

.product__card-price
  font-size: 1.5rem
  color: $price_color

.product__card-offer-text
  top: 28px;
  background-color: $offer_background_color
  color: $offer_text_color
  padding: 2px 6px
  font-size: .7rem
  font-style: italic;
  align-items: center
  display: inline

.product__card-offer-text-filler
  top: 28px;
  padding: 2px 6px;
  font-size: 0.7rem;
  font-style: italic;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: flex;
  min-height: 18px;


.product__card-bottom
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 14px 14px 14px
  color: $body_text_color
  font-size: .9rem
  height 125px

.product__card-spec
  truncate()
  max-width: 240px;

.product__card-energy-anchor
  &:hover
    text-decoration: none

.product__card-recommender__label,
.product__card-last-seen__label-1
.product__card-last-seen__label-2
.product__card-last-seen__label-3
  padding: 7px 14px
  color: #fff
  z-index: 1
  width: 100%
  text-align: center

.product__card-recommender__label
  background-color: $brand_color

.product__card-last-seen__label-1
  background-color: $brand_color_variation
  border-top-left-radius: "calc(%s - 1px)" % $shop_card_border-radius
  border-top-right-radius: "calc(%s - 1px)" % $shop_card_border-radius

.product__card-last-seen__label-2,
.product__card-last-seen__label-3
  visibility: hidden

.product__card-exclusive-delivery-text-right
  top: 28px;
  color: $dark_gray
  font-size: .7rem
  font-style: italic;
  display: flex
  justify-content: right

.product__card-exclusive-delivery-text
  top: 28px;
  color: $dark_gray
  font-size: .7rem
  font-style: italic;
  display: flex
  justify-content: left