.vip__reviews__outer
  sectionOuter($vip_section_background_color)
  +below($desktop)
    display: none
  &.no-reviews
    padding-top: 0
    padding-bottom: 18px
    +below($mobile)
      padding-bottom: 0

.vip__reviews
  sectionInner($vip_inner_section_border_color)

.vip__reviews__header
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: baseline;
  +below($tablet)
    justify-content: space-between;
    .no-reviews &
      justify-content: flex-start;
  h2
    margin-right: 30px
    .no-reviews &
      font-size: 1.2rem
      margin-right: 4px
    +below($tablet)
      flex-grow: 2
      margin-right: 0
      .no-reviews &
        flex-grow: 0
        margin-right: 4px

  .rating-stars
    box(130px, 22px)
    .no-reviews &
      display: none;
    svg
      box(22px)
    +below($tablet)
      box(108px, 20px)
      svg
        box(20px)
    +below($mobile)
      box(108px, 20px)
      svg
        box(20px)
  .rating-stars__back, .rating-stars__front__inner
    box(130px, 22px)
    +below($tablet)
      box(108px, 20px)
    +below($mobile)
      box(108px, 20px)


.vip__reviews__header__label
  margin-left: 10px
  margin-right 15px
  height: 18px;
  line-height: 1.4;
  .no-reviews &
    margin-left: 0
    margin-right 10px
    font-size: 1rem

.vip__reviews__header__links
  font-size: 1rem
  +below($desktop)
    margin -10px 0 10px 0

.reviews__previous, .reviews__next
  c-slider-controls(30px)
  +below($wide)
    display: none !important
.reviews__previous
  left -40px

.reviews__next
  right: -40px

.reviews__slide
  .shop_SKOU &, .shop_SKOUNO &
    padding 4px

.review__slide__card
  background-color: #fff
  padding: 15px 30px
  box-shadow $reviews_card_shadow
  border-radius: $reviews_card_border-radius
  border: $reviews_card_border
  width 33%
  margin-right: 1.6%
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 244px
  &:last-child
    margin-right: 0
  +below($desktop)
    width: 100%
    padding: 15px 20px
    margin-bottom: 15px
    border: 1px solid $vip_section_background_color
    &:nth-child(1)
      margin-right: 0
  .text-expand__show-label
    background-color $reviews_show_hide_background_color !important
  .text-expand__ellipsis
    background linear-gradient( to right, $reviews_ellipsis_gradient_start 0%, $reviews_ellipsis_gradient_end 60%) !important
  .text-expand__hide-label
    background-color $reviews_show_hide_background_color !important

.review__slide__card-new
  background-color: #fff
  padding: 15px 60px
  box-shadow $reviews_card_shadow
  border-radius: $reviews_card_border-radius
  border: $reviews_card_border
  width 33%
  margin-right: 1.6%
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 244px
  &:last-child
    margin-right: 0
  +below($desktop)
    width: 100%
    padding: 15px 20px
    margin-bottom: 15px
    border: 1px solid $vip_section_background_color
    &:nth-child(1)
      margin-right: 0
  .text-expand__show-label
    background-color $reviews_show_hide_background_color !important
  .text-expand__ellipsis
    background linear-gradient( to right, $reviews_ellipsis_gradient_start 0%, $reviews_ellipsis_gradient_end 60%) !important
  .text-expand__hide-label
    background-color $reviews_show_hide_background_color !important


.review__card__title
  font-family: $font_family_base
  font-weight bold
  margin: 0 0 15px 0
  width: 100%
  overflow: hidden;
  text-overflow: ellipsis;

.review__card__description
  white-space: normal;
  flex-grow: 3
  margin: 10px 0 30px

.review__card__footer
  display: flex;
  justify-content: space-between;
  color: #7b7b7b

.review__card__footer__name
  max-width: 65%
  overflow: hidden;
  text-overflow: ellipsis;

.rating-stars
  box(108px, 18px)
  svg
    box(18px)

.rating-stars__back, .rating-stars__front__inner
  box(108px, 18px)

.reviews__container-no-carousel
  display: flex
