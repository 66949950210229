.vip__insurance-wrapper {
    display: flex;
    padding 0px 16px 12px 16px
}
    

.insurance-label-content{
    display flex
    width: 100%
}

.insurance__checkbox {
    width: 20px;
    height: 20px;
    align-self: center;
    accent-color: $brand_color;
}

.insurance__price {
    margin-left: auto;
    font-weight: 700;
}

.insurance__period {
    padding-left: 12px;
}

.vip__insurance-icon {
    width: 48px;
    height: 20px;
}

.vip__insurance-icon-hansa {
    width: 105px;
    height: 20px;
}

.insurance-provider {
    display: flex;
    gap: 12px;
    padding: 5px 16px 1px 16px;
}

.vip__insurance-open {
    cursor: pointer;
}

.insurance-headline {
    font-weight: bold;
}

.santander-pdp {
    height: 170px;
}