.slider__default
  position: relative;
  margin-bottom: 20px

.slider__default__inner
  margin 0 auto
  height 250px
  +below($wide)
    height calc((100vw - 30px * 2) * 0.22)
  +below($desktop)
    height calc((100vw - 20px * 2) * 0.32)
  +below($tablet)
    height calc((100vw - 20px * 2) * 0.31)
  +below($mobile)
    height calc((100vw - 10px * 2) * 0.75)
    max-height initial

.slider__default__slide
  opacity: 0
  position: absolute;
  top 0
  box(100%)
  display: flex;
  transition: all 1500ms
  pointer-events: none;
  &.show-slide
    opacity: 1
    pointer-events: all;


.slider__default__slide__inner
  margin: auto;
  width: 100%
  max-width 100%
  img
    width 100%

.slider_dots_wrap
  position absolute
  bottom 15px
  left 0
  width 100%
  display flex
  justify-content center
  align-items center
  +below($desktop)
    bottom 10px
  +below($tablet)
    bottom 5px

.slides_single-dot
  box(12px)
  border-radius $fp-slider-dots-radius
  margin-right 6px
  margin-left 6px
  background-color #fff
  cursor pointer
  transition all 300ms cubic-bezier(0.445, 0.05, 0.55, 0.95)
  &.active-dot
    transform scale(1.25)
    background-color $brand_color
  +below($tablet)
    box(8px)
