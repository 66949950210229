$hover_color = rgba(0, 0, 0, 0.04)


.search__suggest
  background-color: #fff
  border-radius: 2px
  box-shadow: 3px 5px 25px rgba(0,0,0,.4)
  padding: 15px 18px 30px
  position: absolute;
  top 48px
  width: 830px
  transition: transform 400ms $easeInOutCubic, filter 100ms, opacity 300ms ease
  opacity: 0
  transform: translate3d(-207px, -220px, 0) scale(.5) ;
  pointer-events: none;
  z-index: $search_suggest_Z
  font-size: 0.875rem
  .shop_TRETSE &
  +below($wide)
    width 720px
  +below($desktop)
    top "calc((%s + %s - 2px) / 2)" % ($header_height_mobile $header_search_height_mobile)
    right: "calc(-%s)" % $header_height_mobile;
    width: 100vw
    transform: translate3d(0, 0, 0)
  &.show
    opacity: 1
    transform:translate3d(0, 0, 0) scale(1);
    pointer-events: all;
    .executing &
      filter blur(2px)
      opacity: .9

.search__suggest-zero
  display: block;
  margin: 10px 0;

.search__suggest__amount
  font-weight: bold;
  padding: 0 4px

.search__suggest__resultgroups-outer
  lost-flex-container row

.search__suggest__resultgroups
  lost-column 1/2 2 50px
  +below($wide)
    lost-column 1/2 2 30px
  +below($desktop)
    lost-column 1/1 1

.resultgroup
  position: relative;
  margin-top: 36px
  &:first-child
    margin-top: 10px
  h3
    margin: 0
  &.hide-hidden-pills
    .hidden-pill
      display none

.resultgroup__header
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25)
  padding-bottom: 7px

.resultgroup__heading
  text-transform: uppercase;
  font-weight: bold;
  font-size .8rem
  color $font_color_base
  +below($phablet)
    font-size: 12px

.resultgroup__see-all
  color: rgba(0, 0, 0, 0.4);
  white-space: nowrap;
  &:hover
    color: rgba(0, 0, 0, 0.6)


.resultgroup__items
  zero-list()

.resultgroup__item
  border-bottom: 1px solid rgba(0, 0, 0, 0.1)

  img
    transform: scale(.7);
    transition: transform 200ms ease-in
    margin: auto;
    max-height: 100%

  a
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 0
    text-decoration: none;
    &:hover, &:focus
      img
        transform: scale(1);
      background-color: rgba(0, 0, 0, 0.02);

.resultgroup__item__thumb-container
  box(45px)
  display: flex;

.resultgroup__item__titel
  color $black
  width 260px
  margin-left: 10px
  margin-top: 7px

.search-suggest__match-string
  color $brand_color
  font-weight: bold;

.resultgroup__item__orig-price
  color: rgba(0, 0, 0, .5);
  text-decoration: line-through;
  margin-top: 7px

.resultgroup__item__price
  color: $price_color
  margin-left: 10px
  margin-top: 7px
  .shop_SKOU.b2b &,
  .shop_SKOUNO.b2b &
    display: none;

.resultgroup__pill
  display: inline-block;
  margin-right: 4px
  margin-bottom: 6px
  padding: 4px 8px
  border solid 1px rgba(0, 0, 0, 0.1);
  border-radius: 2px
  &:first-child
    margin-top 11px
  &:hover
    background-color: $hover_color
  a
    color $black
    text-decoration: none;

.resultgroup__pill-toggle
  cursor pointer
  color: $anchor_text_color
  &:hover
    text-decoration underline

.search__suggest__footer
  display: flex;
  width: calc(50% - 25px)
  justify-content: flex-end;
  +below($desktop)
    width: 100%
    justify-content: center;

.search__suggest__see-all
  -webkit-appearance: none
  border: solid 1px rgba(0,0,0,.3)
  border-radius: 2px
  display: inline-block;
  text-decoration: none;
  margin-top: 30px
  border-color: transparent
  background-color: rgba(95, 95, 95, 1)
  color #fff
  font-size: .9rem
  padding: 6px 20px
  &:hover, &:active, &:focus
    background-color: darken(rgba(95, 95, 95, 1), 10%)
    color: #fff
    text-decoration: none;
