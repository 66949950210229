$srp_gallery_spacing = 8px

.srp-product-wrap
  position relative
  flex-grow: 2
  width: "calc(100% - 270px - %s)" % $srp_filter_wrap_margin
  +below($wide)
    width: calc(100% - 270px - 30px)
  +below($desktop)
    width: 100%

.srp-product-wrap__product-list
  &.grid
    lost-flex-container row
    display flex
    flex-wrap wrap
    width "calc(100% + (2 * %s))" % $srp_gallery_spacing
    margin-left - $srp_gallery_spacing

.srp-product-box-outer
  &.list
    margin-bottom 12px
    width 100%
  &.grid
    lost-column 1/3 3 0px
    margin 0 0 15px !important

.srp-product-box
  position relative
  background-color #fff
  border: 1px solid $srp_product_box_border_color;
  border-radius: $srp_product_box_border_radius;
  box-shadow: $srp_product_box_box_shadow;
  display: flex;
  flex-direction: column;
  &.grid
    margin 0px $srp_gallery_spacing 0px
    height 100%
  .brand_tretti &
    background-color: $srp_product_box_inset_background_color

.srp-product-box__offline
  position: absolute;
  display: inline-block;
  text-transform: uppercase;
  font-size .6rem
  background-color: $danger_color
  color #fff
  padding: 0 4px
  border-top-left-radius: 5px

.srp-product-box__content-wrapper
  display: flex
  .grid &
    flex-direction column

// Image section
.srp-product-box__image-section
  display flex
  flex-direction column
  align-items center
  padding: $srp_product_box_image_padding
  &.list
    min-width 240px
    border-right $srp_product_box_borders
    +below($wide)
      min-width: 220px
    +below($tablet)
      min-width: 150px
    +below($mobile)
      border-bottom $srp_product_box_borders
  &.grid
    min-height: 100px
  .brand_tretti &
    background-color #fff

.image-section__image
  max-height 160px
  max-width 180px
  margin-bottom 10px
  +below($tablet)
    max-width: 120px;

.image-section__rating
  margin-bottom 5px

.image-section__options
  display: flex;
  align-items: stretch;
  flex-grow: 2
  justify-content: center;
  font-size $srp_product_box_options_compare_font_size
  color $srp_faded_dark_color
  border-top: $srp_product_box_borders
  width: 100%
  min-height: 52px
  +below($tablet)
    align-items: flex-start;
  .grid &
    .brand_skousen &
      border-top 1px solid #e8e8e8
      border-bottom 1px solid #e8e8e8

.image-section__favorite,
.image-section__compare
  display flex
  align-items center
  width: 50%
  cursor pointer
  padding $srp_product_box_options_padding
  position: relative;
  .grid &
    .brand_skousen &
      padding 15px 0

.image-section__favorite
  justify-content: $srp_product_box_options_favorites_position;
  +below($tablet)
    margin: 0
    border: none
    width: 100%
  .image-section__favorite__icon-wrap
    box(18px)
    margin-right 5px
  svg
    box(18px)
    stroke $srp_fav_fill_color
    fill none
    stroke-width: 3px;
    transition: .25s all ease-out
  &.saved
    .image-section__favorite__icon-wrap
      animation 0.35s pulse ease
      svg
        stroke $srp_fav_and_compare_active
        fill $srp_fav_and_compare_active

.image-section__compare
  justify-content: $srp_product_box_options_compare_position;
  border-left: $srp_product_box_borders
  +below($tablet)
    display: none
  .wag-popover
    top: 42px;
    left: -8px;
    .brand_tretti &
      left: 23px;
  .checkbox
    box(18px)
    position relative
    overflow: hidden;
    border-radius 3px
    margin-right 5px
    background-color #fff
    border: $srp_compare_border
    border-color $srp_fav_and_compare_inactive
    transition: .25s all ease-out
    svg
      box(13px)
      position absolute
      top -30px
      left 2px
      stroke-width 4px
      stroke #fff
      transition: .25s all ease-out
  &.checked
    .checkbox
      background-color $srp_fav_and_compare_active
      border-color $srp_fav_and_compare_active
      svg
        transition: .2s all $easeOutBack
        top 1px
  &.disabled
    cursor default
    opacity 0.5

// Spec and Price wrapper
.srp-product-box__spec-price-wrapper
  display: flex;
  justify-content: space-between;
  width: 100%
  padding: $srp_product_box_specs_padding_list
  background-color: $srp_product_box_inset_background_color
  +below($tablet)
    flex-direction: column;
    padding-bottom 0px
  +below($mobile)
    padding $srp_product_box_spec_padding_mobile
  &.grid
    flex-direction: column;
    border-left: none
    border-top: $srp_product_box_borders
    padding: $srp_product_box_specs_padding_grid
    flex-grow: 1

// Spec section
.srp-product-box__spec-section
  .spec-section__link:hover
    text-decoration none
  &.list
    max-width: 300px
  &.grid
    min-height: 100px
    margin-bottom: 15px;

.spec-section__title
  margin 0 0 15px -10px
  padding-left 10px
  +below($tablet)
    margin-bottom: 5px
  .grid &
    margin-bottom 0px

.spec-section__title__brand
  font-size $brand_font_size
  font-weight: $brand_font_weight
  text-transform uppercase
  display block
  line-height: 1
  a
    color $brand_font_color
  .grid &
    font-size: 1.5rem
  +below($desktop)
    font-size: 1.4rem
  +below($tablet)
    font-size: 1.2rem


.spec-section__title__name, .spec-section__category-title
  font-family $font_family_base
  display: inline-block;
  line-height: 1
  font-size 1rem
  font-weight: normal;
  color: $font_color_base
  a
    color: $font_color_base
  +below($tablet)
    font-size: 0.875rem;

.spec-section__category-title
  display block

.spec-section__delivery-info
  font-size 0.875rem
  .green
    color $srp_positive_color
  &.list
    display: none;
    +below($tablet)
      display: block
      margin-bottom 10px
      font-size 0.75rem
  &.grid
    font-size 0.75rem
    margin-bottom 15px

.spec-section__unique-text
  color $srp_usp_text_color
  font-weight: 600
  margin-bottom 10px
  line-height 1.2
  +below($tablet)
    font-size 0.875rem
  +below($mobile)
    font-size 0.75rem

.spec-section__fall-back-text
  margin-bottom 2px
  font-size 0.875rem
  +below($tablet)
    font-size: 0.75rem

.spec-section__topspec-list-exp
  display: none;

.spec-section__energy-label-wrap
  margin-top 20px
  +below($tablet)
    display none

// Price section
.srp-product-box__price-section
  text-align right
  font-size 0.875rem
  &.list
    text-align right
    flex-grow: 2
    +below($tablet)
      padding: 0;
      min-width: auto
      margin-top: 15px

.price-section__energy-and-price-wrap
  display: flex
  margin-bottom: 15px;
  .text-label
    font-size 0.75rem
    color $srp_faded_dark_color
  .list &
    display flex
    justify-content flex-end
  .grid &
    display flex
    align-items flex-end
    justify-content space-between
    &.price-has-text
      align-items flex-start
  +below($tablet)
    .list &
      justify-content space-between
  +below($mobile)
    .list &
      margin-bottom: 10px;
      align-items flex-start


.price-section__energy-label-wrap
  display none
  flex-direction column
  align-items center
  .text-label
    font-size 0.75rem
    color $srp_faded_dark_color
  .grid &
    display flex
  a
    &:hover
      text-decoration none
  +below($tablet)
    display flex
    margin-top -2px

.price-section__price-wrap
  .grid &
    display flex
    flex-direction column
    align-items flex-end
    span
      text-align right
  &.k1-prod
    color blue
  +below($mobile)
    margin-top 4px
  a
    color: $font_color_base
    text-decoration none
    &:hover
      text-decoration none
    &:visited
      text-decoration none

.price-section__price-text
  color $srp_faded_dark_color
  .list &
    margin-right 10px
    +below($tablet)
      display: none
  .grid &
    padding-bottom 4px
    line-height 1

.price-section__price
  font-size 1.75rem
  font-family: $srp_product_box_price_font
  line-height 1
  color: $price_color
  &.stroke
    mixStroke()
  +below($mobile)
    font-size 1.5rem

.price-section__product-banners
  +below($mobile)
    display none

.price-section__delivery-info
  font-size 0.75rem
  margin-top: 15px;
  .in-stock
    color $in_stock_color
  +below($tablet)
    display: none;

.price-section__card-exclusive-delivery-text-right
  top: 28px;
  color: $dark_gray
  font-size: .7rem
  font-style: italic;
  display: flex
  justify-content: flex-end

.srp-product-box__add-to-cart-desktop,
.srp-product-box__add-to-cart-mobile
  margin 20px 0 20px
  display flex
  justify-content flex-end
  .button
    min-width 100px
    padding 10px 20px
    line-height 1
    display inline-flex
    align-items center
  .icon-basket-wrap
    box(20px)
    margin-left 10px
    svg
      fill #fff
      box(100%)
  .icon-check-wrap
    position: absolute;
    top 7px
    left calc(50% - 8px)
    pointer-events: none;
    svg
      fill #fff
      box(22px)
      stroke #fff
      stroke-width 2.1
      stroke-dasharray: 50
      stroke-dashoffset: 50
      transition: stroke-dashoffset 800ms ease-out 200ms, stroke 300ms
  .adding .icon-check-wrap svg,
  .added .icon-check-wrap svg
    stroke-dashoffset: 0

.srp-product-box__add-to-cart-desktop
  +below($mobile)
    display none

.srp-product-box__add-to-cart-mobile
  display none
  +below($mobile)
    display: flex;
    margin 0 15px 15px 0

// Product banners mobile
.srp-product-box__product-banners-mobile
  display none
  +below($mobile)
    width 100%
    display block
    .brand_tretti &
      padding-top 10px
      background-color: $srp_product_box_inset_background_color

// Product list transitions
$product-trans-time = .35s
$product-trans-ease = $easeOutCubic

.srp-product-move
  transition: transform $product-trans-time $product-trans-ease

.srp-product-enter
  opacity 0;
  .srp-product-box
    transform: scale(0.8);

.srp-product-enter-to
  transition: opacity $product-trans-time $product-trans-ease
  .srp-product-box
    transition: transform $product-trans-time $product-trans-ease
    transform: scale(1);

.srp-product-leave
  opacity: 1;
  .srp-product-box
    transform: scale(1);

.srp-product-leave-active
  position absolute
  opacity 0;
  transition: opacity $product-trans-time $product-trans-ease
  .srp-product-box
    transition: transform $product-trans-time $product-trans-ease
    transform: scale(0.8);

.srp-hero-product-wrap
    background: radial-gradient(circle farthest-side at 27% -200%, $brand_color 96%, $brand_color_dim 0%);
    padding: 16px;
    border-radius: 10px;
    margin: 0 0 12px 0;
    +below($mobile)
      padding: 16px 4px 4px 4px;
    .srp-product-box
        margin: 8px 0 0 0;
        padding: 0;
        +below($mobile)
          border-radius: 10px;
    .srp-product-box-outer
        margin: 0;
    .srp-product-box__spec-price-wrapper
      +below($mobile)
          border-radius: 0 10px 0 0;
    .srp-product-box__image-section.list
      +below($mobile)
          border-radius: 10px 0 0 0;
    .srp-product-box-outer.grid
      max-width: 100%;
      width: 100%;
    
.srp-hero-product-header
    color: white;
    margin: 0;
    font-weight: 700;
    +below($mobile)
      padding-left: 4px;

.srp__product__wrap
  display: none;

.srp__hero__product__wrap
  display: block;

.grid .srp-hero-product-wrap
  lost-column 1/3 3 0px;

.srp-product-wrap_load-more
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;
  +below($mobile)
    align-items: stretch;
    margin-top: 24px;

.srp-product-wrap_load-more p
  margin-bottom: 15px;
  align-self: center;

.plp-disable-body-scroll
  overflow: hidden;
