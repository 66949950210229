box(width, height = width)
  width width
  height height

circle(size)
  box(size)
  border-radius: size

truncate()
  white-space nowrap
  overflow hidden
  text-overflow ellipsis

zero-list()
	margin 0
	padding 0
	list-style none

smooth-scroll()
  overflow-y scroll
  -webkit-overflow-scrolling touch

flex-center-children()
  display flex
  justify-content center
  align-items center

flex-center-children-column()
  display flex
  flex-direction column
  align-items center

mixStroke($stroke-color = rgba(80, 80, 80, 0.8))
  position relative
  &:after
    content: ''
    position absolute
    width 97%
    left -2%
    top 50%
    border-bottom 2px solid $stroke-color
    transform skewY(-11deg)

insetBox($padding = 20px)
  box-shadow: inset 0 0 15px rgba(0,0,0,0.3)
  padding: $padding
  margin-bottom: 30px
  background-color #fff
  border solid 1px #ccc

insetBoxItem()
  display: flex
  justify-content: space-between;
  align-items: center;
  border solid 1px #ccc
  border-radius 5px
  width: 100%
  padding: 12px 20px
  margin-bottom: 15px
  background-color: rgba(250,250,250,1)
  white-space: nowrap;
  color #444
  font-weight: bold
  svg
    box(12px)
    fill #999
  &:last-child
    margin-bottom: 0
  &:hover
    background-color: rgba(250,250,250,.2)
    text-decoration: none
  &.inverted
    background: #a2a2a2
    color: #fff;
    &:hover
      background-color: lighten(#a2a2a2, 10%)
    svg
      fill #fff

pageWrapper($width = $page_width)
  width $width
  margin: auto;
  +below($wide)
    width: 100%;
    padding 0px 30px
  +below($desktop)
    padding: 0 20px
  +below($mobile)
    padding: 0 10px

standardSection()
  width 100%
  max-width $page_width
  margin 0 auto
  +below($wide)
    padding 0px 30px
  +below($desktop)
    padding 0px 20px
  +below($mobile)
    padding 0px 10px

standardSectionPadding()
  +below($wide)
    padding 0px 30px
  +below($desktop)
    padding 0px 20px
  +below($mobile)
    padding 0px 10px

shopCard()
  position: relative;
  background-color: #fff
  border $shop_card_border
  box-shadow $shop_card_shadow
  border-radius $shop_card_border-radius
  display: flex;
  flex-direction: column;

gridCard()
  background-color: #fff;
  border: $shop_card_border;
  padding: 14px;
  box-shadow: $shop_card_shadow;
  border-radius $shop_card_border-radius
  color: $font_color_base
  &:hover
    text-decoration: none;

preloader(size = 40px)
  halfSize = "calc(%s / 2)" %size
  content: "";
  circle(size)
  position: absolute;
  box-sizing: border-box;
  left "calc(50% - %s)" % halfSize;
  top "calc(50% - %s)" % halfSize;
  border: 3px solid rgba(0, 0, 0, 0.3)
  border-top: 3px solid $brand_color
  animation: sweep 600ms infinite ease;

brandHeader()
  font-family: $font_family_header
  font-weight: $brand_font_weight
  text-transform uppercase
  color $brand_font_color
  margin: 0
  line-height: 1
  display: inline-block;
