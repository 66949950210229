.srp__product-links-header
  standardSection()
  margin-bottom 14px
  span
    text-transform: lowercase;

.srp__product-links
  display: flex
  flex-wrap: wrap;
  standardSection()
  margin-bottom 40px

.srp__product-link
  display: inline-block;
  border 1px solid #ccc
  background-color: #fff;
  padding: 5px;
  margin-right: 8px;
  margin-bottom: 8px;
  border-radius: 3px;
  font-size 0.875rem
  line-height: 0.875rem;
  transition: transform 200ms ease
  color #555
  &:hover
    text-decoration: none;
    transform: scale(1.04);
