.cof__error-summary
  color #fff
  background-color $cof_validation_error
  border-radius 6px
  font-size .875rem
  width 100%
  box-sizing border-box
  margin-bottom 15px
  a
    color #fff
    text-decoration underline
