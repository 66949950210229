.vip__delivery-clock
  background-color: $brand_color_variation;
  color: #fff;
  padding: 6px 16px;
  display: flex;
  align-items: center;
  margin-top: -5px;

.vip__delivery-clock-icon
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  svg
    box(36px)
    fill: #fff;
  +below($mobile)
    display: none;

.vip__delivery-clock-spacer
  background-color: rgba(255,255,255, .5);
  width: 1px;
  height: 20px;
  margin-right: 20px;
  +below($mobile)
    display: none;

.vip__delivery-clock-text
  display: flex;
  +below($wide)
    font-size: .9rem
  +below($mobile)
    flex-direction: column;
    align-items: center;
    width: 100%;

.vip__delivery-clock-text-first
  display: flex;

.vip__delivery-clock-countdown
  margin: 0 4px;
  .countdown__simple-counter
    display: flex
    padding 2px 0;
  .countdown__simple-flipper
    background-color: $brand_color_variation_2
    display: flex;
    padding: 0 2px;
    margin: 0 1px;
    line-height: 1.2;
  .countdown__simple-flipper-day
    background-color: transparent;
    padding 0
    margin 0
