.breadcrumb-wrapper
  max-width $page_width
  margin: 0 auto
  padding 0
  font-size: .75rem
  line-height: 40px
  +below($wide)
    padding: 0 30px
  +below($desktop)
    padding: 0 20px
    .shop_SKOU &, .shop_SKOUNO &
      margin-top 10px
    .shop_SKOU.active-streamer &, .shop_SKOUNO.active-streamer &
      margin-top -10px
  +below($tablet)
    visibility: hidden;
    pointer-events: none;
    position: absolute;

.breadcrumb-list
  zero-list()

.breadcrumb-list__item
  display: inline-block;
  &:after
    content '›'
    color $breadcrumb_color
    display: inline-block;
    margin: 0 2px
  &:last-child
    &:after
      display: none

.breadcrumb-list__link
  color $breadcrumb_color
  &:hover
    color $breadcrumb_color
