.energy__label
  display: flex
  flex-direction: column

.energy__label-svg
  &.new
    height: 50px
    width: 91px;
  &.old
    width: 110px;
    height: 45px
  .energy__label-medium &
    &.new
      height: 42px
      width: 77px;
    &.old
      height: 37px
      width: 90px;
  .energy__label-small &
    &.new
      height: 36px
      width: 66px;
    &.old
      height: 31px
      width: 75px;

.energy__label-right
  display: flex
  flex-direction: column
  align-items: flex-start

.energy__label-left
  display: flex
  flex-direction: column
  align-items: flex-end

.energy__label-data
  color #888
  font-size 0.75rem
  margin-top: -0.2rem
  text-decoration: underline
  .energy__label-small &
    font-size 0.65rem

.energy__label-download
  text-decoration: none
  &:hover
    text-decoration: none
