// Toggle Button
.toggle-button__wrap
  margin-bottom: 10px
  display: flex
  justify-content: space-between
  align-items: center
  position: relative
  &.disabled
    opacity: 0.5
    pointer-events: none

.toggle-button
  position: relative
  display: flex
  align-items: center
  cursor: pointer

.toggle-button__btn
  position: relative
  display: block
  box(30px, 18px)
  border-radius: 18px
  background-color: #ddd
  margin-right: 10px
  +below($desktop)
    box(38px, 23px)
  &:after
    content: ''
    position: absolute
    cursor: pointer
    circle(16px)
    top: 0
    left: 0
    box-shadow: 1px 0 2px rgba(0, 0, 0, 0.3)
    background-color: $toggle_button_background_color
    border: $toggle_button_border
    transition: all 0.25s ease-out
    +below($desktop)
      circle(21px)
    .toggle-button.checked &
      left: calc(100% - 18px)
      box-shadow: 1px 0 1px rgba(0, 0, 0, 0.2)
      +below($desktop)
        left: calc(100% - 21px)

.toggle-button__btn__bg
  width: 100%
  height: 100%
  border-radius: 20px
  position: relative
  overflow: hidden

.toggle-button__btn__active-bg,
.toggle-button__btn__shadow-bg
  width: 100%
  height: 100%
  border-radius: 30px

.toggle-button__btn__active-bg
  margin-left: -100%
  background-color: $toggle_button_active_color
  transition: all 0.25s ease-out
  .toggle-button.checked &
    margin-left: 0

.toggle-button__btn__shadow-bg
  position: absolute
  top: 0
  box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.3)

.toggle-button__text
  display: inline-block
  width: 165px
  line-height: 1.2rem
  truncate()
  &.has-description
    max-width 160px
    +below($mobile)
      max-width 140px

.toggle-button__internal-label
  color: #aaa
  font-size: .8rem

.toggle-button__abundance
  color #777
  color rgba(0,0,0,0.5)
  font-size .75rem

.boolean-filter__more
  +below($tablet)
    display block
    padding-top 5px
