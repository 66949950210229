.cof-steps__step
  &.active
    background-color: $brand_color

  &.completed
    cursor: pointer
    background-color: $brand_color_dim


.cof-step-wrap:after
  background-color: $brand_color_dim
