.styleguide-outer
    background-color: $styleguide_background
    padding: 50px 0
.styleguide
    lost-center $page_width
    position: relative
    justify-content: flex-end
    padding: 50px 0


.styleguide__padding-box
    padding: 20px 0 15px
    &.small
        padding: 15px 0 0

.styleguide__content-section
    margin-bottom: 40px
