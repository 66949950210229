.blog-hero
  background-size: cover;
  background-position: center;
  height: 350px
  display: flex;
  justify-content: center;
  margin-bottom: 40px
  +below($desktop)
    height 300px
    margin-bottom 25px
  +below($mobile)
    margin-bottom 15px

.blog-hero__logo-wrapper
  width: 800px
  max-width: 90%
  border-radius: 100%
  text-align: center;
  padding-top 40px
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  a
    width: 100%

  svg
    width: 100%

.blog-hero__bi-line
  display: block;
  font-size 1.1rem
  margin-top: -20px
  color rgb(10, 10, 10)


.blog-post-hero
  height: 700px
  background-position: center
  background-size: cover;
  position: relative;
  margin-bottom: 40px
  &:after
    content: ''
    display: block;
    position: absolute;
    bottom: 0
    width: 100%
    height: 350px
    background: linear-gradient(to top, rgba(0,0,0,.6), rgba(0,0,0,0))
  +below($desktop)
    height 400px
    margin-bottom: 25px
    &:after
      height 250px
  +below($tablet)
    height 350px
  +below($mobile)
    height 350px

.blog-post-hero__inner
  max-width: $page_width
  margin: auto;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  height: 100%
  z-index: 1
  pointer-events: none;
  position: relative;
  a:first-child
    pointer-events: all;
    margin-left: 40px
    +below($desktop)
      margin-left: 25px
    +below($mobile)
      margin-left: 10px
  +below($page_width)
    padding: 0 15px

.blog-post-hero__category
  category-pill()
  color: #fff;

.blog-post-hero__titel
  color: #fff
  margin 15px 40px 320px
  font-size 3.9rem
  line-height 1
  max-width: 800px
  text-shadow: 0 0 16px rgba(0,0,0,.35)
  pointer-events: all;
  text-transform: none;
  +below($desktop)
    font-size 3rem
    margin 15px 25px 70px
    max-width: 750px
  +below($mobile)
    font-size 2.4rem
    margin 15px 10px 70px
