.search
  border $header_search_input_border
  min-height: $header_search_height
  height: $header_search_height
  border-radius: $header_search_input_border_radius
  width $header_search_width
  position: relative;
  background-color: #fff
  margin-left: $header_search_margin_left
  margin-right: $header_search_margin_right
  +between($desktop + 1, $wide)
    max-width 360px
    margin-left 15px
    margin-right 10px
    .shop_SKOU &, .shop_SKOUNO &
      margin-left 0px
      margin-right 0px
  +below($desktop)
    height: $header_search_height_mobile
    border-width: 1px
    border-radius: 3px
    border-color: transparent
    background-color: transparent
    margin: 0
    transition: width 300ms ease
    .search-active &
      width: 100%;

.search__input
  border none
  height: 100%
  border-radius: $header_search_input_border_radius
  padding-left: 16px
  width: 80%
  transition: all 300ms ease

  &::-ms-clear
    display: none

  +below($desktop)
    opacity: 0
    width: 100%
    font-size: 16px
    pointer-events: none;
    .search-active &
      opacity: 1
      pointer-events: all;

.search__spinner
  circle(24px)
  position: absolute;
  right: 86px
  top 10px
  border: 3px solid rgba(0, 0, 0, .1)
  border-top: 3px solid rgba(0, 0, 0, .4)
  animation: sweep 600ms infinite ease;
  display: none;
  .executing &
    display: block;
  +below($desktop)
    box(20px)
    right 40px
    top 6px

.search__submit
  display: flex
  background-color: $header_search_submit_background_color
  height calc(100% - 8px)
  width 16%
  color $header_search_submit_text_color
  border-radius: $header_search_submit_border_radius
  float: right
  margin: 4px
  align-items: center
  svg
    fill: #fff
    margin: auto;
    box(20px)
  span
    margin auto
  &:hover, &:active, &:focus
    color $header_search_submit_text_color
    text-decoration: none;
  +below($desktop)
    display: none;

.search__submit__icon
  display: none;
  border none;
  background-color: transparent
  padding: 0
  box(20px, 100%)
  position: absolute;
  right 4px
  top 0
  transition: all 300ms
  svg
    box(100%)
    transition: all 300ms
    fill $header_search_icon_color
    .search-active &
      box(0, 100%)
  +below($desktop)
    display: block;

.search__close
  box(0, 1px)
  position: absolute;
  display: block;
  background-color: $neutral_dark_color
  transition: width 150ms, transform 300ms
  top 50%
  .search-active &
    box(17px, 1px)
    transform: rotate(45deg)
    top "calc(50% - 9px)"
  &:before
    content: ''
    display: block;
    position: absolute;
    box(0, 1px)
    background-color: $neutral_dark_color
    transition: width 150ms, transform 300ms
    .search-active &
      box(17px, 1px)
      transform: rotate(-90deg)
