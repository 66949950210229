.cof
  padding: 1px 0 40px
  background-color: $cof_bg
  min-height 500px

.cof__outer-section
  lost-center $page_width
  margin-top: 36px
  margin-bottom: 20px
  box-shadow: $cof_section_shadow
  +below($page_width)
    margin-left 15px
    margin-right 15px
  +below($mobile)
    margin: 20px 7px 20px 7px
    box-shadow: $cof_section_shadow_mobile

.cof__inner-section
  padding: 0 50px
  border-left $cof_section_border
  border-right $cof_section_border
  width: 100%
  +below($page_width)
    padding: 0 30px
  +below($mobile)
    padding: 0 8px

.checkbox__label p
  margin: 0


.text-input__validation-message
  color $danger_color
