.brand__container
  lost-center: $page_width;
  margin-top: 20px;
  +below($wide)
    padding 0 10px;

.brand__left-col
  width: 28%;
  margin-right: 2%;
  +below($desktop)
    display: none;

.brand__right-col
  width: 70%;
  +below($desktop)
    width: 100%
