
.header-main
  position: relative;
  background-color: $header_background_color

.header-main__inner
  max-width: $page_width
  margin: 0 auto
  height $header_inner_height
  display: flex;
  justify-content: space-between;
  align-items: center;
  +below($wide)
    padding: 0 20px
  +below($desktop)
    position: fixed
    left: 0;
    z-index: $mobile_menu_Z;
    width: 100%;
    height: $header_height_mobile
    padding: 0 $header_height_mobile 0 10px
    background-color: $brand_color
    box-shadow: $shadow-md;

.header-main__logo
  width $header_logo_width
  height $header_logo_height
  margin-bottom $header_logo_margin
  transition: opacity 100ms, width 300ms ease
  svg
    max-width 100%
    height 100%
    fill: $header_logo_color_desktop
    +below($desktop)
      fill: $header_logo_color_touch
  +below($desktop)
    height $header_logo_height_touch
    width: $header_logo_width_touch
    margin: 0
    .search-active &
      width 0
      opacity: 0

.header-main__logo-desktop
  +below($desktop)
    display: none;

.header-main__logo-mobile
  +above($desktop + 1)
    display: none;

.header-main__placeholder
  height: $header_height_mobile;
  +above($desktop)
    display:none;

.header-main__widgets
  display: flex;
  justify-content: space-between;
  .blog__page &
    display: none !important;
  +below($desktop)
    position: absolute;
    bottom: 3px;
    right: 92px;
