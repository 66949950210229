.vip__last-seen-products__outer
  sectionOuter($vip_section_background_color)
  +below($tablet)
    display none;

.vip__last-seen-products
  sectionInner($vip_inner_section_border_color)

.vip__last-seen-products__container
  lost-flex-container row
  .product__card
    lost-column 1/4 4 14px
    +below($desktop)
      lost-column 1/3 3 14px
    +below($tablet)
      lost-column 1/2 2 14px
    &.card-3
      +below($desktop)
        display none;

.tracker__last-seen
  +below($tablet)
    display none;
