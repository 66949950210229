.brand__categories-nav
  zero-list()
  box-shadow: 1px 3px 8px rgba(0,0,0,0.2);

.brand__categories-lvl1-trigger
  cursor: pointer;
  position relative
  color: #333;
  background-color: #fff;
  display: flex;
  height 40px;
  align-items: center;
  justify-content: space-between;
  padding: 0 14px 0 12px;
  border-bottom: 1px solid #e9e1e1;
  transition: background-color 0.6s;
  &::after
    content "";
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid #666;
    transition: transform 0.4s;
  &:hover
    text-decoration: none;
    background-color: #f8f8f8;
  .show-sub-level &
    background-color: #f2f2f2
    &::after
      transform: rotate(90deg);

.brand__categories-lvl2
  max-height: 0;
  overflow: hidden;
  transition: all 0.5s ease;
  zero-list()
  .show-sub-level &
    max-height: 200px;
    border-bottom: 1px solid #e9e1e1;
    padding: 4px 0;
  a
    color: #333;
    background-color: #fff;
    font-size: .9rem;
    display: block;
    padding: 4px 12px;
    &:hover
      background-color: #f8f8f8;
      text-decoration: none;
