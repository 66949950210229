.cof__cta-section
  margin-top: 20px
  justify-content: flex-end;
  box-shadow: none
  +below($mobile)
    box-shadow: none
    justify-content: center;

.cof__cta-inner
  display: flex
  flex-flow: column nowrap;
  align-items: center;
  +below($mobile)
    width 100%


.cof__cta-button,
.cof__neutral-button
  font-family $font_family_header
  font-size: 1.4rem
  border-radius: $btn_border_radius_lg;
  line-height: 53px
  font-weight 100
  transition: opacity 200ms ease-out
  text-transform: $cof_section_heading_transform
  &:disabled
    opacity 0.5

.cof__cta-button
  width 100%
  box-sizing border-box
  padding 0 100px

.cof__neutral-button
  padding: 0 80px;
  margin-left: 10px;
  +below($tablet)
    padding: 0 40px;
    font-size: 1.2rem
  +below($mobile)
    padding: 0 20px;
    font-size: 1rem
