.tretti-bf__countdown
  background-color: #000
  color: #fff
  background-size: cover;
  margin-top: -30px
  +below($wide)
    margin-top: 0

  &-label
    font-size: 2rem
    color: #fff
    font-family: $font_family_header
    text-transform: uppercase
    text-align: center;
    +below($tablet)
      font-size: 1.6rem

  &-content
    pageWrapper()
    +below($desktop)
      padding 0

  &-video
    display:flex;
    +below($tablet)
      flex-direction: column-reverse;

  &-video-cover
    width: 50%
    margin-right: 4%
    padding: 16px 0
    position: relative;
    +below($tablet)
      width: 100%
      padding: 20px

  &-video-text
    width: 37%
    +below($desktop)
      width: 46%
    +below($tablet)
      width: 100%
      padding: 50px

  &-video-text-sub
    font-size: 1.9rem
    text-transform: uppercase;
    color: $brand_color
    font-weight: 700

  &-video-text-header
    font-size: 3.3rem
    text-transform: uppercase;
    font-weight: 700
    line-height: 1
    p
      margin: 0

  &-video-text-body
    font-size: 1.2rem

  &-seo-text
    margin-top: 120px
    padding-bottom: 40px
    h1, h2, h3
      color: #fff

.countdown-end-text
  text-align: center;
  font-size: 2rem
  font-family: $font_family_header
  color: $brand_color
