// Lost grid global settings
@lost flexbox flex
@lost gutter 20px


// EASINGS
$easeOutBack = cubic-bezier(0.175, 0.885, 0.320, 1.275)
$easeInBack = cubic-bezier(0.600, -0.280, 0.735, 0.045)
$easeInOutCubic = cubic-bezier(0.645, 0.045, 0.355, 1.000)
$easeOutCubic = cubic-bezier(0.215,  0.610, 0.355, 1.000)

// Z - INDEXES
$body_Z = -10
$off_canvas_Z = -8
$scroll_to_top_Z = 10
$gallery_controls_Z = 100
$streamer_Z = 900
$last_seen_Z = 910
$compare_products = 920
$mega_menu_Z = 950
$mobile_menu_Z = 960
$search_suggest_Z = 1000
$favorites_overlay = 1001
$gallery_overlay = 2000
$filter-mobile-panel = 2000
$call_in_box_Z = 2005
$accessories_overlay = 2010
$b2b_nav_Z = 2030
$vip_off_canvas_Z  = 2040
$cof_progress_Z = 3030
$wag_modal_Z = 4000


// Colors
$OS_success_color = #2bc26a;
$OS_warning_color = #f1b20e;
$OS_danger_color = #E64E43;
$youtube_color = #dc4539;

// b2b
$b2b_k0_color = #2dcc70;
$b2b_k1_color = #156EE0;
$b2b_k2_color = #2dcc70;
$b2b_extra_info_color = #d28602;


// Shadows
$shadow-xs	=  0 0 0 1px rgba(0, 0, 0, 0.05);
$shadow-sm	= 0 1px 2px 0 rgba(0, 0, 0, 0.05);
$shadow	=  0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
$shadow-md	= 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
$shadow-lg	=  0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
$shadow-xl	= 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
$shadow-2xl	=  0 25px 50px -12px rgba(0, 0, 0, 0.25);
$shadow-inner	=  inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);