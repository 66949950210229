.blog-pagination__container
  width 100%
  margin-bottom: 20px

.blog-pagination__desktop
  display flex
  justify-content flex-start
  +below($tablet)
    display none

.blog-pagination__mobile
  display flex
  justify-content center
  +above($tablet + 1)
    display none

.blog-pagination__page-button
  padding 10px 0px
  margin 0
  margin-right 1%
  text-align center
  background-color rgba(255,255,255, .9)
  color $brand_color
  width 10.2%
  box-shadow: 1px 1px 3px rgba(0,0,0,.2)
  &:last-child
    margin-right 0
  &.small-button
    +above($tablet + 1)
      width 6.76%
  &.big-button
    +above($tablet + 1)
      width 14.52%
  &.active
    background-color $brand_color
    color #fff
  &.disabled
    color #aaa
    &:hover
      background-color #ececec
      color #aaa
  &.dots
    background-color transparent
    color #aaa
    letter-spacing 2px
    box-shadow: none
    &:hover
      background-color transparent
      color #aaa
  &.svg-arrow
    display flex
    align-items center
    justify-content center
    svg
      box(16px)
      fill $brand_color
      transform translateX(2px)
    .previous-arrow
      transform translateX(-1px)
    .disabled
      fill #aaa
    &:hover
      svg
        fill #fff
  &:hover
    text-decoration none
    background-color $brand_color
    color #fff
