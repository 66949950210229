.blackfriday
  //Badges for Black Friday
  .image-section__badge__image
    color #fff !important
    svg
      fill: #000 !important
    span
      color #fff !important
  .badge-discount
    svg
      fill: #000 !important
  .badge-discount-text
    color #fff !important

  //Site streamer
  .usp__site-streamer
    background: #000 !important
  .usp__site-streamer__text,
  .usp__site-streamer__details,
  .site-streamer__details__close
    color: #fff !important
    a
      color: #fff !important

