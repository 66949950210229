.vip__stock-delivery-info-wrapper
    display: none;

.vip__stock-delivery-info
    display: none;
    color: #FFF;
    background-color: $brand_color;
    padding: 5px 14px;
    font-size: 1rem;
    margin-top: -5px;
    align-items: center;
    +below($mobile)
        display: flex;

.vip__stock-delivery-info svg
    width: 28px;
    height: 28px;
    fill: #FFF;
    margin-right: 15px;
    min-width: 30px;

.brand_skousen
    .vip__stock-delivery-info
        background-color: $brand_color_variation;