.alert__wrapper
  display: flex;
  align-items: center;
  padding: 12px 19px;
  border-radius: 4px;
  transition: all 2s ease-in-out;

//Alert types
.alert__message-error
  background: $danger_color
  color: #fff;

.alert__message-warning
  background: $warning_color
  color: rgba(0,0,0,.7);

.alert__message-success
  background: $success_color
  color: #fff;
