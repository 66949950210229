.cof__section-header
  background-color $cof_section_header_bg
  align-items: center;
  width: 100%
  display: flex
  justify-content $cof_section_header_justify
  flex-wrap: wrap;
  .brand_whiteaway &
    box-shadow: inset 1px -1px 0 0px rgb(232,232,232)
  +below($mobile)
    border none

.cof__section-heading
  background-color: $cof_section_heading_bg
  color $cof_section_header_color
  font-family $cof_section_header_font
  text-transform $cof_section_heading_transform
  font-size $cof_section_header_font-size
  font-weight 300
  padding: $cof_section_heading_paddding
  position: relative;
  &:after
    content ''
    display: block;
    position: absolute;
    box(0)
    border-left: solid 18px $cof_section_heading_bg
    border-top: solid 23px transparent
    border-bottom: solid 23px transparent
    top 0
    right -18px
    .brand_skousen &
      display: none;
  +below($tablet)
    font-size 1.4rem
    padding: 9px 30px
    width: 100%
    &:after
      display: none
  +below($mobile)
    padding: 9px 12px


.cof__section-header__sub-wrapper
  align-self: stretch
  display: flex
  align-items: center
  border-left: $cof_section_subheader_border

.cof__section-subheading
  font-size .875rem
  color: $cof_section_subheading_color
  margin-left: 40px
  margin-right 46px
  font-style $cof_section_subheading_style
  +below($tablet)
    display none
