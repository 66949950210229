.image-section__badge
  position absolute
  .brand_whiteaway &,
  .brand_lph &,
  .brand_enemo &,
  .brand_bilka &,
  .brand_foetex &
    top 3px
    left 3px

.image-section__badge__image
  position relative
  box(70px)
  .shop_SE &
    color #fff
  svg
    box(100%)
    .offer-badge &
      fill:$offer_background_color
    .shop_SE &
      fill:$offer_background_color_se
  span
    position absolute
    width 100%
    font-family $font_family_header
    text-align center
    color: $offer_text_color
  +below($tablet)
    box(60px)
  +below($mobile)
    box(50px)

.brand_whiteaway
  .image-section__badge__image
    font-size 1.2rem
    span
      top 21px
      left 0
      padding-left 2px
    +below($tablet)
      font-size 1rem
      span
        top 20px
        left 0
    +below($mobile)
      font-size 0.75rem
      span
        font-weight bold
        top 18px
        left 0
  &.shop_SE
    .image-section__badge__image
      span
        color: $offer_text_color_se

.brand_skousen,
.brand_lph,
.brand_enemo,
.brand_bilka,
.brand_foetex
  .image-section__badge__image
    font-size 1.4rem
    span
      padding-left 4px
      top 20px
      left 0
    +below($tablet)
      font-size 1.4rem
    +below($mobile)
      font-size 1.2rem
      span
        top 13px

.brand_enemo
  .image-section__badge__image
    span
      color #fff
      top 19px
    +below($tablet)
      span
        top 16px
    +below($mobile)
      span
        top 12px



.brand_skousen
  .image-section__badge
    top -19px
    left -5px
    +below($mobile)
      top -17px
    .image-section__badge__image
      box(90px)
      +below($tablet)
        box(80px)
      +below($mobile)
        box(70px)
    &.offer-badge
      top -5px
      left -5px
      .image-section__badge__image
        box(70px)
        +below($tablet)
          box(60px)
        +below($mobile)
          box(50px)

.brand_tretti
  .image-section__badge
    top -6px
    left -6px
    &.offer-badge
      top -25px
      left -10px
      transform: rotateZ(-15deg);
      .image-section__badge__image
        box(100px)
        color #fff
        font-size 2.2rem
        span
          top 25px
          left 0
        +below($tablet)
          box(80px)
          font-size 1.8rem
          span
            top 19px
        +below($mobile)
          box(70px)
          font-size 1.5rem
          span
            top 19px
      +below($tablet)
        top -20px
        left -8px
      +below($mobile)
        top -20px
        left -6px
