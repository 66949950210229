.fp_carousel-main-wrapper
  .brand_tretti &
    background-color #f1f1f1
    padding: 5px 0
  .brand_bilka &
    background-color: $gray_background
    padding: 5px 0

.fp__carousel-row
  width: 100%;
  margin-bottom: 40px
  +below($wide)
    padding 0 20px
  +below($mobile)
    padding 0 10px

.fp__carousel-wrapper
  max-width: $page_width
  margin: 0 auto 80px auto;

.fp_carousel-header
  font-family: $font_family_header
  font-weight: $font_family_header_weight;
  text-transform: $fp-carousel-header-text-transform;
  font-style: $font_family_header_style;
  margin: 50px 0 20px 0;
  font-size: $fp-carousel-header-size
  text-align: $fp-carousel-header-text-align
  line-height: 1;
  +below($wide)
    padding 0 20px
    .brand_tretti &
      font-size 1.55rem
  +below($mobile)
    padding 0 10px
