.career__infographic-wrapper
  lost-flex-container row

.career__infographic-column
  lost-column 1/3 0 0
  +below($desktop)
    lost-column 1/2
  +below($mobile)
    lost-column 1

.career__infographic-column-box
  background-color: #ECECEC;
  padding: 20px;
  margin-bottom: 20px;
  h3
    margin-top: 0
    margin-bottom: 15px
    font-size: 1.2rem;

.career__infographic-usp
  display: flex;
  margin-bottom: 15px
  &:last-child
    margin-bottom: 0
  svg
    box(60px)
    margin-right: 20px

.career__infographic-text
  display: flex;
  flex-direction: column;
  justify-content: center;
  h4
    font-size: 2.1rem;
    margin-top: 0
    margin-bottom: 0
    line-height: 1
  p
    line-height: 1
    margin: 0

.career__infographic-person-wrapper
  +below($desktop)
    display: none

.career__infographic-person
  position: relative;
  line
    stroke-dasharray: 9;
    stroke-dashoffset: 9;
    animation: dash 16s linear infinite;

.career__infographic-person-heart
  position: absolute;
  right: 150px;
  top: 283px;
  svg
    box(30px)
    fill: #C90000
    animation: pulse 2s infinite;
