.vip__product__outdated
  +below($tablet)
    margin-top 20px
  +below($mobile)
    padding 0 10px
  .product__outdated__intro
    position relative
    padding 2px 0px 20px
    +below($tablet)
      padding-bottom 5px
    .outdated__intro__line__1,
    .outdated__intro__line__2
      display block
      color $brand_color
    .outdated__intro__line__1
      font-weight bold
      font-size 1.25rem
      +below($mobile)
        font-size 1rem

  .alternate__product__wrap
    display flex
    flex-wrap wrap
    width 100%
    border 1px solid rgba(0,0,0,.12)
    padding 5px 15px 16px 5px
    +below($desktop)
      padding-bottom 11px
