.custom-page__wrapper
  .newsletter-signup__wrapper
    .newsletter-input-name
      margin-right: 20px
    .newsletter-signup__validation
      width: 100%
      margin-bottom: 10px
    .validated
      background $success_color
      +below($desktop)
        margin-bottom: 10px
    .error
      background: $danger_color
      color $action_text_color
      +below($desktop)
        margin-bottom: 10px

.custom-page__column-1
  .newsletter-signup__wrapper
    display flex
    flex-direction: column
  .newsletter-form-wraper-inner
    display: flex
    +below($tablet)
      display: block
  .newsletter-signup__button-wrapper
    display block
    width: auto
    +below($tablet)
      flex-direction: row
      display flex
  .newsletter-signup__button
    margin-left: 20px
    width auto
    +below($tablet)
      width: 100%
      margin-left: 0
      flex-direction: row