.customer__center-wrapper
  pageWrapper()

.customer__center-header
  font-size: 2.8rem
  text-align: center
  +below($tablet)
    font-size: 2.3rem
  +below($mobile)
    font-size: 2rem

.customer__center-grid
  lost-flex-container row

.customer__center-grid__item
  lost-column 1/4 4 20px
  +below($tablet)
    lost-column 1/3 3 20px
  +below($mobile)
    lost-column 1/2 2 20px
  gridCard()
  display: flex
  flex-direction: column
  text-align: center
  img
    max-width: 100%
    align-self: center
  span
    font-size: .875rem
