.blog-author-flat-box
  margin-bottom 30px
  display flex
  color: $body_text_color;
  margin-bottom: 30px
  border-radius: 2px
  padding: 30px
  &.dont-link-to-author
    cursor default
    pointer-events none
  &:hover
    text-decoration none
  +below($tablet)
    padding: 20px
  +below($mobile)
    flex-direction column
    align-items center
    padding: 15px 15px 5px

.blog-author-flat-box__image
  circle(110px !important)
  background-color #aaa
  margin-right 30px
  border 5px solid #fff
  +below($tablet)
    margin-right 20px
  +below($mobile)
    margin-right 0px

.blog-author-flat-box__name
  padding 10px 0 0
  margin 0 0 10px
  +below($mobile)
    text-align center

.blog-author-flat-box__bio
  font-size 0.875rem
  color #777
  padding 0
  margin 0 0 10px
  +below($mobile)
    text-align center
