.radio-button__card
  position: relative;
  display: block
  input[type=radio]
    position: absolute
    visibility: hidden

.radio-button__card
  input[type=radio]:checked
    & ~ .radio-button__custom
      &:before
        transform scale(1)
        opacity: 1
