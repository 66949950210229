.dev-top-banner
  width 100%
  font-size .875rem
  text-align center
  padding 4px

  &.branch
    background #fff3cd
    border-bottom 1px solid #ffecb5
    color #664d03

  &.migrating
    background #ffa1a1
    color #724646
