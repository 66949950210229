.vip__finance
  display: flex;
  justify-content: space-between;
  padding: $vip_price_box_bottom_padding
  font-size: .875rem
  margin-top: -3px;
  +below($mobile)
    padding: $vip_price_box_bottom_padding_mobile

.vip__finance__santander
  &.inactive
    display none

.vip__finance__santander-text
  display: flex
  align-items: center;
  flex-wrap: wrap;
  img
    width: 70px
    margin: 0 5px

.vip__finance__santander-overlay-box
  .payment-options
    list-style-type none
    margin 0
    padding 0
    font-size 0.8rem
    li
      padding-top 10px
      white-space nowrap
      &:first-child
        padding-top 0
    select
      margin-left -2px
      font-size 16px
  .payment-info-title
    font-weight bold
    padding-bottom 10px
  .payment-info-field
    min-width: 150px
    display inline-block