.career__profiles
  background: #dbdbdb;

.career__profiles-wrapper
  display: flex;
  flex-wrap: wrap;

.career__profile
  lost-column: 1/3;
  background-color: #fff;
  margin-bottom: 20px;
  img
    width: 100%;
  +below($wide)
    lost-column 1/2
  +below($mobile)
    lost-column 1/1

.career__profile-text
  padding: 0 20px 20px 20px;
  p
    font-size: .875rem
  i
    font-size: .75rem
