.cof__terms
  width 100%
  border $cof_section_border
  border-top: none
  padding: 20px 30px 12px
  background-color $cof_terms_bg_color
  +below($mobile)
    padding: 20px 8px 12px

.cof__terms-inner
  lost-center $cof_center_col_width
  display: block
  .checkbox__label
    display: block
    margin-bottom: 8px
  +below($mobile)
    font-size: .875rem
