.blackfriday__ribbon
  background: #000
  color: #fff
  margin: 0 0 30px 0
  padding: 30px 0

.blackfriday__wrapper
  width: $page_width
  margin: 0 auto;
  +below($wide)
    width: 100%
    padding: 0 15px

.blackfriday__header
  font-family: $font_family_header
  margin: 0 0 15px 0
  text-align: center;
  font-size: 2rem
  text-transform: uppercase;
  line-height: 1
  +below($tablet)
   font-size: 1.7rem
