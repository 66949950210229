.footer__usps
  border-top: solid 1px rgb(232,232,232)
  display: flex
  width: 100%
  align-items: center;
  justify-content: center;
  padding: 40px 0 20px 0
  +below($tablet)
    flex-direction: column

.footer__usps-item
  display: flex
  flex-direction: column;
  width: 250px
  align-items: center;
  margin-bottom: 20px

.footer__usps-icon
  display: flex;
  align-items: center;
  justify-content: center;
  box(40px)
  margin-bottom: 9px
  svg
    box(40px)
    fill: $brand_color_variation

.footer__usps-title
  text-transform: uppercase;
  font-weight: bold
  font-size: .9rem

.footer__usps-sub-title
  font-size: .8rem
