.breadcrumb-outer
  display flex
  justify-content space-between
  align-items center
  max-width $page_width
  margin 0 auto
  +below($wide)
    padding: 0 30px 0 0
  +below($desktop)
    padding: 0 20px 0 0
  +below($tablet)
    display: none;

.breadcrumb-wrapper
  .breadcrumb-outer &
    margin: 0;
