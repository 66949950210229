.countdown
  display: flex;
  flex-direction: column;

.countdown-counter
  display: flex
  justify-content: center;

.countdown-box
  text-align: center;

.countdown-label
  margin: 5px 0

.countdown-flipper
  margin: 0 8px
  padding: 8px
  min-width: 100px
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.7)
  text-shadow: 0 1px 2px #000;
  background-color: #333;
  border-radius: 6px;
  font-size: 4.372rem;
  color #fff
  font-weight: bold;
  text-align: center;
  +below($tablet)
    font-size: 2.188rem;
    min-width: 50px

.flipperAnimate
  animation: pulseToggle 1000ms infinite ease;
