.accordion
  border solid 1px #ccc
  border-radius 5px
  width: 100%
  margin-bottom: 15px
  background-color: rgba(250,250,250,1)
  &:hover
    background-color: rgba(250,250,250,.2)
    text-decoration: none
  &.expanded
    &:hover
      background-color: rgba(250,250,250,1)

.accordion__header
  padding 12px 20px
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold
  color: #444;

.accordion__content
  border-top: 1px solid #dedede
  margin-top: 7px;
  padding 12px 20px

.accordion__trigger
  transition: transform 200ms ease-out
  .expanded &
    transform: rotate(90deg)
  svg
    box(14px)
    fill: #999
    .brand_skousen &
      box(16px)
