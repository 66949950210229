.srp-smart-filter
  position: relative;
  display: flex;
  background-color: $srp_product_box_inset_background_color;
  border: 2px solid $brand_color_variation;
  justify-content: space-between;
  margin-bottom: 12px;

.srp-smart-filter__filter
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  +below($tablet)
    width: 55%;

.srp-smart-filter__step
  padding: $srp_product_box_specs_padding_list;

.srp-smart-filter__counter
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: $srp_product_box_specs_padding_list;
  background-color: $dark_gray;
  color: #fff;
  min-width: 200px;
  +below($tablet)
    min-width: 45%;
    padding: 10px;

.srp-smart-filter__filter-header
  margin-bottom: 12px;
  text-align: center;

.srp-smart-filter__filter-buttons
  display: flex;
  justify-content: center;
  +below($tablet)
    flex-direction: column;
  button
    margin: 0 4px;
    +below($tablet)
      font-size: .775rem;
      margin-bottom: 4px;

.srp-smart-filter__filter-complete
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  h2
    margin: 0;

.srp-smart-filter__counter-header
  font-size: 1.1rem;
  text-align: center;

.srp-smart-filter__counter-number
  font-size: 2.7rem;
  font-weight: bold;
  line-height: 1;

.srp-smart-filter__filter-navigation
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $gray_border;
  width: 100%;
  button
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: none;
    padding: 7px 10px;
    font-size: .8rem;
    svg
      box(10px);
      margin-right: 5px;
      margin-top: 1px;
      fill: $body_text_color;

.srp-smart-filter__filter-navigation-rest
  color: $anchor_text_color;
