.mobile-service-menu
  width: 100%
  padding-bottom 20px
  +above($desktop + 1)
    display: none;
.mobile-service-menu__list
  zero-list()
  display: flex;

.mobile-service-menu__item
  padding: 15px 10px 10px
  border-right: 1px solid rgba(0,0,0,.12)
  flex: 1 100%
  background-color: rgba(0,0,0,.05)
  &:last-child
    border-right: none

.mobile-service-menu__link
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: $black
  font-size: .7rem

  svg
    box(24px)
    fill $brand_color
    margin-bottom: 10px

.mobile-service-menu__contact
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px

a[href^="tel"].mobile-service-menu__contact__phone
  font-size: 1.5rem
  font-weight: bold;

.mobile-service-menu__contact__hours
  color rgba(0,0,0,.6)
