/* Global tooltips style */
[tooltip]
  position: relative;
  margin: 0 4px;
  svg 
    fill $help_icon_background_color
    box($help_icon_size)

/* Applies to all tooltips */
[tooltip]::before,
[tooltip]::after
  text-transform: none;
  font-size: .875rem;
  line-height: 1;
  user-select: none;
  pointer-events: none;
  position: absolute;
  display: none;
  opacity: 0;

[tooltip]::before
  content: '';
  border: 5px solid transparent;
  z-index: 1001;

[tooltip]::after
  content: attr(tooltip);
  text-align: center;
  padding: 10px;
  border-radius: 4px;
  width: 190px;
  box-shadow: 0 1em 2em -.5em rgba(0, 0, 0, 0.35);
  background: #333;
  color: #fff;
  z-index: 1000;

/* Make the tooltips respond to hover */
[tooltip]:hover::before,
[tooltip]:hover::after
  display: block;

/* don't show empty tooltips */
[tooltip='']::before,
[tooltip='']::after
  display: none !important;

/* FLOW: UP */
[tooltip]:not([flow])::before,
[tooltip][flow^="up"]::before
  bottom: 100%;
  border-bottom-width: 0;
  border-top-color: #333;

[tooltip]:not([flow])::after,
[tooltip][flow^="up"]::after
  bottom: calc(100% + 5px);

[tooltip]:not([flow])::before,
[tooltip]:not([flow])::after,
[tooltip][flow^="up"]::before,
[tooltip][flow^="up"]::after
  left: 50%;
  transform: translate(-50%, -.5em);

/* FLOW: DOWN */
[tooltip][flow^="down"]::before
  top: 100%;
  border-top-width: 0;
  border-bottom-color: #333;

[tooltip][flow^="down"]::after
  top: calc(100% + 5px);

[tooltip][flow^="down"]::before,
[tooltip][flow^="down"]::after
  left: 50%;
  transform: translate(-50%, .5em);

/* FLOW: LEFT */
[tooltip][flow^="left"]::before
  top: 50%;
  border-right-width: 0;
  border-left-color: #333;
  left: calc(0em - 5px);
  transform: translate(-.5em, -50%);

[tooltip][flow^="left"]::after
  top: 50%;
  right: calc(100% + 5px);
  transform: translate(-.5em, -50%);

/* FLOW: RIGHT */
[tooltip][flow^="right"]::before
  top: 50%;
  border-left-width: 0;
  border-right-color: #333;
  right: calc(0em - 5px);
  transform: translate(.5em, -50%);

[tooltip][flow^="right"]::after
  top: 50%;
  left: calc(100% + 5px);
  transform: translate(.5em, -50%);

/* KEYFRAMES */
@keyframes tooltips-vert {
  to {
    opacity: .9;
    transform: translate(-50%, 0);
  }
}

@keyframes tooltips-horz {
  to {
    opacity: .9;
    transform: translate(0, -50%);
  }
}

/* FX All The Things */ 
[tooltip]:not([flow]):hover::before,
[tooltip]:not([flow]):hover::after,
[tooltip][flow^="up"]:hover::before,
[tooltip][flow^="up"]:hover::after,
[tooltip][flow^="down"]:hover::before,
[tooltip][flow^="down"]:hover::after
  animation: tooltips-vert 300ms ease-out forwards;

[tooltip][flow^="left"]:hover::before,
[tooltip][flow^="left"]:hover::after,
[tooltip][flow^="right"]:hover::before,
[tooltip][flow^="right"]:hover::after
  animation: tooltips-horz 300ms ease-out forwards;