.opening-hours
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  color: $opening_hours_text_color
  .csa-open
    color $brand_color
  +below($desktop)
    display: none

.opening-hours__channel
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  margin: 0 6px;
  color: $body_text_color
  &:hover
    text-decoration: none;
  a
    color: $body_text_color
    text-decoration: none;

.opening-hours__icon
  box(50px)
  display flex
  svg
    box(30px)
    margin: auto;
    fill: $opening_hours_icon_color

.opening-hours__text
  font-size: 12px
  line-height: 1.2
  margin-left: 8px

.opening-hours__phoneno
  display: block;
  font-weight: 900;
  font-size: $opening_hours_phonenbr_size

.opening-hours__chat-text
  font-weight: 900;
  font-size: $opening_hours_phonenbr_size

.opening-hours__hours
  a
    color: $success_color

.opening-hours__chat-status
  color: $success_color
  cursor: pointer
  &.closed
    color: $danger_color
    cursor default

.opening-hours__overlay-box
  color #333
  +below($desktop)
    display: none;
  ul
    list-style-type none
    margin 0
    padding 0
    font-size 1rem
  li
    white-space nowrap
    padding-bottom 3px
  .opening__hours-day
    display inline-block
    font-weight bold
    min-width: 75px