.srp__featured__products
  background-color: #ececec
  padding: 12px 0 25px 0
  box-shadow: 0 5px 7px 0 #ccc;
  +below($tablet)
    display:none;

  .srp__featured__products-header
    lost-center $page_width
    align-items: flex-end;
    line-height: 1
    margin-bottom: 15px;
    +below($wide)
      padding: 0 15px
    +below($mobile)
      padding: 0 10px
    h2
      margin: 0 10px 0 0
    span
      +below($tablet)
        display: none;

  .srp__featured__products-wrap
    display: flex;
    width: $page_width
    margin: 0 auto;
    +below($wide)
      width: 100%

  .srp__featured__products-carousel
    width: 75%
    +below($wide)
      width: 100%

  .srp__featured__product-highlight
    position: relative;
    display: flex;
    width: 24%
    margin-left: 1%;
    height: 100%
    margin-top: 8px;
    img
      height: 100%;
      width: 100%;
    +below($wide)
      display: none

  .srp__featured__product-grid-image-label
    background: $brand_color;
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    font-size: 1.15rem;
    color: #fff;
    opacity: .85;
    width: 100%;
    text-align: center;
    text-transform: none;

  .product__card-image img
    max-height: 129px
    +below($tablet)
      max-height: 140px;

  .product__card-specs
    display: none;

  .product__card-bottom
    height: auto
    margin-top: 15px

  //Glide specific rules
  .glide__arrow--left,
  .glide__arrow--right
    display: none;

  .glide__bullets
    display: none;
    +below($tablet)
      display: flex;
      bottom: -10px;
