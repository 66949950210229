.stores__map-container
  width: 100%;
  height: 520px;
  .brand_tretti &
    width: $page_width
    margin: 20px auto;
    height: 300px
  +below($mobile)
    height: 300px

.stores__list-wrapper
  pageWrapper();

.stores__list-header
  text-align: center;
  font-size: 2.8rem
  margin-top: 18px
  margin-bottom: 20px

.stores_input-wrapper
  display: flex
  align-items: center;
  justify-content: center;
  border-top: 1px solid $gray_border
  margin-bottom: 30px
  padding-top: 30px
  input
    width: 400px
    +below($mobile)
      width: 100%

.stores__cards
  display: flex
  flex-wrap: wrap;

.stores__card
  shopCard()
  width: 23%
  margin-left: 1%
  margin-right: 1%
  margin-bottom: 25px
  transition: all 300ms ease-in-out
  opacity: 1
  +below($desktop)
    width: calc(88% / 3)
    margin-left: 2%
    margin-right: 2%
  +below($tablet)
    width: 46%
    margin-left: 2%
    margin-right: 2%
  +below($mobile)
    width: 100%
    margin-left: 0
    margin-right: 0
  &.card-hidden
    opacity: 0
    width: 0
    margin-left: 0
    margin-right: 0
    margin-bottom: 0
    border: 0

.stores__card-text
  padding: 14px
  h3
    margin-top: 0
    truncate()
  div
    truncate()

.stores__no-stores-found
  width: 100%
  display: none;
  text-align: center;
