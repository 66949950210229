.footer__main
  background-color: $brand_color
  color: #fff;

.footer__main-content
  max-width: $page_width;
  margin: 0 auto
  padding: 50px 0 30px 0
  +below($wide)
    padding: 50px 30px 30px 30px
  +below($tablet)
    padding: 30px

.footer__main-columns
  display: flex
  justify-content: space-between;
  margin-bottom: 45px
  +below($wide)
    flex-wrap: wrap;
  +below($tablet)
    margin-bottom: 25px
    align-items: center;
    flex-direction: column;

.footer__main-column
  +below($wide)
    width: calc(100% / 3)
  +below($tablet)
    width: 100%
    max-width: 360px

.footer__main-column-header
  color: #fff
  font-size: 1.1rem
  margin-bottom: 17px
  display: flex;
  justify-content: space-between;
  align-items: center;
  +below($tablet)
    cursor: pointer;
    border-bottom: 1px solid #f662a6
    padding-bottom: 8px
    margin-bottom: 8px
    margin-top: 0

.footer__main-column-header-icon
  display: none;
  +below($tablet)
    display: block
    box(18px)
    svg
      box(18px)
      fill: #fff
      transition: transform 0.4s ease;
    &.active
      svg
        transform: rotate(90deg);


.footer__main-column-content
  margin-left: 10px
  padding: 0
  list-style-type: none
  max-height: inherit
  &.active
    margin-bottom: 22px
  a
    color: #fff
  +below($tablet)
    max-height: 0
    margin: 0
    overflow: hidden;
    transition: max-height .4s ease-out;

.footer__main-column-wide
  width: 510px
  +below($wide)
    width: 100%
    align-self: flex-end;
  +below($tablet)
    display: none

.footer__main-column-mobile
  text-align: center;
  font-size: .875rem
  h3
    color: #fff
    font-size: 1.1rem
    margin-bottom: 17px
  +above($tablet)
    display: none

.footer__main-icons
  display: flex
  justify-content: space-between;
  border-top: 1px solid #f662a6
  padding-top: 24px
  +below($desktop)
    justify-content: space-around;
    flex-direction: column-reverse;
  +below($tablet)
    border-top: 0
    padding 10px 0

.footer__main-icons-partners
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  align-items: center;
  +below($desktop)
    justify-content: center;
  +below($tablet)
    display: none
  img
    margin-right: 32px
    max-height: 24px
    +below($mobile)
      max-height: 20px;
  .co2
    max-height: 46px
    img
      box(100px, 46px)
      max-height: 46px

.footer__main-icons-partners-mobile
  display: flex;
  justify-content: center
  flex-wrap: wrap;
  gap: 28px;
  margin-top: 16px;

  img
    max-height: 24px;

.footer__main-icons-social
  display flex
  justify-content center
  .icon-wrap
    display flex
    flex-direction column
    align-items center
    &:hover,
    &:active,
    &:focus
      text-decoration none
  .icon-image
    box(30px)
    display flex
    justify-content center
    align-items center
    margin: 10px;
    svg
      box(30px)

.newsletter-footer-icon
  border-radius: 8px;
  padding: 3px;
  fill: white;
  background: black;

.footer__sub
  background-color: #f3f3f3

.footer__sub-content
  max-width: $page_width;
  margin: 0 auto
  padding: 30px 0
  +below($wide)
    padding: 30px
  +below($tablet)
    display: none

.footer__sub-columns
  display: flex
  justify-content: space-between;
  h3
    font-size: 1.1rem
    margin-bottom: 20px
  a, p
    font-size: .875rem

.footer__sub-newletter
  width: 510px

.footer__sub-list
  display: flex
  flex-direction: column;

.footer__sub-list-item
  display: flex;
  align-items: center
  margin-bottom: 12px
  svg
    fill: $brand_color_variation
    margin-right: 17px
    box(18px)

.footer__sub-last
  display: flex
  align-items: center;
  justify-content: space-between;
  max-width: $page_width;
  margin: 0 auto
  border-top: 1px solid #e0e1e0
  padding: 30px 0
  +below($wide)
    padding: 30px
  +below($desktop)
    flex-wrap: wrap;
    flex-direction: column-reverse;

.footer__sub-company-info
  font-size: .8rem
  color: #bbb
  a
    color: #bbb
  +below($desktop)
    text-align: center;

.footer__sub-prisjakt
  display: flex;
  align-items: center;
  font-size: .9rem
  svg
    box(20px)
    margin: 0 6px 0 12px
  +below($desktop)
    margin-bottom: 14px

.footer__sub-signup
  display: flex
  .newsletter-input-signup
    border-color: #ddd
    color: #999
    font-size: .875rem
    height: 56px
  .newsletter-input-button
    text-transform: uppercase;
    letter-spacing: 1px
    height: 56px
    padding-left: 40px
    padding-right: 40px

.footer__sub-signup-condition
  display: flex
  margin-top: 8px
  justify-content: flex-start;
  cursor: pointer;
  input
    margin-right: 8px
  span
    line-height: 1.3
    color: #999
    font-size: .8rem

.footer__chat
  cursor: pointer;
  &:hover
    text-decoration: underline

.footer-tretti__chat-text
  font-size: 1rem;

.footer__chat-online
  color: $success_color

.footer__chat-offline
  color: $danger_color
  