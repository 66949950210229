.vip__compare-outer
  display:none;
  sectionOuter(#fff)

.vip__compare-inner
  sectionInner($vip_inner_section_border_color)

.vip__compare-loader
  &.loading
    position: relative
    min-height: 100px;
  &.loading::before
    preloader(100px)

.vip__compare-table__toggle
  display none
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px solid $neutral_light_color;
  padding: 8px 0;
  cursor:pointer;
  +below($mobile)
    flex-direction: row-reverse;
  .expanded &
    border-top: 0;

.vip__compare-table__toggle-icon
  display none
  cursor: pointer
  width: 20px;
  height: 20px;
  margin-bottom: 5px;
  border: transparent 3px solid;
  border-right-color: #333
  border-bottom-color: #333
  transform: rotate(45deg);
  transition: transform 300ms ease-in 300ms, -webkit-transform 300ms ease-in 300ms;
  +below($mobile)
    margin-top: -7px;
    margin-left: 10px;
  .data-fetched &
    display block
  .expanded &
    transform: rotate(225deg);
    transition: transform 300ms
    margin-bottom: -5px;
    margin-top: 10px;

.vip__compare-table__toggle-label
  display: none;
  font-size: .875rem
  text-align: center;
  +below($mobile)
    font-size: 1rem
  .data-fetched &
    display block

.vip__compare-table__wrapper
  position: relative;

.vip__compare-table__holder
  border-left: 1px solid $neutral_light_color;
  transition: all 350ms ease-in-out;
  position: relative
  max-height: 610px
  overflow-y: hidden
  overflow-x: scroll
  scroll-behavior: smooth;
  .expanded &
    max-height: 2000px
    &::after
      display: none;

.vip__compare-table
  border-top: 1px solid $neutral_light_color;
  table-layout: fixed;
  width: $page_width
  tr:nth-child(odd) td
    background-color: white
  tr:nth-child(even) td
    background-color: #f4f4f4
  tr:nth-child(odd) th:nth-child(1)
    background-color: #fff
  tr:nth-child(even) th:nth-child(1)
    background-color: #f4f4f4
  tr:nth-child(odd) td:nth-child(2)
    //background-color: #caedfc
    background-color: #ffe
  tr:nth-child(even) td:nth-child(2)
    //background-color: #94d1ee
    background-color: #ffffde
  tr, td
    &.hide
      display none
    &.first-prod
      border-top: 3px solid $brand_color_variation
  td, th
    position: relative;
    font-size: .875rem;
    box-shadow: inset -1px -1px 0px 0px $neutral_light_color;
    width: "calc(%s / 6)" % $page_width
    overflow-wrap: break-word;
    +below($mobile)
      width: 110px
      word-wrap: break-word
      font-size: .7rem
      padding: 4px 8px
    &:nth-child(1)
      +above($tablet)
        position: sticky;
        left: 0
        z-index 2
    &:nth-child(2)
      //box-shadow: inset -2px 0 0px 0px $brand_color_variation, inset 0 -1px 0px 0px #86c1df, inset 2px 0 0px 0px $brand_color_variation;
      box-shadow: inset -2px 0 0px 0px $brand_color_variation, inset 0 -1px 0px 0px $neutral_light_color, inset 2px 0 0px 0px $brand_color_variation;
      +above($tablet)
        position: sticky;
        left: "calc(%s / 6)" % $page_width
        z-index 2
  th
    border-bottom: 0
    +below($mobile)
      width: 90px;
  .vip__compare-products__prod-link:hover
    text-decoration: none;
  .energy__label-data-right, 
  .energy__label-data-left
    &:hover
      text-decoration: underline

.vip__compare-product_row
  text-align: center
  td:nth-child(2)
    .vip__compare-first-prod-label
      display: block
  td:nth-child(2)::before
    content: "";
    height: 2px;
    width: 100%;
    position: absolute;
    background-color: $brand_color_variation;
    top: -1px;
    left: 0;

.vip__compare-next-arrow,
.vip__compare-back-arrow
  display: none
  position: absolute
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 40px;
  height: 100%;
  top: 0;
  z-index: 2;
  cursor:pointer;
  user-select: none;
  .data-fetched &
    display: flex;
    +below($desktop)
      display: none;
  svg
    box(40px)
    background-color: $content_slider_arrow_background
    padding $content_slider_arrow_padding
    stroke-width 5px
    fill: $content_slider_arrow_fill
  :nth-child(1)
    position:absolute
    top: 274px;
  :nth-child(2)
    display:none;
    position:absolute
    bottom: 30%;
    .expanded &
      display:flex;

.vip__compare-next-arrow
  right: -50px;

.vip__compare-back-arrow
  left: -50px;

.vip__compare-reviews
  display: flex;
  align-items: center;
  justify-content: center;
  .rating-stars,
  .rating-stars__back,
  .rating-stars__front__inner
    width: 90px;
  .rating-stars svg
    width 14px
    height 14px

.vip__compare-first-prod-label
  position: absolute;
  top: -1px;
  left: 0;
  background-color: $brand_color_variation;
  color: #fff;
  font-weight: bold;
  padding: 5px 7px;
  display none

.vip__compare-group-header,
.vip__compare-group-header-label
  background-color $dark_gray !important
  color $action_text_color !important
  box-shadow: none !important

.vip__compare-group-header-label
  z-index: 3 !important;

.vip__compare-products__prod-img
  margin-bottom: 10px;
  padding: 4px;
  background-color: white;
  border: 1px solid $neutral_light_color;

.vip__compare-products__prod-brand
  font-family: $font_family_header
  font-weight: $brand_font_weight
  text-transform uppercase
  color $brand_font_color
  text-align: center;

.vip__compare-products__prod-sku
  font-size: .7rem;
  margin-bottom: 10px;
  text-align: center;

.vip__compare-products__prod-atb
  text-align: center;
  button
    padding: 0 6px;


.vip__compare-stock-status
  .in-stock
    color $in_stock_color

.vip__compare-yearly-consumption
  background-color: #dff2fe !important;
