// TEXT INPUT
.custom-text-input-wrapper
  position relative
  lost-column 1 1
  &.percent50
    lost-column 1/2 2 10px
  &.percent60
    lost-column 3/5 2 10px
  &.percent40
    lost-column 2/5 2 10px

.custom-text-input
  position: relative;
  border $input_border
  border-radius $input_border_radius
  display: flex
  height 45px
  padding: 4px 18px
  width: 100%
  background-color #fff

  label
    color rgba(0,0,0,0.4)
    position: absolute;
    font-size: .9rem
    left 18px
    top 13px
    transition: all 100ms ease
    pointer-events: none;
    width: calc(100% - 18px)
    truncate()
  input
    border none
    height: 100%
    width: 100%
    color rgba(0,0,0,.8)
    padding 0
    +below($tablet)
      padding 0
    &:focus, &.filled
      margin-top: 13px
      height: 22px
      & ~ label
        font-size: .65rem
        top 3px
    &:focus ~ label
      color $dark_gray
  &.optional
    label
      font-style: italic
  &.readonly
    background-color rgb(240, 240, 240)
    input
      background-color rgb(240, 240, 240)

.text-input__validation-tick, .text-input__validation-cross
  position: absolute;
  box(16px)
  pointer-events: none
  right 10px
  top 12px
  svg
    box(100%)
    stroke-width 3

.text-input__validation-tick svg
  stroke $success_color

.text-input__validation-cross
  color $danger_color
  font-weight bold
  font-size 1.5rem
  line-height .9

.text-input__validation-message
  font-size .8rem
  line-height 1.1
  min-width 240px
  margin-top 2px

.text-input__success
  border-color: green;

.text-input__error
  border-color: red;

// BUTTON NEXT TO INPUT
.text-input-button
  height: 45px;
  border: 0
  background-color: $action_color;
  color: #fff;
  padding-left: 20px
  padding-right: 20px


 // RADIO
.radio-button__label
  cursor pointer
  position: relative;
  padding-left: 34px
  display flex
  align-items center
  margin-right 20px
  input[type=radio]
    position: absolute
    visibility: hidden
  +below($mobile)
    padding-left 28px

.radio-button__custom
  position: absolute;
  left 0
  top calc(50% - 10px)
  circle(20px)
  border $input_border
  background-color: #fff
  display: flex;
  transition: border-color 90ms linear
  &:before
    content ''
    display: block;
    circle(12px)
    background-color: $radio_select_color
    margin: auto;
    opacity: 0
    transform: scale(0)
    transition: all 100ms ease 80ms

.radio-button__label
  input[type=radio]:checked
    & ~ .radio-button__custom
      &:before
        transform scale(1)
        opacity: 1
      border-color $radio_select_color
      border-width: 2px


// CHECKBOX
.checkbox__label
  cursor pointer
  display flex
  align-items center
  margin-left: 35px;
  input[type=checkbox]
    position: absolute
    visibility: hidden

.checkbox__custom
  margin-right: 5px
  box(19px)
  border-radius: 2px
  border solid 1px #ccc
  background-color: #fff
  display: flex;
  transition: border-color 90ms linear
  position: absolute;
  margin-left: -35px;
  svg
    stroke #fff
    stroke-width 3
    box(15px)
    stroke-dasharray 30
    stroke-dashoffset 30
    margin: auto
    transition: stroke-dashoffset 200ms linear

.checkbox__label
  input[type=checkbox]:checked
    & ~ .checkbox__custom
      svg
          stroke-dashoffset 0
      border-color $radio_select_color
      background-color: $radio_select_color

.input__custom
  border $input_border
  border-radius $input_border_radius
  width: 100%
  padding: 12px 18px;
  margin-bottom: 12px;
  text-align: left;
  font-size: .9rem
  resize none
  display block
  &::placeholder
    color rgba(0,0,0,0.4);
