.altapay.container
  //
  // Altapay forms
  // --------------------------------------------------  
  .pensio_payment_form_table,
  .pensio_payment_form_outer
    input,
    select
      height 48px
      padding 6px 12px
      font-size 16px
      border 1px solid #b2b2b2
      border-radius 4px
      width 100%
      max-width 382px
      transition border-color ease-in-out 0.15s

      &:focus
        border-color #8DC73F
    label
      font-size 14px

  //
  // Credit card
  // --------------------------------------------------
  .pensio_payment_form_row
    margin-bottom 24px

  .expiry_row
    display flex
    flex-wrap wrap

    .pensio_payment_form_label_cell
      margin-right 3px

    .pensio_payment_form_input_cell
      display flex
      gap 10px
      width 100%

      select 
        width 186px

  .pensio_payment_form_cardholdername_error
    font-size 14px
    margin-bottom 24px
    margin-top -12px

  .cvc_description_row
    display flex
    flex-wrap wrap
    gap 3px
    font-size 14px
    margin-bottom 12px
    margin-top -12px
  
    .pensio_payment_form_cvc_cell
      width 100%

  //
  // Finance
  // --------------------------------------------------
  .santanderForm
    position relative

  //Spinner
  .AltaPay_spinner
    width 100%

    img
      width 70px
      display block
      margin 0 0 0 auto

  .AltaPay_CustomField
    float left
    width 100%
    margin-bottom 10px

    label
      float left
      width 37%
      margin-right 3%
      padding 10px 0
      +below($phablet)
        width 100%
        margin 0

      .AltaPay_Custom_RequiredElement
        color  $danger-color
        padding-left 4px

    div:first-of-type
      float left
      width 60%
      +below($phablet)
        width 100%

    .errorMsg
      float left
      margin-left 40%
      margin-top 4px
      width 60%
      color  $danger-color !important
      font-size 13px
      +below($phablet)
        margin-left 0
        width 100%

    //Inputs
    input[type="text"], select
      width 70%
      +below($phablet)
        width 100%

    input[name="santander_custom[RegisteredPostNumber]"],
    input[name="santander_custom[RegisteredPostCode]"]
      width 30%
      +below($phablet)
        width 100%

    input[name="santander_custom[ApplicantPersonalId]"],
    input[name="santander_custom[TelephoneMobile]"],
    input[name="santander_custom[SocialSecurityNumber]"]
      width 50%
      +below($phablet)
        width 100%

    select[name="santander_custom[EmployedSince][0]"]
      width 27%
      +below($phablet)
        width 35%

    select[name="santander_custom[EmployedSince][1]"]
      width 42%
      +below($phablet)
        width 64%

  .AltaPay_Custom_AcceptCreditCheck
    margin-left 40%
    margin-right 0
    width 60%
    +below($phablet)
      margin-left 0
      width 100%

    label
      width 100%
      padding 0

      input[type="checkbox"]
        float left
        width 19px
        height 19px
        margin-right 8px



  .AltaPay_CustomRadioBox
    div:first-of-type
      float left
      width 37%
      margin-right 3%
      font-weight bold
      +below($phablet)
        width 100%
        margin 0

    .RadioButtonGroup
      float left
      width 60%

      label
        width 100%
        padding 0
        margin-bottom 5px
        font-weight:normal

        input[type="radio"]
          float left
          width 16px
          height 16px
          margin-right 8px

  .AltaPay_Custom_NorwegianCitizen label
    width: 50px !important
