.b2b__nav
  position fixed
  width 100%
  font-size: .9rem;
  background: #333
  height: 40px
  z-index: $b2b_nav_Z
  +below($mobile)
    font-size: .6rem

.b2b__nav-placeholder
  height: 40px

.b2b__nav__inner
  lost-center $page_width
  align-items: center;
  height: 100%
  position: relative;

.b2b__nav__btn
  display: flex
  color: #fff;
  cursor: pointer;
  padding: 0 14px
  border-right: 1px solid rgba(255, 255, 255, .3)
  height: 100%
  align-items: center;
  +below($mobile)
    padding: 0 7px
  &:hover
    color: rgba(255, 255, 255, .8)
    text-decoration: none;
  &:first-child
    padding-left: 0
    +below($desktop)
      padding-left: 10px


@media print
  .b2b__nav,
  .b2b__nav-placeholder
    display: none;
