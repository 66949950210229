.srp__brand-header
  width $page_width
  margin: 10px auto
  display: flex
  lost-flex-container row
  +below($page_width)
    width: 100%
    padding: 0 20px
  +below($mobile)
    padding: 0 10px

.srp__brand-header-nav
  lost-column 4/15 2 60px
  +below($page_width)
    lost-column 9/30 2 30px
  +below($desktop)
    display none
  .brand__categories-nav__item
    font-size .875rem
    &:nth-child(n+9)
      display none
  .brand__categories-lvl1-trigger
    height 38px

.srp__brand-header-slider-wrap
  lost-column 11/15 2 60px
  +below($page_width)
    lost-column 21/30 2 30px
  +below($desktop)
    lost-column 1
.srp__brand-header-row
  display: flex
  justify-content: center;
  width: 100%
  .miele__header
    flex-grow: 2

.srp__brand-slider
  flex-grow: 2
  overflow: hidden;
  &.miele
    .active-dot
      background-color: $brand_color_miele;
