.energy_consumption_label-wrapper
    border: 2px solid #4EAE31;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 12px;
    margin-bottom: 24px;

.energy_consumption_label-upper_label
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    +below($mobile)
        display: block;

.energy_consumption_label-upper_label_left
    display: flex;

    +below($mobile)
        justify-content: center;
        margin-bottom: 8px;

.energy_consumption_label-percentage
    font-weight: 900;
    font-size: 36px;
    margin-right: 8px;
    margin-left: 8px;

.energy_consumption_label-percentage_img
    min-width: 48px;
    min-height: 48px;

.energy_consumption_label-percentage_text
    font-weight: 700;

.energy_consumption_label-bottom_label_text
    font-weight: 700;

.energy_consumption_label-span
    font-weight: 400;

.energy_consumption_label-tooltip
    font-weight: 400;