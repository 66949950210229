.brand__categories-row
  lost-center: 100%;

.brand__categories-col
  lost-column: 1/3;
  background-color: #fff;
  box-shadow: 1px 3px 8px rgba(0,0,0,0.2);
  margin-bottom: 20px
  +below($wide)
    lost-column 1/2

.brand__categories-img
  position: relative;
  display: flex;
  padding: 10px 0 20px 0;
  img
    z-index: 1;
    margin: auto
  +below($mobile)
    padding: 0

.brand__categories-header
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, .7)
  color: #fff;
  padding: 10px 0;
  text-align: center;
  font-family: $font_family_base
  font-weight: bold
  z-index: 2;
  +below($mobile)
    font-size: 0.8rem;
    padding: 8px 0;

.brand__categories-sub
  display: block;
  padding: 7px 12px;
  font-size: 0.9rem;
  color: #333;
  border-bottom: 1px solid #e9e1e1;
  &:hover
    text-decoration: none;
    background-color: #f8f8f8;
  +below($mobile)
    font-size: 0.7rem;
