.insurance-overlay
  &__container
    +below($tablet)
      height 65vh
      overflow scroll 

  &__content
    padding 28px 28px 24px 28px
    +below($tablet)
      padding 10px

  &__header_wrapper
    margin 0
    display flex 
    justify-content space-between
    padding-bottom 18px
      
  &__header
    font-size: 36px;
    color: #00966c;
    margin: 0;
    +below($tablet)
      font-size 26px

  &__close-overlay
    position absolute
    font-size 32px
    color #514f4f
    cursor pointer
    right 16px
    top 8px
    font-weight bold

  &__subheader
    padding-top 18px
    padding-bottom 9px
    font-size: 20px;
    font-weight: 800;
    +below($tablet) 
      font-size 18px;

  &__list
    list-style: none;
    padding: 0;
    margin: 0;

  &__list-item
    align-items: center;
    display: flex;
    gap: 14px;
    padding-bottom 1px

  &__list-icon
    stroke: $success_color;
    fill: $success_color;
    height: 22px;
    width: 20px;
    min-width: 20px;

  &__sub-item
    display block
    &--final-item
      padding-top 1px
      padding-bottom 12px

  &__add-container
    list-style: none;
    padding: 0 0 0 0;
    margin: 0;
    +below($tablet)
      display none
  &__price
    padding-bottom 20px

  &__terms-link
    padding-top: 20px
    > p
      margin 0

  &__add-insurance-wrapper
    padding 0

  &__add-btn
    width: 168px;
    height: 48px;
    background-color: $action_color;
    border-radius: 4px;
    border: none;
    color: #fff;
    +below($tablet)
      width 100%

.insurance-overlay-footer

  &__container
    background: #eee;
    padding: 24px 24px 20px 24px;
    margin-top: auto;
  
  &__title
    display flex 
    gap 10px
    align-items center
    > p
      margin 0
      font-size 20px
      font-weight bold 

  &__logo
    width 62px
    height 26px
    
.insurance-overlay-mobile-button
  &__container
    display none
    padding 12px
    box-shadow: 0px -2px 4px -2px rgba(36, 36, 36, 0.1), 0px -4px 6px -1px rgba(36, 36, 36, 0.1)
    +below($tablet)
      display block

  &__text
    display flex
    padding-bottom 8px
    justify-content space-between

.hide-content
  display none

.hide-insurance-from-overlay
  display none

.hide-insurance-table
  display none