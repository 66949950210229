.vip__add-to-basket-sticky-wrapper
    display: none;

.vip__add-to-basket-sticky
    display: flex;
    height: 0px;
    background: #FFFFFF;
    position: fixed;
    z-index: 2005;
    width: -webkit-fill-available;
    top: 0%;
    transition: height 0.7s ease 0s;
    +below($mobile)
        display: flex;
        top: unset;
        bottom: 0%;

.vip__add-to-basket-sticky-content
    font-family: Exo 2;
    display: flex;
    width: -webkit-fill-available;
    justify-content: space-around;
    padding: 0px 12px;
    height: 0px;
    z-index: 2007;
    box-shadow: 6px 0px 6px 0px rgba(0, 0, 0, 0.2);
    transition: height 0.7s ease 0s;
    position: fixed;
    top:0;
    overflow: hidden;
    +below($mobile)
        flex-direction: column;
        border-radius: 15px 0 0 0;
        box-shadow: 0px -2px 8px rgba(36, 36, 36, 0.1);
        margin-left: 58px;
        height: 0px;
        bottom: 0;
        top: unset;

.vip__add-to-basket-sticky-height
    height: 96px;
    +below($mobile)
        height: 74px;

.vip__add-to-basket-sticky-content-height 
    height: 96px;
    padding: 12px 12px 12px 12px;
    +below($mobile)
        height: 74px;
        padding: 4px 12px 12px 12px;

.vip__add-to-basket-sticky_image
    margin-right: 10px;
    width: 70px;
    +below($mobile)
        display: none;

.vip__add-to-basket-sticky_product-info-wrapper
    display: flex;
    flex-direction: row;
    +below($mobile)
        width: -webkit-fill-available;

.vip__add-to-basket-sticky_product-info
    align-self: center;
    +below($mobile)
        display: flex;
        width: -webkit-fill-available;
        justify-content: space-between;

.vip__add-to-basket-sticky_brand-name
    font-style: italic;
    font-weight: bold;
    font-size: 24px;
    color: #888888;
    +below($tablet)
        font-size: 21px;
    +below($mobile)
        font-size: 14px;

.vip__add-to-basket-sticky_sku-product-category
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #333333;
    +below($tablet)
        font-size: 11px;
    +below($mobile)
        font-size: 11px;

    &.sku
        +below($mobile)
            display: none;

.vip__add-to-basket-sticky_sku-product-category-wrapper
    display: flex;
    +below($tablet)
        flex-direction: column;
    +below($mobile)
        flex-direction: row;
        align-items: center;

.vip__add-to-basket-sticky_product-add-wrapper
    display: flex;

    +below($mobile)
        width: -webkit-fill-available;
        justify-content: space-between;

.vip__add-to-basket-sticky_product-price-info
    margin-right 10px;
    align-self: center;

.vip__add-to-basket-sticky_before-price-wrapper
    display: flex;

.vip__add-to-basket-sticky_before-price
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #888888;
    +below($mobile)
        display: none;

    &.dashed
        text-decoration: line-through;

.vip__add-to-basket-sticky_price
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    padding: 4px 8px;
    background: $neutral-lightest;
    border-radius: 10px;
    +below($tablet)
        font-size: 21px;
    +below($mobile)
        font-size: 21px;
        padding: 0px 8px;
    &.discount
        background: $offer_background_color;

.shop_SE,
.shop_ENEMO,
.shop_TRETSE 
    .vip__add-to-basket-sticky_price
        &.discount
            background: $offer_background_color_se;  
            color: #fff;  

.vip__add-to-basket-sticky_add-to-basket-button
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    font-size: 18px;
    font-weight: bold;
    border-radius: 5px;
    +below($mobile)
        font-size: 14px;

svg.vip__add-to-basket-sticky_add-to-basket-icon
    height: 24px;
    width: 24px;
    fill: #FFFFFF;
    vertical-align: top;
    padding-left: 6px;
    +below($mobile)
        display: none;

.vip__add-to-basket-sticky-rating
    font-size: 12px;
    padding-top: 5px;
    margin-bottom: 0;
    +below($mobile)
        display: none;

.vip__add-to-basket-sticky-rating .rating-stars svg
    height: 13px;
    width: 13px;

.vip__add-to-basket-sticky-rating .rating-stars__back, .vip__add-to-basket-sticky-rating .rating-stars__front__inner, .vip__add-to-basket-sticky-rating .rating-stars {
    width: 90px;
    height: 14px;
}

.vip__add-to-basket-sticky-coi
    +below($mobile)
        box-shadow: 1px -1px 4px rgba(36, 36, 36, 0.1);
