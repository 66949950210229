.feedback-box
  width: 100%
  lost-center $cof_center_col_width
  padding: 15px 30px
  justify-content: flex-start;
  align-items: flex-start;
  flex-flow: row nowrap;
  background-color: $cof_validation_success
  color: #fff
  margin-bottom: 25px
  border-radius: 6px
  font-size: .875rem

  svg
    box(20px)
    margin-right: 20px
    fill $cof_validation_success_light
  &.alert
    background-color: $cof_validation_error
    svg
      fill #fff
