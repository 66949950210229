.custom-page__row-inner
  width 100%
  max-width $page_width
  margin 0 auto
  lost-flex-container row
  padding 30px 0
  +below($wide)
    padding 30px 20px
  +below($mobile)
    padding 30px 10px
  h1,h2,h3
    margin-top 0

.custom-page__image-link
  color: $black;

.custom-page__image-title
  margin-top: 20px
  font-size: .875rem
  text-align: center

.custom-page__column
  img
    max-width 100%
    margin auto
    display block
  .button-wrapper-custom-page
    text-align center
    a
      +below($mobile)
        display block

.custom-page__column-1
  lost-column 1/1 1 25px
  img
    width 100%

.custom-page__column-2
  lost-column 1/2 2 25px
  +below($tablet)
    lost-column 1/1 1 25px
    margin-bottom 30px

.custom-page__column-2:last-child
  +below($desktop)
    margin-bottom 0

.custom-page__column-3
  lost-column 1/3 3 25px
  +below($desktop)
    lost-column 1/1 1 25px
    margin-bottom 30px

.custom-page__column-3:last-child
  +below($desktop)
    margin-bottom 0

.custom-page__column-4
  lost-column 1/4 4 25px
  +below($wide)
    lost-column 1/2 2 25px
    margin-bottom 30px
  +below($mobile)
    lost-column 1/1 1 25px
    margin-bottom 30px

.custom-page__column-4:nth-child(3)
  +below($desktop)
    margin-bottom 0
  +below($mobile)
    margin-bottom 30px
    
.custom-page__column-4:nth-child(4)
  +below($desktop)
    margin-bottom 0

.custom-page__video-wrapper
  position: relative;
