
table
  border-collapse: collapse;

th
  border-bottom: solid 1px rgba(0,0,0,.1)
  text-align left
th, td
  padding: 6px 15px

tr:nth-child(2n)
  background-color: rgba(0,0,0,.05)
