.career__countries
  background: #dbdbdb;

.career__countries-wrapper
  display: flex;
  flex-wrap: wrap;

.career__country
  display: flex;
  padding: 14px;
  lost-column: 1/3;
  background-color: #fff;
  align-items: center;
  +below($tablet)
    lost-column 1/1
  h3
    margin:0;
  p
    margin: 0
  svg
    width: 150px;
    height: 112px;
    margin-right: 20px;
