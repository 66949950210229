.insurance-list__item
    background: #fff;
    border-radius: 8px; 
    border: 2px solid rgba(0,0,0,0.15)
    padding: 12px; 
    margin-top 10px
    margin-bottom: 10px;

.insurance-product__container
    display flex
    +below($mobile)
      flex-flow: wrap;

.insurance-product__subheader
    margin 0
    text-align: right;
    font-size: 1.4rem;

.insurance-product__subheader-wrapper
    display: flex;
    justify-content: space-between;  
    font-weight bold

.insurance-product__subheader--desktop
    margin: 0
    font-weight bold
    font-size: 16px
    +below($mobile)
      font-size 0.9rem

.insurance-product__subheader--mobile
  display none
  font-weight bold
  +below($mobile)
    display block
    padding-left 10px

.insurance-product__details
    width 450px
    font-size 14px
    padding-left: 10px
    +below($tablet)
      width calc(100% - 95px - 142px);
    +below($mobile)
      padding-left 0
      width: calc(100% - 80px);

.insurance-product__button 
    height 30px
    float right

.insurance-product__bullet-points
    padding-left 27px
    margin-top: 14px
    list-style-position inside 
    +below($mobile)
      padding-left: 13px;
    li
      font-size .9rem
      color rgba(0,0,0,.7)
      +below($mobile)
        font-size: .8rem

.insurance-product__bullet-points-text
  font-size .9rem
  color rgba(0,0,0,.7)
  +below($mobile)
    font-size: .8rem

.insurance-product__image-container
    position relative
    margin-right: 15px
    width: 80px
    +below($mobile)
      width 65px
      height 65px
      display flex
      width auto

 .insurance-product__image
  +below($mobile)
    width 65px
    width 65px     

.insurance-product__text
  padding-bottom 12px
  width 408px
  +below($tablet)
    width 100%
    font-size: 0.8rem;

.insurance-product__image--shield
    fill $brand-primary 
    width 30px
    position absolute
    top: -64px;
    right: -10px;
    +below($mobile)
      left: 40px

.insurance-product__read-more
    text-align end


.insurance__item-button
  margin-top: 15px
  *
    pointer-events none
  > span
    transition: opacity 200ms
    display: inline-block;
  +below($mobile)
    margin-left: auto;
    margin: 0
  &.adding
    > span
      opacity: 0
  &.added
    background-color: $success_color
    pointer-events none;
    > span
      opacity: 0

.hide-insurance-from-overlay
  display none

.insurance-add-button-wrapper
  text-align right
  min-width fit-content
  +below($mobile)
    display: flex;
    margin-left: auto;
    width: 100%;
    justify-content: space-between;
    margin-top 10px

.insurance-price-mobile
  display none
  margin 0
  font-weight bold
  align-self center
  padding-right 12px
  +below($mobile)
    display block
