.tretti-bf__countdown-header
  background-image: url(//images.wagcdn.com/f/landingpages/blackfriday2018/countdown/tretse/header-left-bg.jpg), url(//images.wagcdn.com/f/landingpages/blackfriday2018/countdown/tretse/header-right-bg.jpg)
  background-position: left top, right top
  background-repeat: no-repeat;
  +below(1360px)
    background-image: url(//images.wagcdn.com/f/landingpages/blackfriday2018/countdown/tretse/header-left-bg.jpg)
    background-position: left top
  +below($tablet)
    margin-bottom: 60px

.tretti-bf__countdown-header-content
  pageWrapper()
  display: flex
  align-items: center;
  justify-content: space-around;
  padding-top: 30px
  background-image: url(//images.wagcdn.com/f/landingpages/blackfriday2018/countdown/tretse/stars.jpg)
  background-position: top center
  background-repeat: no-repeat;
  min-height: 500px
  +below(1360px)
    background-position: top right
  +below($desktop)
    background-image: none
    flex-direction: column;
    img
      margin: 40px 0 10px 0
