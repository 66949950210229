.store__map-container
  width: 100%;
  height: 460px

.store__info-wrapper
  pageWrapper()

.store__info-section-gray
  sectionOuter($neutral_lightest_color, 30px 0)

.store__info-section-brand
  sectionOuter($brand_color_variation, 55px 0)

.store__info-row
  display: flex
  flex-wrap: wrap;

.store__info-col-first
  width: 38%
  +below($desktop)
    width: 50%
    margin-bottom: 20px
  +below($tablet)
    width: 100%

.store__info-col-second
  width: 32%
  +below($desktop)
    width: 50%
    margin-bottom: 20px
  +below($tablet)
    width: 100%

.store__info-col-third
  width: 30%
  +below($desktop)
    width: 100%
    margin-bottom: 20px

.store__info-text-row
  display: flex
  flex-wrap: wrap
  margin-bottom: 5px

.store__info-text-first
  font-weight: bold
  width: 110px
  +below($mobile_small)
    width: 100%
.store__get-direction
  width auto
.store__info-text-warning
  font-size: .875rem
  color: $danger_color
