.cof__tabs-list
  zero-list()
  display flex
  width 100%
  margin-bottom 35px

.cof__tab
  padding 12px
  width 30%
  white-space nowrap
  border-radius 5px
  background-color $cof_bg
  font-size 0.8rem
  cursor pointer
  color rgba(0,0,0,.5)
  transition color 150ms
  margin-right 3%
  display flex
  align-items center
  justify-content center
  &:last-child
    margin-right 0
  &:hover
    color rgba(0,0,0,.9)
  &.selected
    background-color $action_color
    color #fff
    cursor default
  +below($tablet)
    font-size 0.7rem
    white-space normal

.cof__tab-content
  h3
    margin 30px 0 15px

.cof__tabs__leave-at-wrapper
  display flex
  flex-direction column
  max-width 460px

.cof__nav-adr-lookup
  display flex
  margin-bottom 20px
  position relative
  align-items flex-start
  .button
    line-height 45px 

.cof__nav-adr-lookup__input
  width 350px

.cof__nav-adr-lookup__success
  color $cof_validation_success
  position absolute
  bottom -20px
  font-size 0.875rem