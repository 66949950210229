.rating-stars
  position: relative;
  box(110px, 18px)
  svg
    box(18px)

.rating-stars__back, .rating-stars__front__inner
  display: flex;
  box(110px, 18px)
  justify-content: space-between;

.rating-stars__back, .rating-stars__front
  position: absolute;
  top 0
  left 0

.rating-stars__front
  overflow: hidden;
  svg
    fill $star_rating_color

.rating-stars__back
  svg
    fill transparent
    stroke-width 4px
    stroke $star_rating_stroke_color
