.wag-popover
  position: absolute;
  visibility: hidden;
  pointer-events: none;
  z-index: 10
  width 220px
  padding: 6px 8px
  box-shadow: -2px 0px 14px rgba(0,0,0,.4)
  background-color: #fff
  color: rgba(0, 0, 0, .6)
  border-left: solid 4px $brand_color
  font-size .75rem
  line-height 1.25
  transition: all 400ms cubic-bezier(0.175, 0.885, 0.320, 1.1)
  transform-origin: 10% top;
  transform scale(0.3)
  opacity 0
  &.action-color
    background-color: $action_color
    color: #fff
    border-left: solid 4px darken($action_color, 25%)
    &:after
      border-bottom-color $action_color
  &.brand-color
    background-color: $brand_color
    color: #fff
    border-left: solid 4px darken($brand_color, 25%)
    &:after
      border-bottom-color $brand_color
  &.popover-show
    pointer-events: all;
    visibility: visible;
    opacity 1
    transform scale(1)
  &:after
    content: ''
    display: block;
    box(0)
    border solid 4px transparent
    border-bottom-color #fff
    position: absolute;
    left: 9px
    top: -8px
