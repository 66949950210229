$brand_color_miele = #a60c1f;

.miele__header
  display: flex;
  margin-bottom: 10px;
  justify-content: center;
  height: 60px;
  +below($tablet)
    height: 40px;
  img
    height: 60px;
    +below($tablet)
      height: 40px;

.brand__miele
  .slider
    .active-dot
      background-color: $brand_color_miele;
