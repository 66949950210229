.mini__takeover
  background-color: #000;
  display: flex;
  justify-content: center;

.mini__takeover-wrap
  width: $page_width
  margin-top: 5px
  +below($desktop)
    width: 100%;
  +below($tablet)
    margin-top: 0
  img
    vertical-align: bottom;
