
$border-color = #dfdfdf

.blog__post-meta
  width: 100%;
  padding: 30px 40px 15px
  margin: -320px 0 0;
  color #b2b2b2
  font-size: .7rem
  display: flex;
  align-items: center;
  background-color: #fff
  border solid 1px $border-color
  border-bottom: none
  img
    margin-right: 10px
    box(48px)
  a
    font-size .9rem
    color: rgba(0,0,0,.7)
  +below($desktop)
    margin: -60px 0 0;
    padding: 25px 25px 15px
  +below($mobile)
    justify-content: center;
    padding: 20px 25px 5px

.blog__post-author-image
  margin-right 10px

.blog__post-date
  display: block;

.blog__post-body
  padding: 1px 40px 50px
  background-color: #fff
  border solid 1px $border-color
  border-top: none
  img
    lost-center 100%
    margin-top: 1.5em
    margin-bottom: 1.5em
  p
    max-width: 750px
  ul
    list-style-type: circle;
    margin: 1.2em 0
  +below($desktop)
    padding: 1px 25px 50px
    font-size .9rem
  +below($mobile)
    padding: 1px 15px 50px

.blog__post-bottom-meta a
  text-decoration none
  &:hover
    text-decoration none

.blog__post-bottom-category
  category-pill()
