.brand_whiteaway
  .campaign-banner__wrap
    position relative
    display flex
    flex-direction column
    padding-top 44px
    margin-right -30px
    +below($tablet)
      margin-bottom 15px
    +below($mobile)
      margin-right -8px
    &:after
      content ''
      z-index 0
      position absolute
      top 3px
      right 0
      width 9px
      height 42px
      background-color darken($srp_neutral_bg_color, 25%)
      transform skewY(-30deg)
      +below($mobile)
        width 7px
        top 3px
    &.offer
      &:after
        background-color darken($srp_offer_bg_color, 25%)
    &.double-banner
      margin-top 15px
      +below($mobile)
        margin-top -5px

  .grid
    .campaign-banner__wrap
      margin-right -25px
    .campaign-banner__info
      font-size 0.75rem
      margin-left 5px

  .campaign-banner__info
    position absolute
    top 0
    right: 0
    z-index 1
    height 42px
    max-width: 280px
    padding 5px 10px 5px 5px
    background-color $srp_neutral_bg_color
    display flex
    align-items stretch
    justify-content center
    &:before
      content: ''
      position absolute
      top 0
      left -15px
      box(0)
      border-top 21px solid transparent
      border-bottom 21px solid transparent
      border-right 15px solid $srp_neutral_bg_color
    &.offer
      background-color $srp_offer_bg_color
      &:before
        border-right 15px solid $srp_offer_bg_color
    +below($mobile)
      max-width calc(100% - 20px)

  .campaign-banner__info__left,
  .campaign-banner__info__right
    display flex
    flex-direction column
    align-items center
    justify-content center
    span
      line-height 1rem
      text-align center
    a
      color $black
    .force-one-line
      white-space: nowrap;

  .campaign-banner__info__left
    padding-right 8px
    span
      white-space nowrap
      +below($mobile)
        font-size 0.75rem
    .top
      text-transform uppercase
      font-weight bold
    .bottom
      font-size 0.75rem
      white-space: nowrap;

  .grid .campaign-banner__info__left
    display none

  .campaign-banner__info__right
    padding-left 8px
    border-left 1px solid darken($srp_neutral_bg_color, 20%)
    .top
      font-weight bold
    .bottom
      font-size 0.75rem
    .offer &
      border-left-color darken($offer_background_color, 20%)
    &.no-left-text
      display: block
      border: none
      padding-left 0
      span
        display: block
      p
        margin: 0


  .grid .campaign-banner__info__right
    border none !important
    padding-left 0 !important

  .campaign-banner__extra-info
    color $srp_faded_dark_color
    font-size 0.7rem
    text-align right
    display: block
    margin-right 18px

.brand_whiteaway
  &.shop_SE
    .campaign-banner__wrap
      &.offer
        &:after
          background-color darken($srp_offer_bg_color_se, 25%)
    .campaign-banner__info
      &.offer
        background-color $srp_offer_bg_color_se
        color #fff
        &:before
          border-right 15px solid $srp_offer_bg_color_se
        .campaign-banner__info__right
          border-left-color darken($offer_background_color_se, 50%)
