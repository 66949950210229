.scroll-to-top-widget
  display: flex
  position: fixed;
  circle(48px)
  background: #333
  color #fff
  align-items: center;
  justify-content: center;
  flex-direction: column;
  bottom: 80px
  right 20px
  cursor: pointer;
  font-size: .7rem
  z-index: $scroll_to_top_Z
  transform: rotate(90deg);
  svg
    box(20px)
    fill: #fff
