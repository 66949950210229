.career__job-header
  h1
    position: absolute;
    color: #fff;
    margin: 0;
    font-size: 3.1rem;
    padding: 145px 0 0 30px;
    line-height: 1.2;
    text-shadow: 0 0 10px rgba(0,0,0,0.7);
    text-transform: none
    +below($wide)
      padding: 145px 0 0 8px;
    +below($desktop)
      font-size: 2.5rem;
      padding: 155px 0 0 8px;
    +below($tablet)
      font-size: 1.9rem;

.career__job-header-video
  width: 100%
  overflow: hidden;
  height: 400px;
  video
    object-fit: cover;
    width:100%;
    height:100%;
    //filter: grayscale(100%);


.career__job-wrapper
  position: relative;
  max-width: $page_width
  margin: auto;
  margin-top: -170px

.career__job-content
  background-color: #fff
  padding: 30px;
  z-index: 99999999
  lost-flex-container row

.career__job-content-text
  lost-column 3/4
  +below($desktop)
    lost-column 2/3
  +below($tablet)
    lost-column 1

.career__job-content-btns
  margin: 15px 0;

.career__job-content-info
  lost-column 1/4
  +below($desktop)
    lost-column 1/3
  +below($tablet)
    lost-column 1
  div
    padding: 20px;
    background-color: #f7f7f7;
    border: 1px solid #dcdcdc;
  h4
    margin: 0
  p, a
    margin-top: 0
    margin-bottom: 16px
    display: block
  .button
    margin: 0
