.tretti-bf__ribbon-black
  background: #000
  color: #fff
  padding: 30px 0 60px 0
  &.last
    padding-bottom: 75px
  p
    margin: 0
  +below($tablet)
    padding: 0 0 60px 0

.tretti-bf__ribbon-pink
  background: $brand_color
  color: #fff
  padding: 0
  &.last
    padding-top: 45px
  a
    color: #fff
    text-decoration: underline

.tretti-bf__ribbon-pink-end-right
  background: linear-gradient(183deg, $brand_color 49%,#000000 50%);
  height: 140px
  +below($tablet)
    height: 80px

.tretti-bf__ribbon-pink-end-left
  background: linear-gradient(177deg, $brand_color 49%,#000000 50%);
  height: 140px
  +below($tablet)
    height: 80px

.tretti-bf__strok-flex
  position: absolute;
  width: 100%
  height: 2px
  background-color: #fff
  transform: rotate(.5deg);

.tretti-bf__text-intro
  color: #949494;
  margin: 0 0 40px 0

.tretti-bf__text-blackbg
  text-align: center;
  p
    color: #949494;
    margin: 0
  a
    color: #949494;
    text-decoration: underline;

.tretti-bf__text-blackbg-info
  margin: 30px 0
  font-style: italic;
  color: #949494;

.tretti-bf__text-blackbg-header
  margin: 0
  font-weight: normal;
  font-size: 2.2rem
  text-transform: uppercase;
  letter-spacing: 2px;
  line-height: 1.1;
  +below($desktop)
    font-size: 2rem

.tretti-bf__top-banner
  max-width: 1500px
  margin: 0 auto;
  +below($wide)
    width: 100%
    padding: 0 15px

.tretti-bf__bolt
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  left: -95px;
  transform: rotate(15deg);
  +below($tablet)
    left: -15px;
  svg
    bottom -70px
    position: absolute;
    width: 10%
    max-height: 400px
    animation: bounce 2s infinite
    +below($tablet)
      bottom -40px

.tretti-bf__wrapper
  width: $page_width
  margin: 0 auto;
  +below($wide)
    width: 100%
    padding: 0 15px

.tretti-bf__header
  font-size: 4rem
  text-transform: uppercase;
  font-weight: bold
  margin: 0
  line-height: 1.15
  margin-top: 30px
  text-align: center;
  .black-underline
    text-decoration: underline;
    color: #000
  +below($desktop)
    font-size: 3rem
  +below($tablet)
    font-size: 2rem
  +below($mobile)
    font-size: 1.4rem

.tretti-bf__header-sub
  font-size: 4rem
  text-transform: uppercase;
  font-weight: bold
  margin: 0
  line-height: 1
  padding-top: 20px
  padding-bottom: 30px
  .black-underline
    text-decoration: underline;
    color: #000
  +below($desktop)
    font-size: 3rem
  +below($tablet)
    font-size: 2rem
  +below($mobile)
    font-size: 1.8rem

.tretti-bf__grid-row
  lost-flex-container row

.tretti-bf__grid-box,
.tretti-bf__grid-box-large,
.tretti-bf__grid-box-color
  display: flex;
  height: 200px
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition:all 0.3s ease;
  background-size: cover;
  background-position: center;
  margin-bottom: 1px
  &:hover
    text-decoration: none;

.tretti-bf__grid-box
  background-color: #414141
  justify-content: flex-end;
  lost-column 1/3 1 1px
  &:hover
    background-color: #f6f777
    color: #000
    .tretti-bf__grid-box-logo,
    .tretti-bf__grid-box-text
      color: #000
    svg
      fill: #000
  &:nth-child(4),
  &:nth-child(5),
  &:nth-child(9),
  &:nth-child(10)
    lost-column 1/2 1 1px
    +below($tablet)
      lost-column 1/1 1 1px
  +below($tablet)
    lost-column 1/1 1 1px
    height: 100px

.tretti-bf__grid-box-large
  lost-column 1/2 1 1px
  height: auto
  font-family: $font_family_header
  +below($tablet)
    lost-column 1/1 1 1px
  +below($mobile)
    margin-bottom: 4px
  &:hover
    opacity: .8
  span
    color: #a1a09e
    font-size: 1.8rem;
    font-weight: normal;
    text-transform: uppercase;
    text-align: center;
    +below($tablet)
      font-size: 1.5rem;


.tretti-bf__grid-box-color
  lost-column 1/3 1 1px
  +below($tablet)
    lost-column 1/1 1 1px
  &:hover
    opacity: .8
  &.pink
    background-color: #f662aa
  &.blue
    background-color: #42b0e5
  &.yellow
    background-color: #f6fa6f
  span
    color: #fff
    font-family: $font_family_header
    font-size: 2.4rem;
    line-height: 1
    font-weight: normal;
    margin-top: -16px;
    text-align: center;
    text-shadow:
       3px 3px 0 #000,
     -1px -1px 0 #000,
      1px -1px 0 #000,
      -1px 1px 0 #000,
       1px 1px 0 #000;
    transform: rotate(-15deg);

.tretti-bf__grid-box-logo
  font-size: 2.3rem;
  font-family: $font_family_header
  text-transform: uppercase;
  color: #fff
  +below($desktop)
    font-size: 1.9rem

.tretti-bf__grid-box-text
  display: flex
  align-items: center;
  justify-content: center;
  font-family: $font_family_header
  margin-top: 40px
  margin-bottom: 20px
  color #939393
  text-transform: uppercase;
  font-size: .9rem
  svg
    box(16px)
    margin: 0 4px
    fill: #f6f777
  +below($desktop)
    font-size: .8rem
  +below($tablet)
    margin-top: 0

.tretti-bf__carousel-header
  display: flex
  align-items: center;
  font-size: 1.5rem
  padding-top: 75px
  padding-bottom: 15px
  text-transform: uppercase;
  font-family: $font_family_header
  line-height: 1
  +below($tablet)
    padding-top: 50px
  +below($mobile)
    text-align: center;
  svg
    box(22px)
    margin: 0 4px
    fill: #f6f777

.tretti-bf__carousel-wrap
  color: #777
  .recommended__slide__card
    background: #fff

.tretti-bf__categories
  display: flex;
  flex-wrap: wrap;
  width: 100%
  margin-bottom: 80px
  a
    width: calc((100% * 1/4) + 1px);
    margin-left: -1px
    margin-bottom: -1px
    background-color: $brand_color
    border 1px solid #fff
    color: #fff
    display: flex
    align-items: center;
    justify-content: center;
    text-decoration: none
    height: 78px
    transition:all 0.3s ease;
    font-size: 1.1rem
    &:hover
      background-color: #f662aa
    +below($tablet)
      width: calc((100% * 1/2) + 1px);
    +below($mobile)
      font-size: .9rem
