.fp__categories__helper
  display: flex
  flex-direction: column;
  position: relative;
  background-color: $neutral_lightest_color
  padding: 8px
  margin-top: 18px
  margin-bottom: 18px
  border-radius: 5px;
  box-shadow: rgba(43,43,43,0.45) 2px 0 13px;
  h1
    text-align: center;
    font-size: 1.6rem;
    margin-top: 0;
  +above($desktop)
    background-color: #fff;
    box-shadow: none;
    h1
      font-size: 2.1rem;
      margin-bottom: 25px;

.fp__categories__gradient
  width: 150px;
  height: 100%;
  background-image: linear-gradient(90deg,rgba(241,243,244,0) 70%,#ececec 100%);
  position: absolute;
  right: 0;
  bottom: 8px;
  height: 120px;
  pointer-events: none;
  +above($desktop)
    display:none;

.fp__categories__helper-cats
  display: flex;
  overflow-x: scroll;
  +above($desktop)
    flex-wrap: wrap;
    border-top: 1px solid $neutral_dark_color;
    border-bottom: 1px solid $neutral_dark_color;
    padding-top: 30px;
    padding-bottom: 10px;
    overflow: hidden;

.fp__categories__helper-cat
  display: flex;
  flex-direction: column;
  color: $black;
  border: 1px solid $neutral_light_color;
  background-color: #fff;
  min-width: 100px;
  padding: 4px 4px 10px 4px;
  margin-right: 3px;
  border-radius: 5px;
  align-items: center;
  height: 120px;
  justify-content: space-between;
  +above($desktop)
    border:none;
    height: auto;
    lost-column 1/6 6 10px
    margin-bottom: 20px;
    flex-direction: column-reverse;
    img
      width: 100px;

.fp__categories__helper-cat-name
  width: 100%;
  text-align: center;
  font-size: .7rem;
  word-wrap: break-word;
  +above($desktop)
    font-size: 1rem;
    text-transform: uppercase;
    margin-top: 12px;
