section.cof__comment
  padding 10px 0 30px
  +below($mobile)
    padding 0 0 40px

.cof__comment__control-wrap
  margin-top 10px
  max-width 460px
  position relative
  textarea
    width 100%
    resize none
    display block
    border $cof_section_border
    border-radius $input_border_radius
    padding 6px 30px 6px 10px
  span
    font-size .9rem
    color $dark_gray
