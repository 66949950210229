$tretti_offer_lp_gutter = 5px

.tretti-offer-lp__container
  width: $page_width
  margin: 0 auto
  +below($wide)
    width: 100%
    padding: 0 14px

.tretti-offer-lp__top-banner
  width: 1160px;
  margin: 30px auto 30px auto;
  background-image: url('//images.wagcdn.com/f/landingpages/offerpage/tretse/top-banner.jpg')
  min-height: 500px;
  padding-bottom: 40px
  background-size: cover;
  background-position: center;
  +below(1160px)
    width: 100%
  +below($tablet)
    background-color: #dbdbdb
    background-image: none;

.tretti-offer-lp__top-banner-header
  color: #fff
  text-align: center;
  font-size: 3.4rem
  padding: 80px 0
  margin: 0
  span
    text-decoration: underline;
  +below($desktop)
    font-size: 2.4rem
    padding: 30px 0
  +below($mobile)
    font-size: 2rem

.tretti-offer-lp__top-grid
  lost-center 99%

.tretti-offer-lp__top-grid-item
  lost-column 1/4 4 $tretti_offer_lp_gutter
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content:flex-end;
  background-color: #fff
  height: 190px;
  box-shadow: 0 0 7px 0px #888
  margin-bottom: $tretti_offer_lp_gutter
  transition: all 150ms ease-in
  +below($desktop)
    lost-column 1/2 2 $tretti_offer_lp_gutter
  +below($mobile)
    lost-column 1/1 1 $tretti_offer_lp_gutter
  &:hover
    transform scale(0.98)
  +below($mobile)
    height: 140px
  img
    margin-bottom: 36px
    +below($mobile)
      margin-bottom: 10px
    //filter: grayscale(100%)

.tretti-offer-lp__top-grid-label
  background-color: $brand_color_variation
  width: 100%
  text-align: center;
  padding: 8px 0
  font-size: 1.1rem
  text-transform: uppercase;
  letter-spacing: .5px
  color: #ffffff

.tretti-offer-lp__header
  font-size: 2.1rem
  font-family: $font_family_header
  text-transform: uppercase;
  margin-bottom: 30px
  line-height: 1.2
  span
    color: $brand_color
    text-decoration: underline;
  +below($tablet)
    font-size: 1.8rem
  +below($mobile)
    font-size: 1.5rem
  .tretti-offer-lp__dark-wrapper &
    color: #fff

.tretti-offer-lp__grid
  lost-center 100%

.tretti-offer-lp__grid-item
  lost-column 1/4 4 $tretti_offer_lp_gutter
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: $tretti_offer_lp_gutter
  transition: all 150ms ease-in
  +below($desktop)
    lost-column 1/3 3 $tretti_offer_lp_gutter
  +below($tablet)
    lost-column 1/2 2 $tretti_offer_lp_gutter
  img
    width 100%
  &:hover
    text-decoration: none;
    transform scale(0.98)
  &.text
    background-color: $brand_color
    img
      display: none

.tretti-offer-lp__grid-label
  font-family: $font_family_header
  font-size: .83rem
  position: absolute;
  color: #ffffff
  bottom: 0
  left: 0
  line-height: 0
  padding: 13px 8px;
  text-transform: uppercase;
  background-color: $brand_color_variation
  +below($tablet)
    font-size: .7rem
  .text &
    position: relative
    background-color: $brand_color
    font-size: 1.3rem
    +below($tablet)
      font-size: .875rem

.tretti-offer-lp__dark-wrapper
  background-color: #444
  margin-top: 90px;
  padding: 80px 0 50px 0
  +below($mobile)
    margin-top: 50px
    padding: 50px 0 50px 0

.tretti-offer-lp__color-boxes
  lost-center 100%
  margin-top: 60px;
  margin-bottom: 60px;

.tretti-offer-lp__color-box
  display: flex;
  height: 200px
  align-items: center;
  justify-content: center;
  transition: opacity 150ms ease-in
  background-size: cover;
  background-position: center;
  &:hover
    text-decoration: none;

.tretti-offer-lp__color-box
  lost-column 1/3 3 5px
  transition: all 150ms ease-in
  +below($tablet)
    lost-column 1/2 1 5px
  &:hover
    transform scale(0.98)
  &.pink
    background-color: #f662aa
    +below($tablet)
      display: none
  &.blue
    background-color: #42b0e5
  &.yellow
    background-color: #f6fa6f
  span
    color: #fff
    font-family: $font_family_header
    font-size: 2.4rem;
    line-height: 1
    font-weight: normal;
    margin-top: -6px;
    text-align: center;
    text-shadow:
       3px 3px 0 #000,
     -1px -1px 0 #000,
      1px -1px 0 #000,
      -1px 1px 0 #000,
       1px 1px 0 #000;
    transform: rotate(-15deg);
    +below($tablet)
      font-size: 1.5rem

.tretti-offer-lp__disclaimer
  color: #a7a7a7
  display: flex;
  justify-content: center;
  margin-top: 30px
  text-align: center;
