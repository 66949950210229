// Fade transition
.fade-enter, .fade-leave-active
  transition opacity .25s ease-out
  opacity 0

.fade-enter-active
  transition opacity .25s ease-out

// Slide left transition
.slide-enter, .slide-leave-active
  transition all 0.35s ease-out
  margin-left -100vw !important

.slide-enter-active
  transition all 0.25s ease-out

// List fade
.list-fade-enter, .list-fade-leave-active
  transition all 0.5s ease
  opacity 0
  transform scale(0.95)

.list-fade-enter-active
  transition all 0.5s ease

.list-fade-leave-active
  position absolute

.list-fade-move
  transition all 0.5s ease-out

// Pop transition
.pop-enter, .pop-leave-active
  transition all 2.5s $easeInBack
  transform scale(0.3)
  opacity 0

.pop-enter-active
  transition all .25s $easeOutBack
