.cof__saldo-lookup
  display flex
  margin-right 30px
  padding 20px 0
  a
    display: flex;
    align-items: center;
  +below($tablet)
    padding: 9px 12px;

.saldo-lookup__input-wrap
  width 200px

.saldo-lookup__data-wrap
  z-index 2 
  display flex
  flex-direction column
  width 200px
  left 0
  right 0
  width 720px
  overflow auto
  background #fff
  position fixed
  margin 0px auto
  box-shadow $cof_section_shadow
  border-radius 4px;
  padding 20px
  .kreditmax-title__text
    font-weight: bold
    .saldo-lookup-kreditrest__text 
      float right
      .kreditrest-normal__text
        font-weight: normal
  .saldo-lookup__text
    float right
  span:not(:first-child)
    border-top $cof_section_border_darker
    padding 5px  
  span:nth-child(2n)
    background #f2f2f2
  span:nth-child(4)
    border-top: solid 1px #000
  span:nth-child(5)
    border-top: solid 1px #000
  .saldo-lookup__close
    color $cof_section_footer_label_color
    float right
    font-size 28px
    font-weight bold
    display inline-block
    right 0
    position absolute
    margin-top -10px
    top 2px

  .saldo-lookup__close:hover
  .saldo-lookup__close:focus 
    color #000
    text-decoration none
    cursor pointer
