.vip__superspecs__outer
  sectionOuter(#fff)
  padding-bottom: 0
  +below($desktop)
    display: none

.vip__superspecs
  sectionInner($vip_inner_section_border_color)

.vip__superspecs__container
  lost-flex-container row

.vip__superspecs__spec
  margin-bottom: 25px;
  lost-column 1/2 2 30px
  justify-content space-between
  display flex
  +below($desktop)
    lost-column 1/1
  +below($mobile)
    flex-direction: column;

.vip__superspecs__image
  margin-right 18px
  width 40%
  +below($mobile)
    width 100%
    margin: 0
  img
    width: 100%
    +below($mobile)
      margin-bottom: 15px

.vip__superspecs__text
  width 60%
  +below($mobile)
    width 100%
  h3
    margin-top 0
    margin-bottom: 0;
    font-size: 1.2rem;
    color: $brand_color
    line-height: 1
    margin-bottom: 5px

.vip__superspecs__expandable-trigger
  color: $anchor_text_color
  margin: 5px 0 15px 0
  cursor: pointer
  display: flex;
  align-items: center;

.vip__superspecs__expandable-block
  width 100%
  visibility: hidden;
  max-height: 0
  opacity: 0;
  display: flex;
  flex-wrap: wrap;
  transition:all 0.3s ease-in;
  overflow:hidden;
  &.show-section
    opacity: 1;
    visibility: visible;
    max-height: 100%

.vip__superspecs__plus-icon
  font-size: 1.4rem;
  border: 1px solid #3f92da;
  padding: 0 3px;
  line-height: 1;
  display: inline-block;
  border-radius: 6px;
  margin-right: 6px
