.vip__demo-cta
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom-left-radius: 6px
  border-bottom-right-radius: 6px
  padding: 5px 20px
  background: #b1b1b1;
  color: #fff;
  +below($mobile)
    padding: 5px 14px
    font-size: .875rem
  svg
    box(20px, 16px)
    fill #fff
    margin-right: 5px
  strong
    margin: 0 5px
