$openTransitionTime = 700ms
$revealTransitionTime = 300ms
$delay = 100ms
$mobile_break = 720px

.vip__accessories__overlay
  position: fixed;
  top 0
  bottom: 0
  left 0
  right: 0
  background-color: rgba(0,0,0,0)
  transition: background-color $revealTransitionTime ease-in
  display: none
  z-index: $accessories_overlay
  pointer-events: none;
  &.show-confirmation
    display: flex;
  &.show-dialog
    background-color: rgba(0,0,0,.6)
    pointer-events: all;
  &.close-dialog
    background-color: rgba(0,0,0,0)
    pointer-events: none;

.vip__accessories
  display flex
  flex-direction column
  max-height 80%
  height auto
  overflow: hidden;
  position: absolute;
  background-color: $success_color
  opacity: 0
  padding: 0;
  transition: all $openTransitionTime cubic-bezier(0.645, 0.045, 0.355, 1), opacity $revealTransitionTime
  &.show-confirmation
    opacity: 1
  &.show-dialog
    width 700px
    left calc(50vw - 350px)
    top 4vh
    background-color: #fff
    border-radius: 2px
    box-shadow: 0 5px 30px rgba(0,0,0,.3)
    +below($tablet)
      width 90vw
      height 85vh
      left (50vw - (@width / 2))
      top 4vh
      box-shadow: 0 3px 15px rgba(0,0,0,3)
    +below($mobile)
      height 76vh
      top 4vh
  &.close-dialog
    opacity: 0
    transform scale3d(20%,20%,0)
    pointer-events: none;
    
  .accessory-card
    max-width none
    border none
    overflow-y scroll

  .details
    overflow hidden

    .accessory-card .header
      height auto

    +below($mobile)
      .accessory-card
        padding-bottom 72px

      .accessory-atb
        position absolute
        bottom 0
        left 0
        right 0
        background #ffffff
        padding 12px
        height auto
        border-top 1px solid #E0E0E0

.vip__accessories__overlay__header
  display: flex;
  justify-content: right;
  align-items: right;
  background-color: $success_color
  border-top-left-radius: 2px
  border-top-right-radius: 2px
  border-bottom 1px solid #E0E0E0
  color: #fff
  font-size 1.4rem
  margin-bottom: 0
  padding 8px
  transition: all $openTransitionTime ease
  .show-dialog &
    background-color: #fff
    color: $success_color
    +below($mobile)
      font-size: 1.2rem

.vip__accessories__overlay__close
  display flex
  justify-content center
  align-items center
  opacity: 0
  box(0, 30px)
  cursor: pointer;
  overflow: hidden;
  transition: all $revealTransitionTime ease 200ms
  svg
    fill $dark_gray
    box(16px)
  &.reveal-content
    box(30px)
    opacity: .8

.vip__accessories__overlay__content
  display: flex;
  gap: 24px;
  width 100%
  opacity: 0
  transition: opacity $revealTransitionTime ease 200ms
  smooth-scroll()
  &.reveal-content
    opacity: 1
  +below($tablet)
    flex-direction: column;

.vip__accessories__overlay__content__left
  align-items: center;
  justify-content: center;
  padding: 0 24px;
  text-align: center;

.vip__accessories__overlay__content__right
  text-align: left;

.vip-accessory-overlay-image
  margin-bottom: 24px;

.vip-accessory-overlay-price
  font-size: 1.8rem;
  margin-bottom: 12px;

.vip-accessory-overlay-stock
  font-size: .75rem;
  margin-bottom: 12px;

.vip-accessory-overlay-title
  font-size: 1.8rem;
  font-weight: 800;
  line-height: 32px;
  margin-bottom: 12px;
  text-align: left;

.vip-accessory-overlay-description
  font-size: .9rem;
  line-height: 20px;
  text-align: left;
