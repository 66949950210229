
.main-nav
  background: $navigation_background_color
  height: $nav_height
  .brand_skousen &
    background-color $brand_color
  +below($desktop)
    display: none;

.main-nav__inner
  lost-center $page_width
  justify-content: space-between
  align-items: center;
  height: 100%
  position: relative;
  .brand_skousen &
    background: radial-gradient(circle at 100% 86%, #ffe600 0%, #ffe600 14%, #b20100 14.1%);
  +below($wide)
    padding: 0 20px

ul.menu-items
  zero-list()
  display: flex;
  align-items: stretch;
  lost-column 0.80
  height: 100%

.menu-items__item
  display: flex;
  align-items:stretch;
  position: relative;
  font-size: $navigation_font_size_desktop
  +below($wide)
    font-size: $navigation_font_size_touch
  a
    color: $navigation_text_color
    text-decoration: none;
    display: flex
    align-self:stretch;
    align-items: center;
    padding: 0 16px
    white-space: nowrap;
    &:hover
      color alpha($navigation_text_hover_color, .8)
      span
        &::after
          width: 100%
    .shop_LPH &
      +between($desktop + 1, $wide)
        padding: 0 18px
    +below($wide)
      padding 0 15px
  span
    pointer-events: none;
    &::after
      content: ""
      display: flex
      height: 2px
      background: alpha($navigation_text_hover_color, .8)
      width: 0
      transition: width .2s;

  &:first-child
    margin-left: -20px

  .shown
    color alpha($navigation_text_hover_color, .8)
    &:after
      content: ''
      display: block;
      box(0)
      border: 12px solid transparent
      border-bottom-color: #fff;
      position: absolute;
      bottom: 0
      left 50%
      margin-left: -12px
      z-index: $mega_menu_Z + 2

.menu-items__offers-item
  display: flex;
  align-items: center;
  margin-left: 16px
  white-space: nowrap;
  .shop_LPH &
    +between($desktop + 1, $wide)
      margin-left 15px
  .brand_skousen &
    position: absolute;
    right: 0
  +below($wide)
    margin-left 5px
  a
    display: block;
    &:hover
      text-decoration: none;


.menu-items__offers-link span
  display: block;
  padding: 4px 12px
  background-color: $offer_background_color
  color: $offer_text_color
  border-radius: 3px
  font-weight: bold;
  width auto !important
  .shop_SE &
    background-color: $offer_background_color_se
    color: $offer_text_color_se
    &:hover
      color: $offer_text_color_se
  .brand_skousen &
    background-color: transparent
    color: #242424
    font-size: 16px
    padding: 0 40px 0 0
    height: 48px
    border-radius: 0
    display: flex;
    justify-content: center;
    align-items: center;
  &:hover
    text-decoration: none;
    color: $offer_text_color

.menu-items__brands-link:hover
  color alpha($navigation_text_hover_color, .8)
