.altapay.container
  //Custom Altapay button
  .AltaPaySubmitButton
    display: none !important;

  .CustomAltaPaySubmitButton
    float right
    font-size 17px
    border 0
    color #fff
    padding 12px 16px
    border-radius 5px
    +below($tablet)
      width 100%
      margin-bottom 14px

  #submitbutton
    float right
    font-size 17px
    border 0
    color #fff
    padding 12px 16px
    border-radius 5px
    margin-top 35px
    margin-right -15px
    +below($tablet)
      width 100%
      margin-right 0
      margin-bottom 14px

  .CustomAltaPaySubmitButton:disabled
    opacity .5
    cursor not-allowed

  .pensioPaymentCancelButton
    font-size 14px
    background #999
    border 0
    color #fff
    padding 8px 12px
    border-radius 5px
    +below($tablet)
      width 100%
      margin-bottom 14px
