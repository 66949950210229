.wag-row
  lost-center $page_width
  margin-bottom: 20px

.wag-col-1-1
  lost-column 1

.wag-col-1-2
  lost-column 1/2
  +below($tablet)
    lost-column 1
    margin-bottom: 20px;
    &:last-child
      margin-bottom: 0

.wag-col-1-3
  lost-column 1/3
  +below($tablet)
    lost-column 1
    margin-bottom: 20px;
    &:last-child
      margin-bottom: 0

.wag-col-1-4
  lost-column 1/4
  +below($tablet)
    lost-column 1/2
    margin-bottom: 20px;
    &:nth-child(3),
    &:nth-child(4)
      margin-bottom: 0

.wag-col-1-1,
.wag-col-1-2,
.wag-col-1-3,
.wag-col-1-4
  img
    margin: auto;
    display: block
