.vip__addition-info__outer
  sectionOuter($vip_section_background_color)
  +below($desktop)
    display: none

.vip__addition-info
  sectionInner($vip_inner_section_border_color)
  font-size: 1rem;

.vip__addition-info__inner
  max-width: 700px
