.ojw__landingpage-outer
  background-color: #8b8b8b;
  padding-bottom: 20px;

.ojw__landingpage-banner
  width: 1500px
  margin: 0 auto -20px auto;
  position: relative;
  +below(1500px)
    width: 100%;
  span
    position: absolute;
    right: 20px;
    bottom: 20px;
    color: #fff;
    text-shadow: 0px 0px 2px 2px #333
    +below($tablet)
      right: 5px;
      bottom: 5px;

.ojw__landingpage-divider
  lost-center: $page_width;
  margin-top: 20px;
  +below($desktop)
    width: 100%;

.ojw__landingpage-grid
  section
    lost-center: $page_width;
    margin-top: 20px;
  .col-1-2
    lost-column: 1/2;
    +below($mobile_landscape)
      lost-column: 1;
      &:first-child
        margin-bottom: 20px;
  .col-2-3
    lost-column: 2/3;
    +below($mobile_landscape)
      lost-column: 1
  .col-1-3
    lost-flex-container: row;
    lost-column: 1/3;
    +below($mobile_landscape)
      lost-column: 1
  .row-1-2
    lost-column: 1 1;
    &:first-child
      margin-bottom: 20px;
    +below($mobile_landscape)
      lost-column: 1;
  .col-first
    +below($mobile_landscape)
      margin-bottom: 20px;
  img
    transition: all 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
    height: 100%;
    width: 100%;
    display: block;
    &:hover
      transform:scale(1.03);
