.cof__accessories-dialog-overlay
  position: fixed;
  left: 0
  top: 0
  width: 100%
  height: 100%
  z-index: $accessories_overlay
  background-color: rgba(0,0,0,.6)

.cof__accessories-dialog
  overflow: hidden;
  position: absolute;
  width 700px
  height 80vh
  left calc(50vw - 350px)
  top 4vh
  background-color: #fff
  border-radius: 2px
  box-shadow: 0 5px 30px rgba(0,0,0,.3)
  +below($tablet)
    width 90vw
    height 85vh
    left (50vw - (@width / 2))
    top 4vh
    box-shadow: 0 3px 15px rgba(0,0,0,3)
  +below($mobile)
    height 76vh
    top 4vh
  .in-progress &
    pointer-events: none;

.cof__accessories-dialog-inner
  overflow-y: scroll;
  height: calc(80vh - 56px);
  +below($tablet)
    height: calc(85vh - 56px);
  +below($mobile)
    height: calc(76vh - 56px);

.cof__accessories-dialog-header
  display: flex;
  align-items: center;
  height: 56px
  border-bottom: solid 1px rgba(0,0,0,.2)
  padding-left: 20px;
  h2
    margin: 0

.cof__accessories-dialog-close
  position: absolute;
  right: 17px
  top: 3px
  font-size: 2.2rem
  cursor:pointer;
  &:hover
    color: rgba(0,0,0,.5);

.cof__accessories-dialog-item
  display: flex;
  flex-wrap: wrap;
  border-bottom: solid 1px rgba(0,0,0,.1)
  padding: 10px 20px

.cof__accessories-dialog-item__img
  width: 16%;
  display: flex;
  justify-content: center;
  align-items: center;

.cof__accessories-dialog-item__text
  width: 64%
  padding-left: 14px;
  padding-right: 14px;
  +below($tablet)
    width: 84%;
    padding-right: 0;
    margin-bottom: 5px;
  h3
    truncate()
    margin-top: 0
    margin-bottom: 5px
    font-size: 1rem

.cof__accessories-dialog-item__info
  font-size: .75rem
  max-height: 101px
  overflow: hidden
  transition: max-height 600ms ease
  &.show-more
    max-height: 600px;

.cof__accessories-dialog-item__more
  font-size: .75rem
  &.hide-button
    display: none

.cof__accessories-dialog-item__less
  font-size: .75rem
  display: none
  &.show-button
    display: block

.cof__accessories-dialog-item__price
  position: relative;
  width: 20%;
  display: flex;
  flex-direction: column;
  +below($tablet)
    width: 100%;
  .price
    font-size: 1.5rem
    text-align: right;
    margin-bottom: 10px
  .stock-status
    font-size: .65rem
    text-align: right;
    margin-bottom: 10px

.cof__accessories-dialog-item__button
  *
    pointer-events none
  .added-overlay
    display: flex
    align-items: center;
    justify-content: center;
    opacity: 0
    position: absolute;
    transition: all 400ms ease-in-out
    top: 0
    left: 0
    width: 100%;
    height: 0
    svg
      box(28px)
      stroke #fff
      stroke-width 2.5
      margin-left: -15px
      margin-top: -3px
  &.added
    .added-overlay
      opacity: 1
      height 100%;
      background: $action_color


.cof__accessories-dialog__no-acc
  font-size: 1.5rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content:center;
  text-align: center;
  margin-top: 10%;
