.cof__payment
  background-color: $cof_section_bg

.cof__payment-cards
  padding: 35px 50px
  border-left $cof_section_border
  border-right $cof_section_border
  width: 100%
  +below($page_width)
    padding: 30px
  +below($mobile)
    padding: 30px 8px

.cof__payment-cards-header
  lost-center $cof_center_col_width
  text-transform: uppercase;
  font-size: .7rem
  justify-content: space-between;
  border-bottom: $cof_section_border
  color rgba(0,0,0,0.4)
  padding-bottom: 8px


.cof__payment-cards-list
  zero-list()
  lost-center 575px
  flex-direction: column;
  margin-top: 35px
