//Inhouse navigation
.inhouse__nav
  position: fixed;
  left: 10px;
  top: 10px;
  z-index: 400;
  font-size: 1rem;

.inhouse__nav__trigger
  display: inline-flex;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.15);
  padding: 6px 6px 6px 12px;
  border-radius: 4px;
  cursor: pointer;
  background: #156ee0;
  user-select: none;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0,0,0,.2);

  &:hover
    background: darken(#156ee0, 20%);

  &.show-inhouse-nav
    background: darken(#156ee0, 20%);
    box-shadow: inset 0px 0px 2px 2px rgba(0, 0, 0, 0.1);

    svg
      transform: rotate(90deg);

  svg
    box(25px, 16px);
    transition: all 300ms;
    fill: #fff;


.inhouse__nav__list
  display: none;
  zero-list()
  margin: 5px 0 0 1px;
  padding: 0;
  &.show-inhouse-nav
    display: block;

  li
    display block
    position relative

  li ul
    zero-list()
    display: none;
    list-style-type: none;

  li:hover > ul
    display: block;
    position absolute;

  ul
    left: 100%;
    top: 0;

  a
    display: block;
    text-decoration: none;
    white-space: nowrap;
    padding: .6em 1em;
    margin-bottom: -1px;
    margin-left: -1px;
    background-color: #fff;
    border: 1px solid #ddd;
    color: $body_text_color
    font-size: .9rem

  a:hover
    background: #f5f5f5;

  svg
    box(10px)
    margin-left: 6px;
