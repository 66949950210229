.fp__appetizers-row
  lost-center $page_width
  font-size 0
  margin-bottom: 55px;
  +below($wide)
    width: 100%;
    padding: 0 30px;
  +below($desktop)
    padding 0 20px
  +below($mobile)
    padding 0 10px

.fp__appetizers-appetizer
  position: relative;
  lost-column: $fp-appetizers-col-desktop;
  box-shadow $fp-appetizers-shadow
  transition: transform 200ms ease-in
  img
    width: 100%;
  +below(900px)
    lost-column 1/2
    margin-bottom: 20px;
    .brand_tretti &
      margin-bottom 55px
  +below($mobile)
    lost-column 1
    margin-bottom: 10px !important;
    .brand_tretti &
      margin-bottom 45px !important
  &:hover
    transform: scale(1.07);

.tretti__appetizer-label
  position: absolute
  display: flex
  background: $brand_color
  position: absolute
  align-items: center
  justify-content: center
  height: $fp-appetizers-lbl-height
  font-size: $fp-appetizers-lbl-font-size
  font-family: $fp-appetizers-lbl-font-family
  font-weight $fp-appetizers-lbl-font-weight
  color: #fff
  width: 100%
  text-align: center
  text-transform: $fp-appetizers-lbl-text-transform

.fp__appetizers-appetizer-label
  background: $fp-appetizers-lbl-background
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: $fp-appetizers-lbl-height
  font-size: $fp-appetizers-lbl-font-size
  font-family: $fp-appetizers-lbl-font-family
  font-weight $fp-appetizers-lbl-font-weight
  color: #fff;
  opacity $fp-appetizers-lbl-opacity
  width: 100%;
  text-align: center;
  text-transform: $fp-appetizers-lbl-text-transform
  span
    text-align center
    truncate()
    width: 100%;
    padding 0 5px
