.srp-header
  display flex
  justify-content space-between
  flex-wrap wrap
  max-width $page_width
  margin 0 auto 10px auto
  +below($desktop)
    flex-direction column
    margin-bottom 0
    transition: all 0.2s ease-out
    &.filters-present
      margin-bottom 10px

.srp-header__title
  standardSectionPadding()
  h1
    margin 0
    margin-right: 8px
    line-height: 1.2
    +above($desktop)
      margin-bottom: 5px;
  span
    margin 0
    color #aaa
    font-size 1.2rem
    font-family: $font_family_base
    font-weight: normal;
    +below($desktop)
      font-size .9rem
    +below($tablet)
      font-size .8rem
    +below($mobile)
      font-size .7rem
  +below($desktop)
    margin-bottom 15px
    margin-top: 5px;

.srp-header__options
  standardSectionPadding()
  display flex
  align-items center
  justify-content space-between
  transition: all .3s ease

.options__filter-button
  display none
  +below($desktop)
    display block
    line-height 40px
    min-width: 100px

.srp-header__options-right
  display flex

.options__sorting-wrap
  position relative
  span
    margin-right 10px
    +below($mobile)
      display none
  select
    appearance none
    padding 0px 35px 0px 15px
    max-width 200px
    height 34px
    border-radius $options_sorting_border_radius
    border 1px solid $options_sorting_border_color
    background-color: $options_sorting_background_color
    color: $options_sorting_text_color
    cursor pointer
    font-size: 1rem
    &::-ms-expand
      display: none;
    &.disabled
      opacity: .5
      pointer-events: none;
    +below($tablet)
      padding 0px 30px 0px 15px
      height 40px

  svg
    box(15px)
    position absolute
    top 9px
    right 12px
    pointer-events: none;
    fill: $options_sorting_icon_color
    transform: rotate(90deg);
    +below($tablet)
      top 10px
      right 10px

.options__grid-or-list-wrap
  display flex
  margin-left 15px
  &.disabled
    opacity: 0.5
  +below($tablet)
    display none

.list-or-grid__grid-button,
.list-or-grid__list-button
  width 42px
  border 1px solid $grid_changer_border_color
  background-color: $grid_changer_background_color
  cursor pointer
  transition: all 0.25s ease-out
  .disabled &
    cursor default
  &:hover
    background-color $grid_changer_background_color
  &.active
    background-color $grid_changer_active_background_color
    cursor default
    &:hover
      background-color $grid_changer_active_background_color
    div
      background-color #fff
      &:before,
      &:after
        background-color #fff


.list-or-grid__grid-button
  border-top-left-radius $grid_changer_border_radius
  border-bottom-left-radius $grid_changer_border_radius
  padding $grid_changer_grid_padding
  div
    box(6px, 4px)
    position relative
    background-color $grid_changer_icon_color
    margin-bottom 1px
    &:before,
    &:after
      content ''
      box(6px, 4px)
      position absolute
      background-color $grid_changer_icon_color
    &:before
      top 0
      left 8px
    &:after
      top 0
      left 16px


.list-or-grid__list-button
  border-top-right-radius $grid_changer_border_radius
  border-bottom-right-radius $grid_changer_border_radius
  padding $grid_changer_list_padding
  div
    circle(3px)
    position relative
    background-color $grid_changer_icon_color
    margin-bottom 2px
    &:after
      content ''
      box(16px, 3px)
      position absolute
      top 0
      left 6px
      background-color $grid_changer_icon_color

.srp-header__active-filters
  display none
  standardSectionPadding()
  +below($desktop)
    margin 15px 0px 0px
    position relative
    display flex
    flex-wrap: wrap;
    align-items flex-start
    transition: all .3s $easeOutBack
    &.delay
      transition-delay: .2s
