.cof__empty-basket
  display flex
  flex-direction column
  align-items center
  justify-content center
  margin 50px 0
  h2
    margin 30px 0 50px

.cof__empty-basket__icon
  svg
    fill $neutral_dark_color
    box(100px)
