.srp.blueocean
  background-image:url('//images.wagcdn.com/f/b2b/blueocean.jpg');
  background-repeat: repeat-x;

.price-section__b2b-price
  font-size 1.75rem
  font-family: $srp_product_box_price_font
  &.k1-prod
    color blue

.price-section__b2b-price-online,
.price-section__b2b-stock-status
  color: $brand_color

.price-section__b2b-price-description
  color $b2b_extra_info_color

.price-section__b2b-price-kassortment.k0-prod
  color $b2b_k0_color
  font-weight: bold

.price-section__b2b-price-kassortment.k1-prod
  color $b2b_k1_color
  font-weight: bold

.price-section__b2b-price-kassortment.k2-prod
  color $b2b_k2_color
  font-weight: bold
