.srp__recommendations
  background-color: #ececec
  box-shadow: 0 5px 7px 0 #ccc;
  padding: 10px 0

.srp__recommendations-header
  lost-center $page_width
  justify-content: center
  h2
    font-size: 1.7rem
    margin: 0

.srp__recommendations-wrap
  lost-center $page_width
  +below($wide)
    padding: 0 15px
