.vip__accordion-wrapper
  +below($desktop)
    display: none;

.vip__accordion:nth-child(1)
  border-top 1px solid $gray_border

.vip__accordion
  border-bottom 1px solid $gray_border
  svg:first-child
    box(28px)
    fill $brand_color
    margin-right 15px
    min-width 30px

.vip__accordion__accordion-content
  overflow hidden
  max-height 0

.vip__accordion__accordion-content-inner
  padding 0 10px 20px 10px

.vip__accordion__accordion-campaign-text
  display: flex
  .vip__usps__watermark
    margin-top: 10px
    min-width: 100px
    margin-left: 10px
    display: flex;
    align-items: center;
    justify-content: flex-end;

.vip__accordion__insurance-text
  display flex
  justify-content space-between

.vip__accordion__insurance-price
  font-weight 600

.vip__accordion__header-image
  display flex

.vip__accordion__header
  display flex
  align-items center
  padding 10px
  cursor pointer
  width 100%
  text-align left
  border none
  outline none
  transition 0.3s

.vip__accordion__header-wrapper
  width 90%

.vip__accordion__additional-text
  color $in_stock_color
  right 90px

.vip__accordion__header-title
  font-weight 600
  min-width 90px
  display inline-block

.vip__accordion__header-text
  flex-grow 1
  margin-right: 10px;
  .in-stock
    color: $in_stock_color
  &.offer-code
    text-align: center;
    background: #c8c8c8;
    padding: 5px;
    flex-grow: 1;

.vip__accordion__expander
  cursor: pointer;
  box(12px)
  position: relative;
  top: 4px
  &:before,
  &:after
    background: #333
    content: '';
    height: 2px;
    left: 0;
    position: absolute;
    top: 0;
    width: 12px;
    transition: transform 500ms ease;
    transform-origin: center;
  &:after
    transform: rotate(90deg);
    .active &
      transform: rotate(-180deg);
  &:before
    transform: rotate(180deg);
    .active &
      transform: rotate(-180deg);
