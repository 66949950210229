.altapay__gateway
  background-color: $cof_bg

  .pensio_payment_form_submit_cell
    display: none;

  .pensio_payment_form_row
    display: flex;
    margin-bottom: 14px;
    align-items: center;
    +below($tablet)
      align-items: flex-start;
      flex-direction: column

  .pensio_payment_form_label_cell
    color rgba(0,0,0,0.4)
    font-size: .9rem
    width: 140px
    truncate()

  .pensioCreditCardInput
    border $cof_section_border
    height 45px
    padding: 4px 18px
    background-color #fff
    min-width: 76px

  .cvc_description_row
    position: absolute;
    left: 262px;
    top: 148px;
    +below($tablet)
      left: 122px;
      top: 210px;
    .pensio_payment_form_label_cell
      display: none;
