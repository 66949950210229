.ojt__landingpage-outer
  padding: 20px 0;

.ojt__landingpage-banner-desktop
  width: $page_width
  margin: 0 auto;
  position: relative;
  +below($page_width)
    width: 100%;
  +below($phablet)
    display none;
  span
    position: absolute;
    right: 20px;
    bottom: 20px;
    color: #fff;
    text-shadow: 0px 0px 2px 2px #333
    +below($tablet)
      right: 5px;
      bottom: 5px;

.ojt__landingpage-banner-mobile
  width: 100%
  margin: 0 auto;
  position: relative;
  +above($phablet)
    display:none;

.ojt__landingpage-grid
  section
    lost-center: $page_width;
    margin-top: 20px;
  .col-1-2
    lost-column: 1/2;
    +below($mobile_landscape)
      lost-column: 1;
      &:first-child
        margin-bottom: 20px;
  .col-2-3
    lost-column: 2/3;
    +below($mobile_landscape)
      lost-column: 1
  .col-1-3
    lost-flex-container: row;
    lost-column: 1/3;
    +below($mobile_landscape)
      lost-column: 1
  .row-1-2
    lost-column: 1 1;
    &:first-child
      margin-bottom: 20px;
    +below($mobile_landscape)
      lost-column: 1;
  .col-first
    +below($mobile_landscape)
      margin-bottom: 20px;
  img
    transition: all 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
    height: 100%;
    width: 100%;
    display: block;
    &:hover
      transform:scale(1.03);
