
progress-bar()
  content: ''
  position: absolute;
  display: block;
  left: 10px
  top: 50%
  height: 18px
  margin-top: -9px

.cof-step-wrap
  margin: 40px auto 60px
  width: 800px
  top: 0
  position: relative
  +below($desktop)
    width: 80vw
    margin: 20px auto 10px
  &:before
    progress-bar()
    right: 10px
    background-color: #e9e9e9
    z-index: -2
  
  &:after
    progress-bar()
    z-index: -1
  &.step-1:after
    right: 100%
  &.step-1-half:after
    animation: scroll79 2s ease
    right: 79%
  &.step-2:after
    animation: scroll66 2s ease
    right: 66%
  &.step-3:after
    animation: scroll33 2s ease
    right: 33%
  &.step-4:after
    animation: scroll0 2s ease
    right: 10px
  +below($mobile)
    &:before, &:after
      display: none;

@keyframes scroll79 {
  from  {right: 100%}
  to    {right: 79%}
}
@keyframes scroll66 {
  from  {right: 79%}
  to    {right: 66%}
}
@keyframes scroll33 {
  from  {right: 66%}
  to    {right: 33%}
}
@keyframes scroll0 {
  from  {right: 33%}
  to    {right: 0%}
}

@keyframes flashStep {
  from {
    background-color: #e9e9e9
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    background-color: $brand_color
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    }
}

.step-1-half.cof-steps__step.active
  background-color: $brand_color

.cof-steps
  zero-list()
  display: flex;
  justify-content: space-between;
  align-items: center;
  counter-reset: (step)

.cof-steps__step
  circle(54px)
  border: solid 4px #fff
  background-color: #e9e9e9
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center

  &.clickable
    cursor: pointer
  &:before
    counter-increment: step
    content: counter(step)
    font-size: 20px
    line-height: 46px
  &.active
    background-color: $brand_color
    circle(74px)

    &.animated
      background-color: #e9e9e9

      animation: flashStep 1s ease 1.5s
      -webkit-animation-fill-mode: forwards;
      -moz-animation-fill-mode: forwards;
      -o-animation-fill-mode: forwards;
      -ms-animation-fill-mode: forwards;
      animation-fill-mode: forwards;

    &:before
      color #fff
      font-size: 34px
      line-height: 66px
  &.completed
    &:before
      color #fff
  +below($desktop)
    circle(50px)
    &:before
      line-height: 43px
      font-size: 18px
    &.active
      circle(50px)
      &:before
        line-height: 43px
        font-size: 18px

.cof-steps__label
  white-space: nowrap;
  margin-top: 6px
  +below($desktop)
    display: none;
