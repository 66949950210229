squareTrigger()
  box(12px)
  border solid 1px #999
  border-radius: 2px
  &:before, &:after
    background-color: #999

roundTrigger()
  circle(14px)
  background-color: #ccc
  &:before, &:after
    background-color: #fff

trigger()
  cursor: pointer
  position: relative
  display: flex
  transition: transform 200ms ease-out
  &:before
    content: ''
    position: absolute
    left 50%
    margin -4px 0 0 -1px
    top 50%
    display: block
    box(2px, 8px)
  &:after
    content: ''
    margin: auto
    display: block
    box(8px, 2px)
  +below($mobile)
    margin-left 10px

.cof__expandable-item
  display: flex
  justify-content: space-between;
  align-items: center;
  border solid 1px #ccc
  border-radius $cof_service_border_radius
  width: 100%
  padding: 12px 20px
  margin-bottom: 10px
  background-color: rgba(250,250,250,1)
  font-size .875rem
  white-space: nowrap;
  color $cof_li_item_font_color
  transition: margin-bottom 300ms ease 500ms
  &:hover
    cursor: pointer;
  input
    margin-right: 10px
  &.checked
    border 1px solid $brand_color_variation
    box-shadow: inset 0px 1px 5px rgba(0,0,0,0.2), 0 0 1px 0 $brand_color_variation
  &.expanded
    margin-bottom: 0
    border-bottom-left-radius: 0
    border-bottom-right-radius: 0
    transition: margin-bottom 100ms ease 0ms
  +below($mobile)
    padding: 12px 15px
    span, p
      display: block
      max-width: 200px
      truncate()


.cof__expandable-item-info-trigger
  trigger()
  .expanded &
    transform: rotate(180deg);
    &:before
      opacity 0

.brand_whiteaway .cof__expandable-item-info-trigger
  roundTrigger()
body:not(.brand_whiteaway) .cof__expandable-item-info-trigger
  squareTrigger()

.cof__expandable-item__icon
  box(60px, 40px)
  margin-right 15px
  vertical-align middle
  +below($mobile)
    box(40px, 27px)
    margin-right 10px

.cof__expandable-item__description
  max-width 330px
  white-space normal
  font-size 0.75rem
  margin-top 5px
  opacity .8
  line-height 1.25
  +below($tablet)
    max-width 250px
  +below($mobile)
    max-width 200px
  +below(420px)
    display none

.cof__expandable-item__price
  flex-grow: 2
  text-align right
  .service-cards-disabled &
    display none

.cof__expandable-item-details
  background-color: rgba(245,245,245,.8)
  color $cof_service_details_font_color
  padding: 15px 0
  border solid 1px #ccc
  border-top: none
  margin-bottom: 10px
  font-size .875rem

.expandable-item-details__text-block
  padding: 0 20px
  ul
    list-style: disc
    padding-left 20px
  li
    margin-bottom 5px
