$openTransitionTime = 700ms
$revealTransitionTime = 300ms
$delay = 100ms
$mobile_break = 720px

.accessories-close-btn
    font-size: 32px;
    position: absolute;
    color: black;
    right: 15px;
    top: 0px;
    cursor pointer

.insurance-pop__overlay
  position: fixed;
  top 0
  bottom: 0
  left 0
  right: 0
  background-color: rgba(0,0,0,0)
  transition: background-color $revealTransitionTime ease-in
  display: none
  z-index: $accessories_overlay
  pointer-events: none;
  &.show-confirmation
    display: flex;
  &.show-dialog
    background-color: rgba(0,0,0,.6)
    pointer-events: all;
  &.close-dialog
    background-color: rgba(0,0,0,0)
    pointer-events: none;

.insurance-pop
  overflow: hidden;
  position: absolute;
  background-color: $success_color
  opacity: 0
  height: 55px
  transition: all $openTransitionTime cubic-bezier(0.645, 0.045, 0.355, 1), opacity $revealTransitionTime
  &.show-confirmation
    opacity: 1
  &.show-dialog
    width 700px
    height fit-content
    left calc(50vw - 350px)
    top 4vh
    background-color: #fff
    border-radius: 2px
    box-shadow: 0 5px 30px rgba(0,0,0,.3)
    +below($tablet)
      width 90vw
      left (50vw - (@width / 2))
      top 1vh
      box-shadow: 0 3px 15px rgba(0,0,0,3)
    +below($mobile)
      top 1vh
  &.close-dialog
    opacity: 0
    transform scale3d(20%,20%,0)
    pointer-events: none;

.insurance-pop__header
  max-height 100px
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $success_color
  border-top-left-radius: 2px
  border-top-right-radius: 2px
  color: #fff
  font-size 1.4rem
  transition: all $openTransitionTime ease
  &.reveal-content
    justify-content: flex-start
  .show-dialog &
    padding: 10px 20px;
    background-color: #fff
    border-bottom: solid 1px rgba(0,0,0,.2)
    box-shadow 0 3px 10px -3px rgba(0,0,0,0.3)
    color: $success_color
    height auto !important
    +below($mobile)
      max-height 150px
      height: auto !important
      font-size: 1.2rem
      padding: 10px


.insurance-pop__inner
  height: 100%
  display: flex;
  align-items: center;

.accessories-pop__header__img
  box(0px)
  opacity: 0
  transition: opacity $revealTransitionTime ease $delay
  .show-dialog &
    box(64px)
    +below($mobile)
      box(56px)
  &.reveal-content
    opacity: 1

.accessories-pop__header__label
  line-height 55px
  white-space: nowrap;
  opacity: 0
  max-width: 0
  transition: all $openTransitionTime
  .show-dialog &
    opacity: 1
    margin-left: 20px
    max-width: 400px;

.accessories-pop__tick
  box(30px)
  margin: 10px
  svg
    box(100%)
    stroke #fff
    stroke-width 2.5
    stroke-dasharray: 50
    stroke-dashoffset: 50
    transition: stroke-dashoffset 800ms ease-out 200ms, stroke $revealTransitionTime
  +below($mobile)
    box(42px)
  &.draw
    svg
      stroke-dashoffset: 0
  &.reveal-content
    svg
      stroke: $success_color


.insurance-pop__header__close
  flex-flow column
  opacity: 0
  box(0, 30px)
  overflow: hidden;
  transition: all $revealTransitionTime ease 200ms
  svg
    fill $success_color
    box(16px)
  &.reveal-content
    box(30px)
    opacity: .8
    &.overlay-header-image
        width 100%  !important
        height fit-content  !important
        display flex
        gap 20px
        +below($mobile)
            gap 0px
.overlay-image
  display: flex;
  align-items: center;
  width: 74px
  height: 74px

.accessories-pop__content
  padding 16px
  width 100%
  height auto
  max-height 65vh
  opacity: 0
  transition: opacity $revealTransitionTime ease 200ms
  background #F5F5F5
  overflow-y scroll

  &.reveal-content
    opacity: 1
  #step1
    display: block;
    padding: 20px
    &.hidden
      display: none;
  #insurance-content
    display: block;
    &.hidden
      display: none;

.hidden
  display:none!important;

.insurance__list__heading
  font-size: 1.1rem
  margin: 0
  pointer-events: none;

.insurance__list
  zero-list()
  margin-top: 10px
  border-top: solid 1px rgba(0,0,0,.12)

.insurance__item
  display: flex;
  justify-content: space-between;
  padding: 10px 0
  border-bottom: solid 1px rgba(0,0,0,.12)
  +below($mobile)
    flex-wrap: wrap;
    border-bottom: none

.accessories__item__thumb
  margin-right 15px
  +below($mobile)
    margin-right 0px
    box(65px)

.accessories__item__text-container
  width 450px
  +below($tablet)
    width calc(100% - 95px - 142px)
  +below($mobile)
    width: calc(100% - 75px);

.accessories__item__name
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  +below($mobile)
    font-size: .9rem

.accessories__item__description
  font-size .9rem
  margin-top 14px
  color rgba(0,0,0,.7)
  +below($mobile)
    font-size: .8rem

.accessories__item__price-inner
  text-align: right;
  +below($mobile)
    text-align left

.accessories__item__price
  font-size: 1.4rem
  +below($mobile)
    font-size 1.2rem

.accessories__item__delivery
  text-align: right;
  font-size: .8rem
  color: rgba(0,0,0,0.7)
  +below($mobile)
    text-align left
  .in-stock
    display: none
  .fa
    display: none

.accessories__item-button
  margin-top: 15px
  flex-shrink: 0
  *
    pointer-events none
  > span
    transition: opacity 200ms
    display: inline-block;
  +below($mobile)
    margin: 0
  &.adding
    > span
      opacity: 0
  &.added
    background-color: $success_color
    pointer-events none;
    > span
      opacity: 0

.accessories__item__tick
  position: absolute;
  top 3px
  left calc(50% - 8px)
  svg
    box(22px)
    stroke #fff
    stroke-width 2.1
    stroke-dasharray: 50
    stroke-dashoffset: 50
    transition: stroke-dashoffset 800ms ease-out 200ms, stroke $revealTransitionTime
  .adding &, .added &
    svg
      stroke-dashoffset: 0

.accessories__buttons
  opacity: 0
  background-color: #fff
  display: flex;
  border-top: solid 1px rgba(0,0,0,.2)
  width: 100%
  height 73px
  padding: 15px 0
  justify-content: center;
  align-items: center;
  transition: opacity $revealTransitionTime ease
  .button
    margin: 0 10px
  &.reveal-content
    opacity: 1
    box-shadow 0 -3px 10px -3px rgba(0,0,0,0.3)


.accessories__buttons_second_step
  opacity: 1
  background-color: #fff
  display: flex;
  border-top: solid 1px rgba(0,0,0,.2)
  width: 100%
  height 73px
  padding: 15px 0
  justify-content: center;
  align-items: center;
  transition: opacity $revealTransitionTime ease
  .button
    margin: 0 10px
  &.reveal-content
    opacity: 1
    box-shadow 0 -3px 10px -3px rgba(0,0,0,0.3)



.overlay-header-text
    display flex
    flex-direction column
    justify-content center
    align-self baseline
    width 100%

.overlay-product-name
    align-items baseline
    font-size: 14px
    color black
    display flex
    gap 8px
    span:first-child 
        font-size 16px
    +below($tablet)
        flex-direction column
        gap 0

+mobile()
  .overlay-product-name
    flex-direction row
    gap 12px

  .overlay-image
    display none

  .overlay-header-text
    margin-top 4px
    flex-direction row
    justify-content space-between


.overlay-product-price
    font-size: 14px
    color black

.insurance-pop-logo
    svg
        width 53px
        height 62px
        fill $brand_color

.insurance-pop-container
    display flex
    justify-content space-between
    align-items: center
    flex-wrap: wrap

.insurance-logo-wrapper
    display flex
    gap 10px

.insurance-pop-title-wrapper
    display flex
    gap 20px

.insurance-pop-price
    font-size 22px
    font-weight 800

.overlay-pop-insurance-top-container
  padding 20px 20px 0 20px

.insurance-overlay-header-bullet
  display flex
  justify-content space-between
  font-size 20px
  font-weight 800
  +below($mobile)
      display none
.insurance-header__accessories-title
    font-size 20px
    font-weight 800

.insurance-header-bullet-mobile
    display: none; 
    gap 14px
    align-items baseline
    justify-content space-between
    +below($mobile)
      display flex

.insurance-overlay-pop__content
  display: flex;
  width 100%
  height 60vh
  opacity: 0
  transition: opacity $revealTransitionTime ease 200ms
  smooth-scroll()
  &.reveal-content
    opacity: 1
  #step1
    display: block;
    &.hidden
      display: none;
  #insurance-content
    display: flex;
    flex-grow: 1;
    flex-direction column;
    justify-content space-between;
    &.hidden
      display: none;

.accessories__overlay-container
    padding: 20px!important
    min-height 50vh
  
.overlay-header-content
  display inline-flex
  gap 20px

.overlay-button
  width 100%


.overlay-button-wrapper
  font-weight bold
  :nth-child(1)
    color $action_color
    +below($mobile)
      border 1px solid $action_color
      border-radius 4px
      color $action_color
  +below($mobile)
    gap 8px
    height fit-content
    flex-wrap wrap-reverse
   
.insurance-pdf-info-text
  p
    margin-bottom 0 
    font-size 14px

.accessories__overlay-container
  background $neutral_lightest_color

.accessories__item
  border-radius 8px
  background white
  border 2px solid $border-color
  margin-bottom: 8px
  padding 12px
  +below($mobile)
    padding 8px

.insurance__list
  border-top none

#accessories-content
  background $neutral_lightest_color
  width: 100%;

.accessories__list
  border-top none

.content
  display flex
  flex-direction column
  gap 12px
  height 100%

  h3
    font-size 20px
    line-height 24px
    font-weight 800
    margin 0

    +mobile()
      font-size 18px
      line-height 24px
