.confirmation_survey
  lost-flex-container row
  lost-center 800px
  padding-bottom: 30px
  +below(800px)
    lost-center 100%
    padding: 15px

  .survey_column
    lost-column 1/2
    padding: 0 10px 10px
    margin-bottom: 10px
    background-color: rgba(0,0,0,0.1)
    text-align center
    &.filled-in
      opacity: .6
      pointer-events: none;
    h3
      margin-bottom: 15px
    ul
      zero-list()
      text-align center
    +below($tablet)
      lost-column 1

  .survey-item
    margin-bottom: 12px
    button
      width 230px
