$uspHeight = 46px
$uspHeightMobile = 34px
$rotateWidth = 300px
.usp
  background-color: $usp_bar_background_color
  text-align: center;
  +below($desktop)
    padding: 1px

.usp__inner
  lost-center $page_width
  justify-content: space-between;
  align-items: center;
  height: $uspHeight
  position: relative;
  perspective: 1000
  +below($wide)
    padding: 0 20px
  +below($tablet)
    height: $uspHeightMobile
  &.active-streamer
    +below($desktop)
      display: block;
      height: auto;
      padding: 0

.usp__links
  display: flex;
  justify-content: space-between;
  width: $usp_bar_width
  margin: auto;
  position: relative;
  .active-streamer &
    margin 0
  +below($desktop)
    .active-streamer &
      margin 7px auto


  +below($tablet)
    display: block;
    transform-style: preserve-3d;
    height: $uspHeightMobile
    width: $rotateWidth
    margin: auto !important
    animation spin-usp 14s infinite

.usp__link
  display: flex
  align-items: center;
  color: $usp_bar_link_color
  text-transform: $usp_bar_text_transform;
  font-weight: $usp_bar_font_weight;
  font-size: $usp_bar_font_size;
  font-family: $usp_bar_font_family;
  white-space: nowrap;
  &:hover
    text-decoration: underline
    color: $usp_bar_link_color
  svg
    box(24px)
    margin-right: 10px
    fill $usp_bar_icon_color
  +between($desktop + 1, $wide)
    .active-streamer &
      margin-right 20px
      .shop_LPH &
        margin-right 60px
  +below($tablet)
    box($rotateWidth, $uspHeightMobile)
    position: absolute;
    width 100%
    backface-visibility: hidden;
    top 0
    left 0
    font-size: $usp_bar_font_size_mobile
    justify-content: center;
    svg
      box(20px)
      margin-right: 6px

.usp__trustscore
  .usp__label
    margin-left: 7px
.usp-trustpilot__star
  margin-top 3px
  svg
    box(16px)
    fill #fff
    margin-right 3px
    border-radius 3px
    padding 1px 1px 2px 1px
    background-color #8DC73F
  &.dimm-star
    svg
      background-color #c8c8c8

.usp__face1
  +below($tablet)
    transform: translateZ(5px) rotateX(0deg)
.usp__face2
  +below($tablet)
    transform: translateY(-8.5px) translateZ(-5px) rotateX(120deg)
.usp__face3,
.usp__trustscore
  +below($tablet)
    transform: translateY(8.5px) translateZ(-5px) rotateX(240deg)

@keyframes spin-usp{
  0% {
    transform: rotateX(0deg)
  }
  23% {
    transform: rotateX(0deg)
  }
  33% {
    transform: rotateX(-120deg)
  }
  56% {
    transform: rotateX(-120deg)
  }
  66%{
    transform: rotateX(-240deg)
  }
  89%{
    transform: rotateX(-240deg)
  }
  100%{
    transform: rotateX(-360deg)
  }
}
