.srp-top-banner
  display block
  width 100%
  img
    vertical-align:bottom;

.srp-top-banner__date
  width: 100%;
  background-color: $campaign_end_date_background_color
  font-size: .8rem
  color: $campaign_end_date_text_color
  border-left: 1px solid $campaign_end_date_border_color
  border-right: 1px solid $campaign_end_date_border_color
  border-bottom: 1px solid $campaign_end_date_border_color
  border-bottom-right-radius: $campaign_end_date_border_radius
  border-bottom-left-radius: $campaign_end_date_border_radius
  text-align: center;
  padding: 10px 5px
