.vip__in-stock-recommender-wrapper
  display:none;

.vip__in-stock-recommender-header
  font-size: 20px;
  margin: 8px 8px 0 8px;
  font-weight: 800;
  color: black;
  text-decoration: none;

.vip__in-stock-recommender-header:hover
  text-decoration: none;

.vip__in-stock-recommender
  background-color: #fff;
  margin: 10px 0 0 0;
  display: block;
  border: 2px solid $brand_color;
  border-radius: 5px;
  a
    &:hover
      text-decoration: none;

.brand_skousen
  .vip__in-stock-recommender
    border: 2px solid $brand_color_variation;

.vip__in-stock-recommender-footer
  background-color: $brand_color;
  height: 43px;
  padding: 8px;
  font-size: 20px;
  color: #FFF;
  font-weight: 400;
  a
    &:hover
      text-decoration: none;
  +below($mobile)
    font-size: 18px;

.brand_skousen
  .vip__in-stock-recommender-footer
    background-color: $brand_color_variation;

.vip__in-stock-recommender-content-section
  display: flex;
  flex-direction: column;
  flex-grow: 3;
  min-width: 0;

.vip__in-stock-recommender-image-section
  margin: 0 8px 0 0;
  align-self: center;
  +below($mobile)
    display: flex;
    justify-content: center;
    margin: 0 0 4px 0;

.vip__in-stock-recommender-label-section
  display: flex;
  justify-content: space-between;
  flex-grow: 3;

  .energy-label-wrapper
    position: relative;
    min-width: 74px;
    .energy-label
      display: block;
      +below($mobile)
        display: none;
    .energy-label-mobile
      display: none;
      right: 0;
      top: 0;
      +below($mobile)
        display: block;
  .energy__label-wrap
      display: flex;
      flex-direction: column;
      align-items: flex-end

.vip__in-stock-recommender-spec-price
  display: flex;
  justify-content: space-between;
  +below($mobile)
    flex-direction: column;

.vip__in-stock-recommender-spec-section
  min-width: 0;
  padding-right: 12px;
  ul 
    font-size: 14px;
    line-height: 20px;
    margin: 0;
    padding-inline-start: 0px;
    li
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      line-height: 24px;
      font-size: 14px;

.vip__in-stock-recommender-price-section
  display: flex;
  justify-content: end;
  margin: 50px 0 0 0;
  +below($mobile)
    justify-content: initial;
    margin: 8px 0 0 0;


.vip__in-stock-recommender-body
  display: flex;
  position: relative;
  padding: 8px;
  +below($mobile)
    padding: 10px;
    flex-direction: column;

  a  
    text-decoration: none;
    color: $body_text_color;

  .img-url
    min-width: 70px;

  img 
    max-height: 110px;

  .prod-info
    display: flex;
    flex-direction: column;
    .name 
      font-family: $font_family_header
      font-style: $font_family_header_style
      color: $shop_card_header_color
      font-size: 16px;
      line-height: 22px;
      text-transform: uppercase;
    .npkid
      color: $body_text_color
      font-size: 14px;
      line-height: 22px;
      margin-bottom: 8px;
  
  .price-info
    display:flex;
    flex-direction: column;
    .price
      font-size: 20px;
      line-height: 25px;
      color: $price_color
    .offer-text
      background-color: $offer_background_color
      color: $offer_text_color
      padding: 2px 6px
      font-size: 12px;
      font-style: italic;
      align-items: center
      display: inline
