.brand_skousen
  .campaign-banner__wrap
    position: relative;
    margin-right: -11px
    +below($tablet)
      margin-bottom 10px
    +below($mobile)
      margin-right 3px
    &.double-banner
      margin-top 15px
      +below($desktop)
        margin-top 5px
      +below($mobile)
        margin-top -5px

  .campaign-banner
    position relative
    overflow auto
    padding 3px

  .campaign-banner__info
    float right
    display flex
    align-items stretch
    justify-content center
    position relative
    padding 10px 15px
    min-height 50px
    background-color $srp_accent_color_light
    color #fff
    a
      color: #fff
    &.offer
      background-color transparent
      color $black
      a
        color $black
      &:before
        content ''
        position absolute
        top 0
        width 100%
        height 100%
        display block
        border-radius 10px
        left -4.5%
        transform perspective(300px) rotateX(-8deg) rotateY(-10deg) rotateZ(-1deg)
        background-color $offer_background_color
      .campaign-banner__info__right
        border-color $offer_text_color


  .grid .campaign-banner
    margin-right: 0

  .campaign-banner__info__left,
  .campaign-banner__info__right
    display flex
    flex-direction column
    align-items center
    justify-content center
    span
      line-height 1rem
      text-align center
    .force-one-line
      white-space: nowrap;

  .campaign-banner__info__left
    padding-right 8px
    z-index: 1
    .top
      text-transform uppercase
      font-weight bold
    .bottom
      font-size 0.75rem
      white-space: nowrap;

  .grid
    .campaign-banner__info__left
      display none

  .campaign-banner__info__right
    border-left 1px dashed #fff
    padding-left 8px
    z-index: 1
    .offer &
      border-left 1px dashed darken($srp_neutral_bg_color, 20%)
    .top
      font-weight bold
    .bottom
      font-size 0.75rem
    &.no-left-text
      border: none
      padding-left 0

  .grid .campaign-banner__info__right
    border none !important
    padding-left 0 !important

  .store-offer
    .campaign-banner__info__left
      span
        text-transform: uppercase;
        font-size: .65rem
        font-weight: bold
    .campaign-banner__info__right
      a
        display: flex;
        font-size: .65rem
        align-items: center;
        justify-content: center;
        color: #333
      svg
        box(7px)
        margin-left: 2px

  .campaign-banner__extra-info
    color $srp_faded_dark_color
    font-size 0.7rem
    margin-right: 12px;
    display block
    text-align right
    +below($desktop)
      font-size 0.75rem
