.fp__brands-row
  lost-center $page_width
  margin-bottom: 40px;
  justify-content: space-between;
  .brand_whiteaway &
    border-top: 1px solid #ececec
    border-bottom: 1px solid #ececec
    padding: 34px 0
  .brand_tretti &
    margin-top: 80px
  +below($wide)
    width: 100%;
    padding: 0 20px;

.fp__brands-brand
  lost-column: 1/6;
  +below($desktop)
    lost-column 1/3
  +below($tablet)
    lost-column 1/2
  a
    display: flex;
  img
    margin: auto;
