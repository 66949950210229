.os-mode-table
  position relative
  padding 0px 25px 25px 25px
  width 100%

.os-mode-table__table
  border none
  width 100%
  font-size 0.825rem
  color #fff
  letter-spacing 0.3px
  .table-heading
    font-weight bold
    text-transform uppercase
    word-spacing 1.5px
  .table-link
    text-transform none
    cursor pointer
    color: #fff;
    text-decoration: underline;
    &:hover
      text-decoration underline
  td:nth-child(1)
    min-width 220px
  &.success
    tr
      td:nth-child(2)
        border-right 2px solid darken($OS_success_color, 25%)
    .table-heading
      background-color darken($OS_success_color, 25%)
    tr:nth-child(odd)
      background-color $OS_success_color
    tr:nth-child(even)
      background-color lighten($OS_success_color, 10%)
  &.warning
    tr
      td:nth-child(2)
        border-right 2px solid darken($OS_warning_color, 25%)
    .table-heading
      background-color darken($OS_warning_color, 25%)
    tr:nth-child(odd)
      background-color $OS_warning_color
    tr:nth-child(even)
      background-color lighten($OS_warning_color, 10%)
  &.danger
    tr
      td:nth-child(2)
        border-right 2px solid darken($OS_danger_color, 25%)
    .table-heading
      background-color darken($OS_danger_color, 25%)
    tr:nth-child(odd)
      background-color $OS_danger_color
    tr:nth-child(even)
      background-color lighten($OS_danger_color, 10%)

.os-mode-table__table-full
  margin-top: 14px
  border none
  width 100%
  font-size 0.825rem
  letter-spacing 0.3px
  .table-heading
    font-weight bold
    text-transform uppercase
    word-spacing 1.5px
    background-color #999
    color #fff
  tr
  tr:nth-child(odd)
    background-color #f1f1f1
    color #333
  tr:nth-child(even)
    background-color #fff
    color #333

.os-mode-table__popover
  position fixed
  width 100vw
  height 100vh
  top 0
  left 0
  z-index $favorites_overlay

.os-mode-table__popover__backdrop
  position absolute
  width 100%
  height 100%
  background-color rgba(0, 0, 0, 0.5);

.os-mode-table__popover__box
  position absolute
  width 700px
  max-height 400px
  overflow auto
  left calc(50% - 300px)
  top 200px
  background-color #fff

.os-mode-table__popover__box__header
  background-color $brand_color
  color #fff
  display flex
  align-items center
  justify-content space-between
  span
    padding 0px 15px
  .close
    cursor pointer
    padding 5px 15px 7px
    line-height 1
    font-size 2rem

.os-mode-table__popover__box__body
  flex-center-children()

.os-mode-table__popover__box__loading
  circle(50px)
  border 5px solid #ececec
  border-top-color $brand_color
  margin 50px
  animation: loading-circle 600ms infinite ease;

.os-mode-table__popover__box__table
  width 100%
  table
    width 100%
    padding-bottom 10px
    tr:nth-child(even)
      background-color #ececec
    td:first-child
      width 140px
