$openTransitionTime = 700ms;
$revealTransitionTime = 300ms;

.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  // width: 100%; /* Full width */
  // height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  z-index: 101;
  transition: all $openTransitionTime cubic-bezier(0.645, 0.045, 0.355, 1), opacity $revealTransitionTime;
}

/* Modal Content/Box */
.modal-content 
  transition: all 700ms cubic-bezier(0.645, 0.045, 0.355, 1), opacity 300ms;
  transition-property: all, opacity;
  transition-duration: 700ms, 300ms;
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1), ease;
  transition-delay: 0s, 0s;
  width: 700px;
  left: calc(50vw - 350px);
  top: 4vh;
  background-color: #fff;
  border-radius: 2px;
  -webkit-box-shadow: 0 5px 30px rgb(0 0 0 / 30%);
  box-shadow: 0 5px 30px rgb(0 0 0 / 30%);
  position: absolute;
  +below($tablet) 
    width: 90vw;
    left: 5vw;
    top: 10vh;
  &--insurance
    top: 4vh
    +below($desktop)
      top: 64px
  

.insurance__overlay-header-wrapper {
  display: flex;
  justify-content: space-between;
  margin: 0;

  > h2 {
    font-size: 36px;
    color: $success_color;
    margin: 0;
    +below($tablet) {
      font-size: 26px;
    }
  }

  > p:hover {
    cursor: pointer;
  }
}

.insurance__overlay-top {
  padding: 28px 28px 24px 28px;

  +below($tablet) {
    padding: 12px 12px 12px 12px;
  }
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover, .close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.overlay-active {
  transition: all $openTransitionTime cubic-bezier(0.645, 0.045, 0.355, 1), opacity $revealTransitionTime;
  display: block;
  transition: background-color 0.5s;
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.insurance__subheader {
  font-size: 20px;
  font-weight: 800;
    +below($tablet) {
    font-size 18px;
  }
}

.insurance__overlay-bullet {
  stroke: $success_color;
  fill: $success_color;
  height: 22px;
  width: 20px;
  min-width: 20px;
}

.insurance__overlay-list {
  list-style: none;
  padding: 0 0 28px 0;
  margin: 0;
}

.insurance__overlay-list-cta {
  list-style: none;
  padding: 0 0 0 0;
  margin: 0;
}

.insurance__overlay-list-cta-desktop {
  +below($tablet) {
    display: none;
  }
} 

.insurance__overlay-list-item {
  align-items: center;
  display: flex;
  gap: 14px;
}

.insurance__overlay-add-button {
  width: 168px;
  height: 48px;
  background: #FC4C02;
  border-radius: 4px;
  border: none;
  color: white;

  +below($tablet) {
    width: 100%;
  }
}

.insurance__overlay-logo-text {
  display: flex;
  gap: 10px;

  > p {
    margin: 0;
    font-size: 20px;
    line-height: 24px;
    font-weight: 800;
  }
}

.insurance__overlay-company-container {
  background: #EEEEEE;
  padding: 24px 24px 20px 24px;
  margin-top: auto;
}

.insurance__overlay-additional-items {
  padding-bottom: 20px;
  margin: 0;

  &:last-child {
    padding-bottom: 0;
  }
    .links {
      padding 0
    }
}

.insurance__overlay-icon {
  width: 62px;
  height: 26px;
}

.insurance__overlay-container {
  +below($tablet) {
    height: 70vh;
    overflow: scroll;
  }
}


.insurance__overlay-container-cof {
  +below($tablet) {
    height 70vh
    width 100%
    overflow: scroll;
  }
    scrollbar-width: none;  /* Firefox */
}

.insurance__overlay-container-cof::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}
.insurance__overlay-mobile-section {
  +above($tablet) {
    display: none;
  }

  padding: 12px 12px 12px 12px;
  box-shadow: 0px -2px 4px -2px rgba(36, 36, 36, 0.1), 0px -4px 6px -1px rgba(36, 36, 36, 0.1);
}

.insurance__overlay-mobile-text {
  display: flex;
  padding-bottom: 8px;
  justify-content: space-between;
}

.hide-addinsurance-btn {
  display: none !important;
}

.pdp-exp-a {
  display : none;
}

.pdp-exp-b {
  display : flex;
}

.pdp-exp-b.vip__accordion {
  display : block;
}

.insurance__overlay-links-sub{ 
  font-size: 12px;
}

.insurance-overlay__close {
  position: absolute;
  font-size: 32px;
  color: #514F4F;
  cursor: pointer;
  right: 16px;
  top: 8px;  
}