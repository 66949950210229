.srp-filter-mobile-backdrop
  position fixed
  z-index $filter-mobile-panel
  top 0
  left 0
  width 100vw
  height 100vh
  background-color rgba(0, 0, 0, 0.5);
  display none
  +below($desktop)
    display block

// Desktop filter
.srp-filter-desktop
  width 270px
  background-color: $srp_filter_wrap_background_color
  border: $srp_filter_wrap_border
  margin-right: $srp_filter_wrap_margin
  +below($wide)
    margin-right 30px
  +below($desktop)
    display none

.srp-filter-desktop__wizard-start-button
  line-height 1.2
  margin $srp_wizard_open_button_margin
  margin-bottom 20px
  flex-center-children-column()
  justify-content center
  height 60px
  .first-line
    font-size 1.375rem
  .second-line
    font-size 0.9rem

.srp-filter-desktop__active-filters
  padding: $srp_filter_active_filters_padding
  margin-bottom: 0px;
  position relative
  display flex
  flex-wrap: wrap;
  align-items flex-start
  transition: all .3s $easeOutBack
  &.not-empty
    margin-bottom 10px
  &.delay
    transition-delay: .2s
  +below($desktop)
    display none

.filter-pill
  display: flex;
  align-items center
  border 1px solid #ccc
  background-color: #fff;
  padding: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  border-radius: 3px;
  font-size 0.875rem
  line-height: 0.875rem;
  color #555
  span
    margin-left 5px
    font-size 1.25rem
    padding-bottom 1px
    cursor pointer
  &.clear-filters
    margin-right: 10px
    background-color: rgb(240, 240, 240)
    cursor: pointer;

// Mobile filter
.srp-filter-mobile
  display none
  position fixed
  z-index $filter-mobile-panel
  top 0
  left 0
  width 85%
  height 100%
  max-width 300px
  margin 0
  padding 0
  background-color #fff
  box-shadow 0px 0px 20px rgba(0, 0, 0, 0.5);
  flex-direction column
  +below($desktop)
    display flex

.srp-filter-mobile__header
  display flex
  justify-content space-between
  align-items baseline
  min-height 60px
  background-color #ececec
  border-bottom 1px solid #ccc
  padding 10px 20px 10px
  div
    font-family $font_family_header
    font-size 1.25rem
  span
    line-height 1
    font-size 2.25rem
    padding 0px 10px 5px
    margin-right -10px
    cursor pointer

.srp-filter-mobile__body
  flex-grow 2
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  padding 20px 20px

.srp-filter-mobile__footer
  min-height 70px
  flex-center-children()
  border-top: 1px solid #ccc
  background-color #ececec
  .button-action
    font-size: 1rem;
    padding: 0 20px;
    line-height: 42px;
    border-radius: 6px

// Filter box
.filter-box
  margin-bottom 10px
  transition: margin 0.3s ease-out
  position: relative;
  &.expanded
    margin-bottom 20px
    +below($desktop)
      margin-bottom 10px
  +below($desktop)
    margin-bottom 10px

.filter-box__header
  display flex
  align-items: center;
  justify-content: space-between;
  line-height: 1;
  padding: $srp_filter_box_header_padding;
  cursor: pointer;
  position relative
  background-color: $srp_filter_box_header_background_color
  color: $srp_filter_box_header_text_color
  .brand_skousen &
    border-bottom: 1px solid $gray_border
  +below($desktop)
    margin-bottom 0
    padding 0 0px 10px 0
    border-bottom 1px solid #ccc
    background-color: transparent
.filter-box__header__title-wrap
  display flex
  align-items center
.filter-box__header-label
  font-weight: $srp_filter_box_header_font_weight;
  font-size $srp_filter_box_header_font_size
  +below($desktop)
    color: #333
  +below($desktop)
    font-size 1.25rem
  +above($desktop + 1)
    &:hover
      text-decoration underline
.filter-box__internal-label
  color: #aaa
  font-size: .8rem
.filter-box__unit
  color: lighten($srp_filter_box_header_text_color, 35%)
  font-weight: normal
  font-size: 0.75rem
  margin-top 5px
  +below($desktop)
    color: lighten(#333, 60%)
.filter-box__arrow-left,
.filter-box__arrow-right
  fill $srp_filter_box_header_icon_color
  transition: transform 0.3s ease-out
  +below($desktop)
    fill #333
  .filter-box.expanded &
    transform rotate(90deg)
.filter-box__arrow-left
  box(16px)
  display: $srp_filter_box_arrow_left_display
  margin-right: 5px;
  +below($desktop)
    display: block
.filter-box__arrow-right
  box(20px)
  display: $srp_filter_box_arrow_right_display
  margin-left: 10px;
  +below($desktop)
    display: none

.filter-box__header__description-wrap,
.toggle-button__description-wrap
  box($srp_filter_box_question_size)
  position relative
.toggle-button__description-wrap
  margin-left 10px
  margin-top 3px
  .disabled &
    display none

.filter-box__header__description-wrap__inner,
.toggle-button__description-wrap__inner
  flex-center-children()
  box(36px)
  border 1px solid transparent
  margin-left: -11px
  margin-top -11px
  +above($desktop + 1)
    &:hover
      .filter-box__description-trigger,
      .toggle-button__description-trigger
        svg
         fill #333

.filter-box__description-trigger,
.toggle-button__description-trigger
  svg
    box($srp_filter_box_question_size)
    fill: $srp_filter_box_question_background_color
    transition: all 200ms $easeOutBack
    pointer-events: none;

.filter-box__description-box,
.toggle-button__description-box
  width 180px
  position absolute
  right -8px
  bottom 30px
  padding: 6px 8px
  box-shadow: 2px 2px 14px rgba(0,0,0,.4)
  background-color: #fff
  color: rgba(0, 0, 0, .6)
  border-right: solid 4px $brand_color
  font-size .75rem
  line-height 1.25
  transition: all 200ms cubic-bezier(0.175, 0.885, 0.320, 1.1)
  transform-origin: 90% top;
  z-index: 2
  &:after
    content: ''
    display: block;
    box(0)
    border solid 4px transparent
    border-top-color #fff
    position: absolute;
    right: 9px
    bottom: -8px

.filter-box__body
  background-color: $srp_filter_box_background_color;
  border: 1px solid $srp_filter_box_border_color;
  border-radius: $srp_filter_box_border_radius;
  font-size 0.875rem
  line-height: 0.875rem;
  padding: 20px 10px 10px;
  .brand_skousen &
    padding : 16px 0px;
  +below($desktop)
    padding 20px 0px 10px
    border none
    font-size 1rem
  &.expand-down
    transition: all .35s $easeOutBack
  &.expand-up
    transition: all .35s $easeInBack


// Range filter
.range-filter
  margin-bottom: 20px
  margin-top 3px
  +below($desktop)
    margin-bottom 17px

.range-filter__name
  font-weight bold
  margin 0px 0 10px
  +below($desktop)
    margin-bottom 10px




.range-filter_input-wrap
  display flex
  justify-content space-between
  margin 0 0 15px
  white-space nowrap



.range-filter__pre-label
  color rgba(0,0,0,.5)

.range-filter__editable-label
  padding 3px 0
  transition all 100ms
  &:focus, &:hover
    border solid 1px #ccc
    padding 3px 8px
