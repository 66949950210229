.energy-label
  cursor pointer
  margin-bottom: 4px
  +below($desktop)
    display: none

.energy-label-mobile
  margin-bottom: 4px
  +above($desktop + 1)
    display: none
