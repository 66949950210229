.altapay.container
  lost-center $page_width
  padding: 0 20px
  box-sizing border-box

.altapay
  .pull-right
    float right

  .row
    lost-flex-container row
    width 100%


  .panel-wrapper
    lost-column 3/5
    +below($tablet)
      lost-column 1/1

  .order-wrapper
    lost-column 2/5
    +below($tablet)
      lost-column 1/1

  .shop-logo-wrapper
    lost-column 2/5
    +below($tablet)
      lost-column 1/2

    img
      margin-top 20px
      margin-bottom 20px

  .payment-logo-wrapper
    lost-column 1/5
    +below($tablet)
      lost-column 1/2

    img
      float right
      margin-top 14px

  .payment-att
    color $danger-color
    width 100%
    margin-bottom 14px
