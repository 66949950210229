.slider
  position: relative;
  margin $fp-slider-margin-above-wide
  +below($wide)
    margin $fp-slider-margin-below-wide
  +below($desktop)
    margin $fp-slider-margin-below-desktop
  +below($tablet)
    margin $fp-slider-margin-below-tablet
  +below($mobile)
    margin $fp-slider-margin-below-mobile

.slider__inner
  margin 0 auto
  max-height $fp-slider-max-height
  height $fp-slider-height-above-wide
  +below($wide)
    height $fp-slider-height-below-wide
  +below($desktop)
    height $fp-slider-height-below-desktop
  +below($tablet)
    height $fp-slider-height-below-tablet
  +below($mobile)
    height $fp-slider-height-below-mobile
    max-height initial

.slider__slide
  opacity: 0
  position: absolute;
  top 0
  box(100%)
  display: flex;
  transition: all 1500ms
  pointer-events: none;
  flex-direction: column
  &.show-slide
    opacity: 1
    pointer-events: all;


.slider__slide__inner
  margin: auto;
  width: 100%
  max-width $fp-slider-max-width
  img
    width 100%
    vertical-align:bottom;
  +below($mobile)
    width 100%
    img
      width: 100%

.slider__slide__date
  margin: auto;
  width: 100%
  max-width $fp-slider-max-width
  background-color: $campaign_end_date_background_color
  font-size: .8rem
  color: $campaign_end_date_text_color
  border-left: 1px solid $campaign_end_date_border_color
  border-right: 1px solid $campaign_end_date_border_color
  border-bottom: 1px solid $campaign_end_date_border_color
  border-bottom-right-radius: $campaign_end_date_border_radius
  border-bottom-left-radius: $campaign_end_date_border_radius
  text-align: center;
  padding: $campaign_end_date_padding
  text-align: center;

.slider_dots_wrap
  position absolute
  bottom 15px
  left 0
  width 100%
  display flex
  justify-content center
  align-items center
  +below($desktop)
    bottom 10px
  +below($tablet)
    bottom 5px


.slides_single-dot
  box(12px)
  border-radius $fp-slider-dots-radius
  margin-right 6px
  margin-left 6px
  background-color #fff
  cursor pointer
  transition all 300ms cubic-bezier(0.445, 0.05, 0.55, 0.95)
  &.active-dot
    transform scale(1.25)
    background-color $brand_color
  +below($tablet)
    box(8px)

.slider-arrows
  position absolute
  top 0
  left 0
  width: 100%
  pointer-events none

.slider-arrows__inner
  margin 0 auto
  max-height $fp-slider-max-height
  height $fp-slider-height-above-wide
  width: 100%
  max-width $fp-slider-max-width
  display flex
  justify-content space-between
  align-items center
  +below($wide)
    height $fp-slider-height-below-wide
  +below($desktop)
    height $fp-slider-height-below-desktop
  +below($tablet)
    height $fp-slider-height-below-tablet
  +below($mobile)
    height $fp-slider-height-below-mobile
    max-height initial
  .shop_SKOU &, .shop_SKOUNO &
    justify-content flex-start
    align-items flex-end
    +below($mobile)
      justify-content flex-end

.slider-arrows__left-arrow,
.slider-arrows__right-arrow
  box(40px)
  position relative
  transition all 0.25s ease-out
  &:hover
    +above($desktop + 1)
      transform: scale(1.2);
  .shop_SKOU &, .shop_SKOUNO &
    margin 10px
    +below($mobile)
      margin-bottom 6px
      margin-right 10px
    &:hover
      transform scale(1)
      background-color rgba(0, 0, 0, 0.6);

.slider-arrows__left-arrow
  margin-left: 10px;

.slider-arrows__right-arrow
  margin-right: 10px;
  .shop_SKOU &, .shop_SKOUNO &
    margin-left 0
    margin-right: 0

.slider-arrows__shadow-arrow,
.slider-arrows__primary-arrow
  svg
    cursor pointer
    pointer-events all
    box(40px)
    position absolute
    left 0
    transform-origin 50% 50%

.slider-arrows__shadow-arrow
  svg
    top 1px
    left 2px
    fill rgba(0, 0, 0, 0.5);
    filter blur(2px)
  .shop_SKOU &, .shop_SKOUNO &
    display none

.slider-arrows__primary-arrow
  svg
    fill #fff
