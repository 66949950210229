.newsletter-signup
  &.float
    display flex
    flex-wrap wrap
    .newsletter-input-name
      width 49%
      margin-right: 1%
      +below($tablet)
        width 100%
        margin-right: 0
    .newsletter-input-email
      width 49%
      margin-left: 1%
      +below($tablet)
        width 100%
        margin-left: 0
    +below($tablet)
      display: block

  &__button-wrapper
    display flex
    width: 100%
    +below($tablet)
      flex-direction: column-reverse;

  &__button
    width: 16%
    margin-right: 2%
    +below($desktop)
      width: auto
      margin: 0

  &__validation
    color $action_text_color
    padding: 0 10px
    border-radius: 3px
    width: 82%
    font-size: 0.95rem
    display: flex
    align-items: center
    +below($desktop)
      width: auto
  .validated
    background $success_color
    +below($desktop)
      margin-bottom: 10px
  .error
    background: $danger_color
    color $action_text_color
    +below($desktop)
      margin-bottom: 10px

  &__conditions
    font-size 0.6rem

.checkbox__label
  margin-top: 8px
