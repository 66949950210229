category-pill()
  display: inline-block;
  font-size .7rem
  padding: 2px 8px
  background-color: rgba(255,255,255,.7)
  border-radius: 4px
  color: rgba(0,0,0,.8)
  margin-right: 3px
  transition: transform 150ms ease
  &:hover
    transform: scale(1.1);
  &:active
    transform: scale(1);
