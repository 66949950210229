.wag-modal
  position: fixed;
  width: 100vw
  height: 100vh;
  background: rgba(0,0,0,.7);
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  overflow: hidden;
  transition: opacity 400ms;
  z-index: $wag_modal_Z;
  &.show-modal
    visibility: visible;
    opacity: 1;
    pointer-events: all;

.wag-modal__inner
  display: flex
  margin: 5vw auto 0 auto;
  width: 70vw
  padding: 14px;
  max-height: 80vh;
  overflow:auto;

  tr:nth-of-type(even)
    background-color: transparent;

.wag-modal__inner.wag-modal__inner-default
  background: #fff

  table
    width: 100%;
    background-color: transparent;
    border-spacing: 0;

  th
    background-color: #fff;
    text-align: left;
    font-weight: bold;
    border-bottom: 2px solid #d1d1d1;
    padding: 4px;

  td
    border-bottom: 1px solid #d1d1d1;
    padding: 4px;

  tr:nth-of-type(odd)
    background-color: #f1f1f1;


.wag-modal__close
  pointer-events: all;
  color: rgba(255,255,255, .8)
  font-size: 3rem
  position: absolute;
  right 40px
  top 0px
  cursor: pointer;
  &:hover
    color: rgba(255,255,255, 1)
