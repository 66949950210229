
.accessories-carousel
  position relative
  width calc(100% - 80px - 24px)
  margin auto
  margin-bottom 40px

  &.full-width
    width 100%

  +below($mobile)
    width 100%

  .glide__slides
    margin 0

  .glide__bullets
    display flex

  .glide__bullet[class*=active],
  .glide__bullet:hover,
  .glide__bullet:focus
    background-color $brand_color
    border-color $brand_color

  .glide__arrows
    +below($mobile)
      display none

  .glide__arrow
    display flex
    justify-content center
    align-items center
    background rgba(255, 255, 255, 0.9)
    border 1px solid #E0E0E0
    border-radius 4px

    .brand_tretti &
      svg
        fill #333333

  .glide__arrow--left
    left -52px

  .glide__arrow--right
    right -52px
