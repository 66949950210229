.contentful-lp
  h2
    margin-top 0
    font-size 1.625rem
    p
      margin 0

.contentful-lp__normal-section
  position relative
  display block
  margin 0 auto
  max-width 960px
  margin-bottom 60px
  +below($desktop)
    margin 0px 20px 40px
  +below($mobile)
    margin 0px 10px 30px

.contentful-lp__top-slider
  position relative
  margin-bottom 30px


.contentful-lp__top-slider__inner-slide,
.contentful-lp .slider-arrows__inner &
  margin 0 auto
  max-height 378px
  height calc(100vw * 0.3214)
  +below($mobile)
    height calc(100vw * 0.75)
    max-height initial

.contentful-lp__image-nav
  display flex
  justify-content space-around
  +below($tablet)
    flex-direction column
    align-items center

.contentful-lp__image-nav-box
  position relative
  flex-center-children-column()
  margin-right 20px
  width 33%
  &:last-child
    margin-right 0
  +below($tablet)
    margin-bottom 20px
    margin-right 0
    width initial
  img
    width 100%
  span
    color: #fff;
    background-color $brand_color_variation
    line-height 1
    padding 10px 20px
    border-radius 10px
    min-width 200px
    text-align center
    &:hover
      text-decoration underline

.contentful-lp__video-section
  margin-top 0
  video
    cursor pointer
    width 100%

.contentful-lp__2-column-text__column-wrap
  display flex
  +below($tablet)
    flex-direction column

.contentful-lp__2-column-text__column-left,
.contentful-lp__2-column-text__column-right
  width 50%
  +below($tablet)
    width initial

.contentful-lp__2-column-text__column-left
  margin-right 30px

.contentful-lp__mid-page-banner
  position relative
  margin 0 auto 20px
  display block
  img
    display block
    margin 0 auto
    max-height 380px
    height calc(100vw * 0.3214)
    +below($mobile)
      width 100%
      height calc(100vw * 0.75)
      max-height initial

.contentful-lp__featured-image
  display flex
  +below($desktop)
    flex-direction column

.contentful-lp__featured-image__text-wrap
  width 33%
  margin-right 50px
  +below($desktop)
    width 100%
    margin 0 0 15px

.contentful-lp__featured-image__image-wrap
  width 67%
  img
    box-shadow 0px 20px 40px rgba(0, 0, 0, 0.3);
  +below($desktop)
    width initial
    margin 0
    text-align center

.contentful-lp__image-grid__grid-wrap
  lost-flex-container row

.contentful-lp__image-grid__box
  lost-column 1/3 3 50px
  margin-bottom 40px
  +below($tablet)
    lost-column 1/2 2 30px
    margin-bottom 30px
  +below($mobile)
    lost-column 1/1 1 0px
    margin-bottom 10px
    text-align left
    padding 10px
  img
    display block
    margin 0 auto
  span
    color #555
    font-weight bold
    padding 0px 0px 5px
    display block
  div
    color #999
    font-size 0.875rem
