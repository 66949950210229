@media print

  @page
    margin: 0;

  body
    font: 12pt;
    line-height: 1.3;
    color: #000;
    padding: 10px 30px;

  h1
    font-size: 24pt

  h2
    font-size: 14pt; margin-top: 25px

  .header-main,
  .b2b-buttons,
  .main-nav,
  .breadcrumb-outer,
  .vip__b2b-data__outer,
  .vip__product-info,
  .vip__reviews__outer,
  .vip__video-description__outer,
  .vip__addition-info__outer,
  .vip__alt-products__outer,
  .gallery__thumbs_outer,
  .footer-main,
  .expandable-section__toggle,
  .vip__specifications__manual,
  .gallery__next,
  .gallery__previous,
  .inhouse__outer,
  .skou-header,
  .cof__confirmation-header-text,
  .cof__section-footer,
  .vip-outer .badge-wrapper,
  .toc-nav,
  .super-specs-variant-c,
  .vip__superspecs__expandable-block,
  #Coi-Renew
    display: none !important;

  .super-specs-variant-c-mobile
    display: none;


  .vip__gallery-description-wrapper,
  .vip__specifications__description,
  .footer-sub,
  section
    margin: 0 !important;
    padding: 0 !important;
    margin-top: 10px !important;

  .vip__specifications__energy
    padding-right: 40px;

  .vip__specifications__header,
  .vip__specifications__line
    padding: 0;

  .vip__specifications
    page-break-before: always;

  .vip__specifications,
  .vip__addition-info
    height: auto !important;

  .inhouse__printed__price
    display: block !important;
    font-size: 30px;
    color: $brand_color;
    width: 100%;
    margin: 10px 0

  .cof__confirmation-header
    display: block;
    text-align: center;
    border:0;

  .cof__inner-section
    border:0;

  .cof__outer-section
    box-shadow: none;

  .cof__section-header
    display: block;
    text-align: center;

  .cof__section-heading
    color: #333 !important;
    text-align: center;
    padding: 0;
    &:after
      display: none;

  .footer-sub__info,
  .footer-sub__inner
    display: block !important;
    text-align: center !important;
    span, a
      color: #333 !important;
      text-decoration: none !important;

  .footer-sub__social
    display: none !important;
