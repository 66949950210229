buttonFill($btn_bg_color, $btn_txt_color)
  background-color: $btn_bg_color
  color: $btn_txt_color
  transition: background 150ms;
  &:active
    background: lightness($btn_bg_color, 60%)
  &:hover
    background: alpha($btn_bg_color, 80%)
  svg 
    fill:$btn_txt_color;

buttonOutline($btn_border_color, $btn_txt_color)
  background-color: alpha($btn_border_color, 3%)
  color: $btn_txt_color
  border: 1px solid $btn_border_color
  transition: background 150ms;
  &:active
    border-color: lightness($btn_border_color, 60%)
    background: lightness($btn_border_color, 12%)
  &:hover
    border-color: alpha($btn_border_color, 80%)
    background: alpha($btn_border_color, 12%)
  svg 
    fill:$btn_txt_color;

//Basic layout of button
.button
  background-color: rgba(255, 255, 255, 1);
  color: #555
  border-radius: $btn_border_radius;
  padding: 0 20px;
  line-height 42px
  text-align center
  cursor: pointer;
  font-size: .95rem;
  font-family: $font_family_base
  border: 0;
  overflow: hidden;
  position: relative;
  transition: all 80ms
  white-space: nowrap;
  display: inline-block;
  box-sizing: content-box;
  svg 
    box(20px)
  +below($mobile)
    padding: 0 10px;
    line-height 36px
  &:hover
    text-decoration: none;
  &:active
    background-color: $dark_gray
    border-color: $dark_gray
    transform: scale3d(0.9, 0.9, 1);
    +below($wide)
      transform: scale3d(1.1, 1.1, 1);
  &:disabled
    pointer-events none
    opacity .7

//Button sizes
.button-lg
  font-size: 1.4rem
  border-radius: $btn_border_radius_lg;
  padding: 0 44px;
  line-height: 53px
  svg 
    box(24px)

.button-sm
  font-size: .7rem
  padding: 0 15px
  line-height 30px
  svg 
    box(14px)

//Button types

//Regular filled color buttons
.button-action
  buttonFill($action_color, $action_text_color)
  .brand_foetex &
    background-color: $brand_color
  .brand_skousen &
    border-radius: 16px;

.button-brand
  buttonFill($brand_color, #fff)

.button-neutral
  buttonFill($neutral_color, #fff)

//Outlined buttons
.button-action--outline
  buttonOutline($action_color, $action_color)

.button-brand--outline
  buttonOutline($brand_color, $brand_color)

.button-neutral--outline
  buttonOutline($neutral_color, $neutral_color)

//Buttons with icon
.button-icon
  display: flex;
  align-items: center;
  svg 
    margin-right: 6px;

//Other buttons
.tab-button-neutral
  background-color: $neutral_lightest_color
  color: #777
  margin-left 7px
  &:first-child
    margin-left 0
  &:active
    transition: background 150ms;
  &.active
    background-color: $neutral_color
    color: #fff
    &:hover
      cursor default
      opacity: 1

.button-success
  buttonFill($success_color, #fff)

.button-danger
  buttonFill($danger_color, #fff)

.button-warning
  buttonFill($warning_color, #333)

.button-disabled
  background-color: #fff
  color: rgba(0, 0, 0, .4)
  box-shadow: inset 0 0 0 1px #ccc;
  pointer-events: none