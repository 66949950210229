$menuWidth = 320px
$menuWidthNarrow = 265px

// PAGE CONTENT

.main-content
  transition: transform 350ms ease-in
  background-color: $main_bg_color
  &.show-menu
    box-shadow: 3px 0 10px rgba(0,0,0,.3)
    position: fixed;
    top: 0
    bottom: 0
    left 0
    right 0
    transform: translate3d(-($menuWidth), 0, 0);
    +below($mobile_small)
      transform: translate3d(-($menuWidthNarrow), 0, 0);
  &.dragging
    transition: transform 0ms


// CONTENT OVERLAY

.off-canvas-overlay
  display: none;
  .show-menu &
    display: block;
    position fixed
    top $header_height_mobile
    left 0
    right 0
    bottom 0
    .brand_tretti &
      position static
    //Because streamer is outside header on Skousen
    .shop_SKOU &, .shop_SKOUNO &
      top "calc(%s + 30px)" % ($header_height_mobile)


// THE MENU

.nav-off-canvas
  position: absolute
  top 0
  right: 0
  width: $menuWidth
  max-width: 95vw
  min-height: 100vh
  background-color: #fff
  z-index: $off_canvas_Z
  +above($desktop + 1)
    display: none
  +below($mobile_small)
    width: $menuWidthNarrow

.nav-off-canvas__lvl1, .nav-off-canvas__lvl2, .nav-off-canvas__lvl3
  position: absolute;
  width: 100%
  right: 0
  top 0
  bottom 0
  transition: transform 350ms ease-in
  background-color: #f5f5f5
  min-height: 100vh
  box-shadow: 3px 0 10px rgba(0,0,0,.3)
  opacity 0
  &.show-menu
    smooth-scroll()
    opacity 1

.nav-off-canvas__lvl2, .nav-off-canvas__lvl3
  display: none
  &.show-menu
    display: block

.nav-off-canvas__lvl1
  z-index: 3
  .lvl2-visible &
    transform: translate3d(-($menuWidth - 5), 0, 0);
    +below($mobile_small)
      transform: translate3d(-($menuWidthNarrow - 5), 0, 0);

.nav-off-canvas__lvl2
  z-index: 2
  .lvl3-visible &
    transform: translate3d(-($menuWidth - 10), 0, 0);
    +below($mobile_small)
      transform: translate3d(-($menuWidthNarrow - 10), 0, 0);

.nav-off-canvas__lvl3
  z-index: 1


.nav-off-canvas__header
  position: relative;
  font-weight bold
  text-align center
  height $header_height_mobile
  display: flex;
  justify-content: center;
  align-items: center;
  .back-link
    circle(40px)
    position: absolute;
    left: 15px
    top 8px
    transition: all 80ms
    display: flex;
    &:active
      background-color: rgba(0,0,0,.4)
    svg
      box(50%)
      fill $brand_color
      stroke $brand_color
      stroke-width 2px
      margin: auto;

.nav-off-canvas__menu-items
  zero-list()
  margin-bottom: 10px
  border-top: solid 1px rgba(0,0,0,.06)

.nav-off-canvas__menu-item
  border-bottom: solid 1px rgba(0,0,0,.06)
  position: relative;
  &:last-child
    border-bottom: none
  a
    color $black
    padding: 10px 20px
    display: inline-block;
    width: 100%
    text-decoration: none;
    transition: all 80ms
    font-size .9rem
    +below($mobile)
      font-size 1rem
    &:active
      background-color: rgba(0,0,0,.4)
    .nav-off-canvas__lvl2 &
      padding-left: 25px
    .nav-off-canvas__lvl3 &
      padding-left: 30px

  span
    pointer-events: none

.nav-off-canvas__brand-link
  font-weight bold

.menu-item-more-levels:after
  content: ''
  position: absolute;
  display: block;
  box(12px)
  transform: rotate(45deg);
  border solid 2px transparent
  border-right-color: rgba(0,0,0, .4)
  border-top-color: rgba(0,0,0, .4)
  pointer-events: none;
  right: 20px
  top 13px

.nav-off-canvas__offers-link:after
  content: ''
  display: inline-block;
  circle(10px)
  margin-left: 3px
  background-color: $offer_background_color
