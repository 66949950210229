.quote__wrapper
  standardSection()

.quote__header-info
  display flex
  justify-content space-between
  align-items flex-start
  +below($tablet)
    flex-direction: column;

.quote__header-info-text
  max-width 620px;
  +below($tablet)
    margin-bottom: 14px
  p
    margin 0

.quote__header-info-expire
  background-color: $gray_background_light
  padding 20px
  font-size: 0.75rem

.quotes__container
  lost-flex-container row
  display flex
  justify-content: center;
  +below($desktop)
    display: none;

.quotes__container-mobile
  +above($desktop)
    display: none

.quote__card-wrapper
  lost-column 1/3
  +below($desktop)
    lost-column 1/1

.quote__card-recommend
  visibility: hidden;
  height: 40px
  text-align: center;
  font-size: 1.3rem
  color: $brand_color
  &.shown
    visibility: visible;

.quote__card
  width 100%
  shopCard()
  &.recommend
    border 3px solid $brand_color
    box-shadow: 0px 0px 10px 0px #cdcdcd;
    +above($desktop)
      margin-top: -2px

.quote__card-header
  background-color: $gray_background_light
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 16px
  h2
    margin: 0
    font-size: 1.8rem
    line-height: 1
  p
    margin 0 0 16px 0
    font-size: 0.75rem

.quote__card-header__price
  position: relative;
  display: flex;
  width: 100%
  align-items: center;
  flex-direction: column;
  margin-bottom: 16px
  background-color: #fff
  border-radius: $btn_border_radius

.quote__card-header__price-discount
  background-color: $offer_background_color;
  color: $offer_text_color;
  padding: 4px 8px
  width: 100%
  font-size: 1.1rem
  font-weight: bold
  text-align: center;
  border-top-left-radius: $btn_border_radius
  border-top-right-radius: $btn_border_radius

.quote__card-header__price-total
  padding-top: 16px
  font-size: 2.3rem;
  line-height: 1
  color: #333

.quote__card-header__price-conditions
  padding-bottom: 16px
  font-size: 0.75rem

.quote__cta-wrapper
  display: flex
  width: 100%
  button
    width: 100%

.quote__card-product
  padding: 16px

.quote__card-product__info
  display: flex;
  align-items: flex-start;

.quote__card-product__info-img
  display: flex;
  justify-content:center
  width: 35%;
  padding-right: 14px
  img
    max-height: 180px

.quote__card-product__info-name
  width: 65%

.quote__card-product__info-brand
  font-family: $font_family_header
  color:#888
  font-size: 1.1rem
  text-transform: uppercase;
  width: 100%
  margin-top: 5px
  margin-bottom: 2px;
  line-height: 1
  truncate()
  a
    color:#888
    &:hover
      text-decoration: none;

.quote__card-product__info-npkid
  font-size: .875rem
  margin-bottom: 12px;
  line-height: 1
  truncate()

.quote__card-product__info-price
  font-size: 1.5rem
  color: $body_text_color
  margin-bottom: 12px;
  line-height: 1
  .stroked
    display: inline-block;
    position: relative;
    font-size: 1rem
    &:after
      content: ''
      position absolute
      width 97%
      left -2%
      top 50%
      border-bottom 2px solid rgba(80, 80, 80, 0.8)
      transform skewY(-11deg)

.quote__card-product__info-price-multiple
  font-size: .875rem
  color: $body_text_color

.quote__card-product__info-stock-status
  font-size: .6rem
  line-height: 1
  margin-bottom: 12px;
  .in-stock
    color: $in_stock_color

.quote__card-product__service
  padding-top: 10px
  border-top: 1px solid #eee

.quote__card-product__service-header
  display: flex;
  align-items: center;
  justify-content: space-between;

.quote__card-product__service-description
  margin-top: 5px;
  font-size: .875rem
  ul
    margin: 0
    padding: 0 16px



.quote__card-items
  &.is-service
    border-bottom: 1px solid $gray_background_light

.quote__card-footer__line,
.quote__card-footer__line-discount,
.quote__card-footer__line-total,
.quote__card-footer__line-vat,
.quote__card-footer__line-button
  display: flex;
  justify-content: space-between;
  div
    padding: 8px 16px

.quote__card-footer__line-discount
  background-color: $offer_background_color
  color: $offer_text_color;
  font-weight: bold

.quote__card-footer__line-total
  font-weight: bold
  font-size: 1.2rem
  border-bottom: 0
  div
    padding: 8px 16px 0 16px

.quote__card-footer__line-vat
  font-size: .875rem
  div
    padding: 0px 16px 8px 16px

.quote__card-footer__line-button
  background-color: $gray_background_light
  div
    padding: 16px 16px 16px 16px

.quotes__footer
  display: flex
  flex-direction: row;
  justify-content: center;
  padding-top: 14px
  margin-bottom: 20px
  div
    text-align: center;
    max-width: 500px
