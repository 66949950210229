.super-specs__header
    border: solid 1px #e8e8e8;
    align-items: center;
    width: 100%;
    display: flex;
    background-color: #fff
    margin: 30px 0 30px
    +below($mobile)
        background-color: $brand_color

.super-specs__heading
    background-color: $brand_color
    color: #fff;
    font-family: $font_family_header
    font-size: 1.25rem;
    padding: 9px 46px;
    position: relative;
    &:after
        content: '';
        display: block;
        position: absolute;
        width: 0;
        height: 0;
        border-left: solid 18px $brand_color;
        border-top: solid 23px transparent;
        border-bottom: solid 23px transparent;
        top: 0;
        right: -18px;

.super-specs__subheading
    font-size: .875rem;
    color: rgba(0,0,0,0.4);
    margin-left: 40px;
    +below($mobile)
        display: none

.super-specs__list
    lost-flex-container row

.super-specs__spec
    lost-column 1/2
    lost-flex-container row
    margin-bottom: 30px
    +below(800px)
        lost-column 1



.super-specs__spec-img-wrap
    lost-column 0.55
    img
        width: 100%
.super-specs__spec-text-wrap
    lost-column 0.45
    font-size: .875rem
    overflow-wrap: break-word;
    word-break: break-word;
    hyphens: auto;
    h4
        color $brand_color
        margin-top: 0
        font-size: 1.1rem
