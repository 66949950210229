.alternate__product__thumb__wrap
  width 20%
  padding 10px 5px 0px 10px
  +below($desktop)
    width 25%
  +below($mobile)
    padding 10px 10px 0px 5px
  .alternate__product__thumb
    display block

.alternate__product__details
  width 50%
  padding-top 10px
  +below($desktop)
    width 75%
  .alternate__product__details__rating
    display flex
    padding-bottom 3px
  .rating-stars
    margin-top 2px
  .alternate__product__details__avg__score
    padding-left 5px
  .alternate__product__details__stock__status
    display block
    font-size 12px
  .in-stock
    color $brand_color
  .alternate__product__details__specs
    font-size 12px
    margin 10px 0 0
    padding 0 0 0 18px
    line-height 1.3

.alternate__product__price__and__button
  display flex
  flex-direction column
  justify-content space-between
  width 30%
  padding-top 4px
  text-align right
  +below($desktop)
    flex-direction initial
    width 100%
    padding-left 10px
    padding-top 15px
  .alternate__product__price
    font-size 32px
  .alternate__product__button
    width 100%
