.career__services-wrapper
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;

.career__service
  lost-column: 1/2;
  background-color: #ececec;
  margin-bottom: 20px;
  img
    width: 100%;
  h3
    font-size: 1.7rem;
  +below($tablet)
    lost-column 1/1

.career__service-text
  padding: 0 20px 20px 20px;
  font-size: .875rem
