.skoulp__landingpage-outer
  padding-top: 20px;
  padding-bottom: 20px;
  +below($mobile)
    padding: 20px 10px;
  .shop_SKOU &,
  .shop_SKOUNO &
    background-color: #000
    color: #fff


.skoulp__landingpage-banner
  width: $page_width
  margin: 0 auto
  +below($desktop)
    width: 100%;

.skoulp__pligt
  text-align: right
  color: #fff;
  text-shadow: 0px 0px 2px 2px #333
  font-size: .8rem
  text-align: center;

.skoulp__landingpage-divider
  lost-center: $page_width;
  margin-top: 20px;
  +below($desktop)
    width: 100%;

  img
    transition: all 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
    height: 100%;
    width: 100%;
    display: block;
    &:hover
      transform:scale(1.03);
    &.skoulp__banner
      transition: none;
      &:hover
        transform: none;

.skoulp__carousel-wrap
  width: $page_width
  color: #777
  .recommended__slide__card
    background: #fff
  +below($desktop)
    width: 100%

.skoulp__header
  font-size: 3.6rem
  color: #fff
  text-align: center;
  text-transform: uppercase;
  line-height: 1
  +below($tablet)
    font-size: 2rem

.skoulp__seo
  pageWrapper()
  h1, h2, h3, h4
    color: #fff
