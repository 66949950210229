.tretti-bf__kickstart
  background-color: #000
  color: #fff
  background-size: cover;
  margin-top: -30px
  padding-bottom: 20px
  +below($wide)
    margin-top: 0

  &-content
    pageWrapper()
    +below($desktop)
      padding 0

  &-qliro
    margin-top: 100px

  &-carousel
    pageWrapper()

  &-seo
    pageWrapper()
    color: #fff
    h1, h2, h3, h4
      color: #fff
