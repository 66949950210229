$star-width = 18px
$star-width-mobile = 14px
$star-margin-between = 4px

.star-rating
  position relative
  flex-center-children()

.star-rating__back,
.star-rating__front
  svg
    box($star-width)
    margin-right $star-margin-between
    +below($tablet)
      box($star-width-mobile)
    &:last-child
      margin-right 0

.star-rating__back
  svg
    stroke-width 4px
    stroke $star_rating_stroke_color
    fill transparent

.star-rating__front
  position absolute
  top 0
  left 0
  overflow hidden
  white-space nowrap
  svg
    fill $star_rating_color
