$white-ish = rgb(232,232,232)

.styleguide__sidebar
    background-color: $styleguide_nav_background
    color #fff
    width: 330px
    padding: 0 0 0 50px
    display: flex
    flex-direction: column
    justify-content: space-between
    box-shadow: 0 0 15px rgba(0,0,0,.3)
    position: absolute
    left: 0
    top 0
    bottom: 0
    z-index: 0
    h1
        width: 20px
        line-height: 1.12
        font-weight: 100
        word-break: keep-all
        overflow-wrap: normal
        margin-bottom: 30px
        color #fff
    nav
        border-left solid 1px $white-ish
        padding: 18px 0 30px 0
        flex-grow: 2
    ul
        zero-list()
    li
        padding: 14px 0
    a
        color $white-ish
        padding-left 36px
        font-size: .875rem
        position: relative
        display: block
        &:before
            content: ''
            display: block
            circle(7px)
            border solid 2px $styleguide_nav_background
            background-color: $white-ish
            position: absolute
            left -6px
            top 4px
            transition: all 500ms ease-out
        &.selected
            color #fff
            &:before
                circle(9px)
                border solid 1px $styleguide_nav_background
                box-shadow: 0 0 8px rgba(255,255,255, .9)
