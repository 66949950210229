.brand_whiteaway.christmas
  .header-main__logo
    +above($desktop)
      height 68px
      margin-top: -9px
  .usp__links
    svg
      fill: #b20000

.brand_tretti.christmas
  .header-main__logo
    width: 140px;
    height: 120px;
    +below($desktop)
      height: 52px;
      width: 65px;
      left: -8px;
