.blog-author-list
  lost-flex-container row
  display flex
  justify-content space-between
  flex-wrap wrap

.blog-author-list-box
  position relative
  cursor pointer
  lost-column 1/3 3 30px
  color: $body_text_color;
  box-shadow: 1px 3px 8px rgba(0,0,0,.2)
  margin-bottom: 30px
  border-radius: 2px
  padding: 20px 20px 10px
  display flex
  flex-direction column
  align-items center
  transition all 0.2s ease-in
  background-color: #fff
  &:hover
    text-decoration none
    transform: scale(1.05)
  +below($wide)
    lost-column 1/3 3 20px
    margin-bottom: 20px
  +below($desktop)
    lost-column 1/2 2 20px
  +below($mobile)
    lost-column 1/1 1 0px
    margin-bottom: 15px

.blog-author-list-box__image
  circle(100px !important)
  background-color #aaa
  margin-bottom 10px

.blog-author-list-box__name
  padding 0
  margin 0 0 10px

.blog-author-list-box__bio
  font-size 0.875rem
  color #777
  text-align center
  padding 0
  margin 0 0 10px
