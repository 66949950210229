.glide__arrow
  position: absolute;
  display: block;
  top: 50%;
  z-index: 2;
  color: white;
  text-transform: uppercase;
  cursor: pointer;
  transition: opacity 150ms ease, border 300ms ease-in-out;
  transform: translateY(-50%);
  line-height: 1
  box($content_slider_arrow_size)
  border: none
  background-color: $content_slider_arrow_background
  padding $content_slider_arrow_padding
  svg
    box(100%)
    stroke-width 5px
    fill: $content_slider_arrow_fill

.glide__arrow:focus
  outline: none

.glide__arrow--left-accordion
  left: 0
  +below($wide)
    left -20px
  +below($tablet)
    left 14px
    
.glide__arrow--right-accordion
  right: 0
  +below($wide)
    right -20px
  +below($tablet)
    right 14px

.glide__arrow--left
  left: -50px
  +below($wide)
    left -20px
  +below($tablet)
    left 14px

.glide__arrow--right
  right: -50px
  +below($wide)
    right -20px
  +below($tablet)
    right 14px

.glide__arrow--disabled
  opacity: 0.33

.glide__bullets
  position: absolute;
  z-index: 2;
  bottom: -24px
  left: 50%;
  display: flex;
  align-items: center;
  list-style: none;
  transform: translateX(-50%)
  +below($tablet)
    display: none

.glide__bullets-accordion
  position: absolute;
  z-index: 2;
  left: 50%;
  display: flex;
  align-items: center;
  list-style: none;
  transform: translateX(-50%)
  +below($tablet)
    display: none

.glide__bullet
  box(10px)
  border solid 1px $content_slider_dot_border_color
  border-radius: $content_slider_dot_border_radius
  display: inline-block;
  margin: 2px
  cursor: pointer;
  transition: all 100ms ease-in-out;
  padding 0

.glide__bullet:focus
  outline: none

.glide__bullet:hover,.glide__bullet:focus
  background-color: $content_slider_dot_background_color_active

.glide__bullet[class*=active]
  background-color: $content_slider_dot_background_color_active

.glide--swipeable
  cursor: grab;

.glide--dragging
  cursor: grabbing;
