.insurance-table
    &__atb-container
        display: flex;
        justify-content space-between
    
    &__plp-variant
        &--terms-container
            padding-top: 12px
            padding-bottom: 12px
            
    &__product-image-container
        position: relative;
        display flex
        flex-wrap wrap
        +below($mobile)
            flex-wrap nowrap

    &__product
        &--shield
            fill: $success_color;
            width: 30px;
            position: absolute;
            bottom -5px
            left 65px
            +below($tablet)
                bottom -25px
                left 50px

        &--text
            padding-left 25px
            font-weight 700

        &--image
            +below($mobile)
                width 65px
                height 65px  
                  
    &__logo-provider
        width: 48px
        height: 20px

    &__logo-provider-se
        &--full
            width: 80px;
            height: 22px;
            +below($tablet)
                display none
        &--small
            width 20px
            height 20px
            +above($tablet)
                display none

    &__product-price
        &--readMore-variant
            padding-bottom 8px 
            display flex
            gap 8px

    &__add-button
        text-align right
        min-width fit-content
        width 150px
        
        &--desktop
            +below($mobile)
                display none

        &--mobile
            display: flex;
            justify-content: space-between;
            padding-top: 12px;
            +above($mobile)
                display none

    &__container
        grid-template-columns: 1fr 100px 110px 100px;
        display: grid;
        justify-content:center;
        align-items:center;
        +below($mobile)
            grid-template-columns: 1fr 60px 80px 60px;

    &__row
        display contents

    &__cell
        &--grey-row
            background-color $neutral_lightest_color
        display flex 
        height 100%
        align-items center
        justify-content center

        &--first-column
            padding 20px 24px
            font-size $font_size_base
            font-weight 700
            display flex 
            align-items center
            word-break break-word
            +below($tablet)
                font-size 12px
                padding 14px 8px
        
        &--headline
            font-size $font_size_base
            font-weight 700
            padding 20px 0
            display flex 
            align-items center
            flex-flow column
            text-align center
            +below($tablet)
                font-size 12px

        &--subline
            font-size 14px
            padding 14px 24px
            display flex 
            align-items center
            height 100%
            word-break break-word
            +below($tablet)
                font-size 12px
                padding 14px 8px

    &__icon
        height: 20px;
        width: 20px;

        &--checkmark
            stroke #00966c
            fill #00966c 

        &--terms-questionmark-container
            +above($mobile)
                display flex 
                align-items center
                gap 8px      

        &--terms-plp
            padding-bottom 8px
            font-size 14px
            
        &--terms-questionmark
            width 16px
            height 16px
            +below($mobile)
                margin-bottom -3px

    &__terms-text-wrapper
        font-size 14px
        padding-top 8px
        +above($mobile)
            display flex
            flex-direction column
        div
            padding-bottom 8px
          

.text-expand__links--vertical-gradient
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0.67%, #FFFFFF 100%);
    width 100%
    padding-top: 70px;
    display: flex;
    flex-flow: row-reverse;

