.main-nav__mega-menus
  position: absolute;
  width: 100%
  top 0
  left 0

.mega-menu
  position: absolute;
  top $nav_height
  z-index: $mega_menu_Z
  background-color: #fff;
  left: 0
  width: 100%
  padding: $mega_menu_padding
  border-bottom: $mega_menu_border_bottom
  border-radius: $mega_menu_border_radius
  transform: scale3d(.1, .1, 1)
  transform-origin: 50% 0
  transition: all 350ms ease-out, opacity 300ms
  transition-delay: 100ms
  font-size: .9rem;
  opacity: 0
  box-shadow: 3px 4px 26px rgba(0,0,0,.5)
  pointer-events: none;
  a
    color: $black
  &.shown
    transform: scale3d(1, 1, 1)
    z-index: $mega_menu_Z + 1
    pointer-events: all;
    opacity: 1
  &:nth-child(1)
    transform-origin: 0 0
  &:nth-child(2)
    transform-origin: 110px 0
  &:nth-child(3)
    transform-origin: 240px 0
  &:nth-child(4)
    transform-origin: 350px 0
  &:nth-child(5)
    transform-origin: 480px 0
  &:nth-child(6)
    transform-origin: 610px 0


.mega-menu__lvl1
  zero-list()
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;

.mega-menu__lvl1__item
  display: inline-block;
  lost-column $mega_menu_columns_amount
  background-color: #fff
  padding: $mega_menu_columns_padding
  border-radius: 2px

.mega-menu__lvl1__header
  display: flex;
  flex-direction: $mega_menu_header_direction;
  align-items: $mega_menu_header_position;

.mega-menu__lvl1__img
  height: $mega_menu_icon_height;
  width: $mega_menu_icon_width;

.mega-menu__lvl1__caption
  display: inline-block;
  line-height: 1
  min-width: 60%
  text-transform: $mega_menu_caption_text_transform
  margin: $mega_menu_caption_margin
  font-weight: $mega_menu_caption_text_weight

.mega-menu__lvl2
  zero-list()
  margin: $mega_menu_lvl2_margin

.mega-menu__lvl2__item
  border-radius: 2px
  transition: background-color 50ms
  position: relative;
  &:hover
    background-color: $mega_menu_lvl2_item_hover_background_color
  &:before
    content: $mega_menu_caret_icon
    color $mega_menu_caret_color
    position: absolute;
    left 0px
    top 0px
    font-size: 1rem

  a
    display: block;
    padding: $mega_menu_lvl2_item_padding
    font-size: $mega_menu_lvl2_item_font_size
    overflow: hidden;
    text-overflow: ellipsis;
    &:hover
      text-decoration: none;
      color $mega_menu_lvl2_item_hover_color

.mega-menu__lvl2__category
  font-weight: normal;
  &:focus
    background-color: rgba(0,0,0,0.25)

.mega-menu__brands
  padding 30px 40px

.mega-menu__brand-list
  lost-flex-container row
  width 100%

.mega-menu__brand-item
  lost-column 1/6 6 40px
  margin 10px 0px 30px
  padding 12px 12px 7px
  border 2px solid #ececec

.mega-menu__brand-link
  display flex
  align-items center
  justify-content flex-end
  a
    display flex
    align-items center
    color $body_text_color
    font-weight bold
    font-size 1.2rem
  svg
    box(16px)
    margin-left 10px

.mega-menu__close
  position: absolute
  right: 15px
  top: 8px
  font-size: 1.9rem
  line-height: 1
  cursor: pointer
  z-index 1
  svg
    box(16px)
    fill: #777
    &:hover
      fill alpha(#777, .8)
