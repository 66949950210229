.srp-nested-wrap
  standardSection()
  margin-bottom 50px
  p
    margin 0 0 10px
  img
    margin-bottom 10px

.srp-nested__categories
  lost-center $page_width
  margin-top 10px

.srp-nested__category
  lost-column 1/4
  background-color #fff
  border: 1px solid $srp_product_box_border_color;
  border-radius: $srp_product_box_border_radius;
  box-shadow: $srp_product_box_box_shadow;
  padding: 15px 0 15px 0
  text-align: center;
  margin-bottom: 20px
  color: #333;
  transition: transform 200ms ease-in
  +below($desktop)
    lost-column 1/3
  +below($tablet)
    lost-column 1/2
  +below($mobile)
    lost-column 1/1
    margin-bottom 10px
  &:hover
    text-decoration: none;
    +above($desktop + 1)
      transform: scale(1.07);

.srp-nested__category-flex
  display: flex
  align-items: center;
  padding: 15px
  min-height: 72px
  img
    box(40px)
    margin: 0 10px 0 0
  .srp-nested__category-name
    display: flex;
    flex-grow: 2

.srp-body__category-img
  margin-bottom: 10px

.srp-nested__category-name
  display: flex
  align-items: center;
  justify-content: center;
  font-size: .9rem

.srp-nested__category-label
  truncate()
  max-width: 90%

.srp-nested__category-icon
  box(16px)
  margin-left: 5px
  fill: #333;
  max-width: 10%
