
.menu-items__favorite-cart-item
  display flex
  position absolute
  right: 25px
  top: 127px
  z-index: 999
  +below($desktop)
    right: 100px;
    width: auto
    display flex
    top 0
  .favorites-widget
    box(58px)
    svg
      fill #fff
  .favorites-widget__amount, .basket-widget__amount
    width 20px
    height 20px
    line-height 20px
    border-radius 20px
    top 10px
    right 0
  .basket-widget
    width 60px
    height 60px
    background-color transparent
    svg
      fill #fff
.main-nav
  margin: auto
  position: relative;
  margin-top: -30px;

.menu-items__item
  &:first-child
    margin-left: 0;

  a
    padding: 0 10px
    .shop_TRETNO &
      padding: 0 6px

  span
    border-right: 1px solid #dd1870;
    padding-right: 20px;
    .shop_TRETNO &
      padding-right 12px

  .shown
    &:after
      margin-left -22px
      .shop_TRETNO &
        margin-left -19px


.menu-items__offers-item
  margin-left 0
  a
    display flex
  .menu-items-offer-link__inner
    &:hover
      color alpha($navigation_text_hover_color, .8)
    &::after
      width: 100%
.menu-items__offers-link
  span
    padding-left 0
    border-right none