$wizard_title_height = 66px
$wizard_legend_height = 70px
$wizard_footer_height = 70px

.srp-wizard-wrap
  position fixed
  top 0
  left 0
  width 100vw
  height 100vh
  z-index $gallery_overlay
  display flex
  justify-content center
  align-items center
  +below($tablet)
    display none

.srp-wizard__backdrop
  top 0
  left 0
  position absolute
  box(100vw, 100vh)
  background-color rgba(0, 0, 0, 0.65);
  z-index -1

.srp-wizard__box
  position relative
  width 800px
  max-width: 96%
  background-color #fff

.srp-wizard__box__header
  position relative
  padding-bottom 10px
  .wizard-title
    height $wizard_title_height
    font-size $srp_wizard_title_font_size
    font-family $font_family_header
    text-transform $srp_wizard_title_text_transform
    flex-center-children()
  .wizard-close
    position absolute
    top 0
    right 0
    line-height 1
    padding 5px 10px
    font-size 2rem
    cursor pointer
    color #aaa
    transition: all .3s ease
    &:hover
      color: #333
      transform: scale(1.1);
    &:active
      transform: scale(0.9);
      transition: all .15s ease

.srp-wizard__box__header__legend
  height $wizard_legend_height
  background-color $srp_legend_bg_color
  display: flex;
  align-items: center;
  justify-content:flex-start;
  .step
    position: relative;
    padding: 5px 5px 5px 40px
    height: 100%
    display: flex;
    align-items: center;
    color: #fff
    &:first-child
      padding-left 30px
    &:before,
    &:after
      z-index: 2
      position: absolute;
      top 0
      right: -21px
      content ""
      box(0)
      border-bottom: 35px solid transparent
      border-top: 35px solid transparent
      border-left: 20px solid #fff
    &:after
      z-index: 3
      right: -20px
      border-left-color: $srp_legend_bg_color
    &.active,
    &.done
      background-color $brand_color
      &:after
        border-left-color: $brand_color
      .step-number
        color $brand_color
    &.disabled
      color rgba(255, 255, 255, 0.4);
      .step-number
        background-color #ddd
        color #bbb
  .step-number
    circle(21px)
    background-color: #fff
    flex-center-children()
    margin-right: 7px
    font-size: .7rem
    line-height: 1
    color: #aaa

.srp-wizard__box__body
  padding 10px 30px 20px
  height 'calc(80vh - %s - %s - %s)' % ($wizard_title_height $wizard_legend_height $wizard_footer_height)
  overflow: auto;
  .step-title
    font-size 1.25rem
    font-family $srp_wizard_step_title_font_family
    padding-bottom 10px
  .step-desc
    margin-top -5px
    margin-bottom 15px

.srp-wizard__box__body__options
  lost-flex-container row
  width 100%
  .step-image
    lost-column 1/4 4 15px
    background-color #fff
    margin-bottom 20px
    img
      padding 5px
      border 1px solid #ececec
  .card
    cursor pointer
    position relative
    lost-column 1/4 4 20px
    margin-bottom 20px
    padding 15px
    background-color #fff
    border 1px solid #ececec
    transition all .3s ease
    overflow-wrap: break-word;
    word-break: break-word;
    hyphens: auto;
    &.active,
    &:hover
      box-shadow 0 5px 15px rgba(0,0,0,0.25)
      transform scale(1.05)
    &.disabled
      pointer-events: none;
      opacity: 0.35
      &:hover
        box-shadow: none;
    > div
      padding: 0px 5px 5px
      &:last-child
        padding-bottom 0
  .feature-image
    padding 0
  .feature-title
    line-height 1.2
  .feature-text
    color #aaa
    font-size 0.75rem
  .abundance
    font-size 0.75rem
    color #aaa
  .radio-button
    position absolute
    top 3px
    right 23px
  .checkbox
    position absolute
    top 12px
    right 10px
    &.checkbox__card
      pointer-events: none;
      padding 10px
    .checkbox__custom
      box(18px)
      svg
        box(13px)
  .range-type
    font-size 0.75rem
    padding-bottom 5px
    div
      text-align center
  .range-type__image
    padding 0
    background-color #ececec
    flex-center-children()
    height 100px
    font-size 2rem
    margin-bottom 10px
    margin-top 10px
  div.range-type__between,
  div.range-type__and-sign
    padding-bottom 0
  .range-type__value
    font-size 1.25rem

.srp-wizard__box__footer
  height $wizard_footer_height
  padding 0 30px
  display flex
  justify-content flex-end
  align-items center
  background-color #ececec
  border-top 1px solid #ccc
  .button
    margin-left 10px
    min-height: 42px
