


.cof__address-primary, .cof__address-alternative, .cof__address-secondary
  lost-center $cof_center_col_width

.cof__address-secondary
  margin-top: 20px

.cof__address-row
  width: 100%
  margin-bottom: 10px
  lost-flex-container row

.cof__address-newsletter
  padding 4px 0 8px

.cof__address-alternative
  border solid 1px rgba(232,232,232,1)
  border-radius 5px
  width: 100%
  padding: 12px 20px
  background-color: rgba(250,250,250,1)
  font-size .875rem
  margin-top: 16px
  color $cof_li_item_font_color

.cof__address__city
  input
    text-transform capitalize