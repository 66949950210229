.cof__delivery
  background-color: $cof_section_bg

.cof__delivery-wrapper
  border-left $cof_section_border
  border-right: $cof_section_border
  padding: 30px
  width: 100%
  +below($tablet)
    padding 30px
  +below($mobile)
    padding 15px 8px

.cof__delivery-inner
  lost-center $cof_center_col_width
  flex-direction: column;
  h3
    +below($mobile)
      font-size 1rem

.cof__delivery-txt-wrapper
  width 50%
  +below($tablet)
    width 100%
  .custom-text-input-wrapper
    margin-bottom 20px

.cof__delivery-use-alt
  border solid 1px #ccc
  border-radius 5px
  padding: 12px 20px
  margin-bottom: 10px
  background-color: rgba(250,250,250,1)
  font-size .875rem
  color $cof_li_item_font_color
