.vip-outer
  display: flex;
  flex-direction: column;
  align-items: center;

.vip-outer > section:last-of-type
  padding-bottom: $vip_last_section_padding;
  +below($tablet)
    padding-bottom: 10px;


.vip__gallery-description-wrapper
  position relative
  width $page_width
  display flex
  justify-content: space-between;
  padding: $gallery_outer_padding
  +below($wide)
    padding: 30px 30px 40px
    width 100%
  +below($desktop)
    padding: 20px 20px 30px
    flex-direction column
  +below($tablet)
    padding: 20px 20px 30px
  +below($mobile)
    padding: 10px 10px 30px

.vip__product-info
  position relative
  width: 43%
  +below($desktop)
    width: 100%
