.confirmation
  width: $page_width
  margin: 40px auto 0 auto;
  +below($wide)
    width: 100%

.confirmation__module
  +below($wide)
    padding 0 20px

.confirmation__date
  color: #bfbfbf
  font-size: .8rem
  display: flex;
  div
    width: 40px
    text-align: center;

.confirmation__products-header
  font-weight: bold;
  margin-top: 36px
  margin-bottom: 15px

.confirmation__wrapper
  display: flex
  align-items flex-start
  +below($desktop)
    flex-wrap: wrap;

.confirmation__products
  display: flex;
  width: 50%
  flex-wrap: wrap;
  +below($desktop)
    width 100%
  .cof__services-card
    width: 100%

.confirmation__image
  margin-left: 30px
  margin-bottom: 30px
  text-align: center;
  img
    margin-bottom: 20px
  +below($desktop)
    margin-left: 0
    width 100%

.confirmation__facebook
  text-align: left
