.tretti-bf__main
  background-color: #000
  color: #fff
  margin-top: -30px
  padding-bottom: 20px
  +below($wide)
    margin-top: 0

  &-container
    pageWrapper()

  &-brands
    background-color: #f5f5f5
    padding: 150px 0 150px 0
    margin: 120px 0 90px 0
    background-image: url(//images.wagcdn.com/f/landingpages/blackfriday2018/main/tretse/repeat-top-fade.jpg), url(//images.wagcdn.com/f/landingpages/blackfriday2018/main/tretse/repeat-bottom-fade.jpg)
    background-position: top, bottom;
    background-repeat: repeat-x;
    a:hover
      text-decoration: none;
    span
      position: relative;
      display: flex
      align-items: center;
      justify-content: center;
      margin-top: 8px
      margin-bottom: 14px
      color: #333
      font-size: 1.1rem
      font-family: $font_family_header
      &:after
        margin-left: 7px
        content ""
        box(0)
        border-bottom: 6px solid transparent
        border-top: 6px solid transparent
        border-left: 9px solid #333

  &-carousel
    pageWrapper()

  &-seo
    pageWrapper()
    color: #fff
    h1, h2, h3, h4
      color: #fff
