.brand__about
  margin-top: 40px;
  margin-bottom: 40px;

.brand__about-intro
  lost-center: 100%;
  h2
    margin-top: 0

.brand__about-intro-img
  lost-column: 1/3;

.brand__about-intro-text
  lost-column: 2/3;

.brand__about-entries
  lost-center: 100%;

.brand__about-entry
  lost-column: 1/3;
  +below($mobile)
    lost-column: 1/2;
