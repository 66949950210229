.glide__slides .read-more-link
  cursor pointer

button.read-more-link
  border none
  background inherit
  padding 0
  color $anchor_text_color
  text-align left

  &:hover
    text-decoration underline

.insurance-card
  .accessory-image
    svg
      padding 0

  .insurance-overlay-footer__container
    margin 0 -16px -16px
    padding 16px

    +mobile()
      margin 0 -12px -12px
      padding 12px

  .insurance-overlay__sub-item
    p
      margin 0

.accessory-card
  .header
    .accessory-image
      position relative

  ul
    list-style-type none
    padding 0
    margin 8px 0
    
    +mobile()
      margin 8px 0

    li:before
      content "•"
      padding-right 8px
      padding-left 8px

  ul > ul
    margin 0

.accessory-card,
.insurance-card
  display flex
  flex-direction column
  padding 16px
  gap 12px
  background white
  border 1px solid #E0E0E0
  border-radius 8px
  color #242424
  height 100%
  overflow visible

  +mobile()
    padding 12px

  h4
    font-size 18px
    line-height 24px
    font-weight 800
    margin 0
    padding 0

    +mobile()
      font-size 16px

  h3
    overflow hidden
    display -webkit-box
    -webkit-box-orient vertical
    -webkit-line-clamp 2
    font-size 18px
    line-height 24px

    +mobile()
      font-size 16px

  button.read-more-link
    margin-top 4px

  .atb-content
    display flex
    font-weight 800
    font-size 16px
    justify-content center
    align-items center
    gap 8px

  .header
    display flex
    gap 20px
    justify-content center
    align-items stretch
    height 100%

    +mobile()
      flex-direction column

    & + *
      margin-top 12px

  .subheader
    font-weight 800
    font-size 16px
    line-height 24px

    +mobile()
      font-weight 400

  .content
    display flex
    flex-direction column
    gap 8px
    flex 1 0px
    justify-content space-between

    > *
      display flex
      flex-direction column
      gap 8px

  .accessory-brand
    font-size 14px
    line-height 20px

  .rating
    display flex
    flex-wrap wrap
    gap 12px
    row-gap 8px
    line-height 18px
    align-items center
    margin-top 4px

  .accessory-image
    display flex
    align-items center
    justify-content center
    flex 1 0px

    img,svg
      padding 20px

    svg
      max-width 140px
      width 100%
      aspect-ratio 3 / 2

  .accessory-description
    font-size 16px
    line-height 24px
    overflow hidden
    display -webkit-box
    -webkit-box-orient vertical
    -webkit-line-clamp 2

    p
      margin 0

    ul
      padding 0
      list-style-type none
      
      li:before
        content '•'
        padding-left 4px
        padding-right 8px

  .accessory-price
    margin-top 4px
    font-size 26px
    font-weight 800

  .accessories__item-button
    margin 0

  .accessories__item__tick
    top 10px

  .info-header
    font-weight 800
    font-size 18px
    line-height 24px

    +mobile()
      font-size 16px

  .accessory-atb
    display flex
    flex-direction column
    height 48px
