.tab__panel__outer
  font-size: .9rem
  .b2b &
    box-shadow: 0px 0px 0px 4px $brand_color
    background-color: $brand_color

.tab__panel__inner
  background-color: #fff;
  border: 1px solid #c8c8c8;
  padding: 14px;
  margin-top: -1px;

  &.bg-danger
    background-color: $OS_danger_color;

  &.bg-warning
    background-color: $OS_warning_color;

  &.bg-success
    background-color: $OS_success_color;

.tab__panel__btns
  display: flex;
  justify-content space-between
  margin-top: 2px;

.tab__panel__btns__inner
  display flex

.tab__panel__btn
  padding: 7px 12px;
  background: #F2F2F2;
  border: 1px solid #c8c8c8;
  border-right: 0;
  cursor: pointer;
  color: #333
  a
    color initial
  &.right__btn
    float right
  &:last-child
    border-right: 1px solid #c8c8c8;
  &.btn-active
    background: #fff;
    border-bottom: 1px solid #fff;
  &:hover
    text-decoration: none
    color: #333

.tab__panel__tab
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  height: 0;
  transition: opacity 200ms ease-in;

  &.tab-active
    opacity: 1;
    height: auto;
    visibility:visible;
    pointer-events:auto;
.tab__panel__table
  width: 100%;
  border-collapse: collapse;
  a
    color $black
    text-decoration underline

  th
    text-align: left;
    border-bottom: 1px solid $gray_border;

  th,
  td
    padding: 2px 4px;
