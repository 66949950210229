.vip__upsale__outer
  width: 100%
  background: #fff;
  opacity: 1;
  transition: all 300ms ease;

  &.close-upsale
    opacity: 0;
    pointer-events: none;
    max-height: 0;

.vip__upsale
  max-width $page_width
  margin: auto;
  display: flex;
  background-color: #FEFCD6;
  background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23dbd8af' fill-opacity='1' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
  border-radius: 4px
  padding: 16px;
  justify-content: space-between;
  +below($tablet)
    padding-bottom: 10px;
    flex-direction: column;

.vip__upsale__description
  display: flex;
  width: 33%;
  font-size: 1.2rem
  +below($desktop)
    width: 28%;
  +below($tablet)
    width: 100%;
    order: 3;
    margin-top: 5px;
  +below($mobile)
    font-size: 1rem

.vip__upsale__arrow
  display: flex;
  width: 12%;
  justify-content: flex-end;
  align-items: flex-end;
  +below($tablet)
    display: none;

  svg
    height: 26px;
    margin-bottom: 30px;
    +below($desktop)
      height: 16px;
      margin-bottom: 10px;


.vip__upsale__product
  display: flex;
  position: relative;
  width: 50%;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0px 4px 30px 0 rgba(66, 63, 0, 0.2);
  padding: 10px;
  color: $body_text_color;
  &:hover,
  &:active
    text-decoration: none;
  +below($desktop)
    width: 55%;
  +below($tablet)
    width: 100%;
    order: 2;

  .rating-stars
    margin-right 5px
    +below($mobile)
      width 90px
      height 15px

  .rating-stars__back,
  .rating-stars__front__inner
    +below($mobile)
      height 15px
      width 90px
      svg
        width 15px
        height 15px

.vip__upsale__product__img
  margin-right: 20px;
  +below($tablet)
    margin-right: 10px;

.vip__upsale__product__data
  width: 100%;
  font-size: .9rem;
  +below($desktop)
    font-size: .8rem;
  +below($tablet)
    font-size: .75rem;
  +below($mobile)
    max-width: 60%

  ul
    padding-left: 18px;
    margin: 8px 0 0 0

.vip__upsale__product__name
  truncate()
  font-size: 1.1rem;

.vip__upsale__product__rating
  display: flex

.vip__upsale__product__stock
  .in-stock
    color $in_stock_color

.vip__upsale__product__price
  position: absolute;
  right: 13px
  bottom: 13px
  font-size: 1.8rem
  line-height: 1.1
  +below($tablet)
    font-size: 1.3rem
    right: 10px
    bottom: 10px
  +below($mobile)
    position initial
    bottom initial
    right initial
    margin 7px 5px 5px
    text-align right

  .before-price
    font-size: 0.8rem;
    text-decoration: line-through;
    +below($tablet)
      font-size: 0.7rem


.vip__upsale__close
  height: 38px;
  display: flex
  justify-content: flex-end;
  width: 6%;
  font-size: 50px;
  line-height: 0.4;
  cursor: pointer;
  +below($desktop)
    width: 5%;
  +below($tablet)
    width: 100%;
    order: 1;
