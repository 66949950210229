
.header-main__blog-logo-desktop
  box(173px, 80px)
  +below($desktop)
    display: none;

.blog__logo-mobile
  display: none;
  flex 1 100%
  transition: opacity 100ms, width 300ms ease
  +below($desktop)
    box(150px, 35px)
    display: flex;
    flex-flow: row nowrap;
  svg
    max-width: 100%
    max-height: 35px
  .search-active &
    display: none;

.blog__logo-hverdagen
  height 73%
  margin-bottom: 5px
  flex 1 auto
  svg
    fill $black
  +below($desktop)
    height: 100%
    svg
      fill #fff

.blog__logo-by-wrapper
  display: inline-block;
  margin-left: 10px

.blog__logo
  height: 100%
