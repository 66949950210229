.accordion-title
  box-shadow: 0px 1px 2px -1px rgba(36, 36, 36, 0.1);
  filter: drop-shadow(0px 1px 3px rgba(36, 36, 36, 0.05));
  border-radius: 8px;
  padding: 16px !important;
  margin-bottom: 0;
  margin-top: 0;
  background-color: #FFFFFF; 
  border: #E0E0E0 solid 1px;
  cursor: pointer
  margin-bottom: 0
  transition: background-color 0.2s ease
  text-transform: capitalize

.accordion-header 
  justify-content: space-between
  display: flex
  background-color: #FFFFFF
  &:hover {
    background-color:#f5f5f5;
  }
  
.accordion-header-active 
  background-color: #F5F5F5
  border-radius: 8px 8px 0 0;

.accordion-review-title
  margin-bottom: 0;
  margin-top: 0;
  @media (max-width: 768px){
  flex-grow: 0!important
  padding-right: 10px
  margin: 0!important
  }
  
.accordion-content-active
  padding-bottom: 16px;
  padding-top: 16px;
  box-shadow: 0px 1px 2px -1px rgba(36, 36, 36, 0.1);
  filter: drop-shadow(0px 1px 3px rgba(36, 36, 36, 0.05));
  max-height: fit-content;
  border: #E0E0E0 solid 1px!important;
  position: relative;

.accordion-read-more-btn-wrapper
  z-index: 2;
  display:none;
  position: absolute;
  left: 0;
  bottom: 0;
  background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 52%, rgba(255,255,255,0) 100%);
  width: 100%;
  height: 150px;

.read-more-btn
  display: block;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%,-50%);
  border-color: #FC4C02
  background: #ffff;
  color:#FC4C02;
  border: 1px solid #FC4C02;
  padding: 12px 24px;
  border-radius: 4px;
  &:hover{
    cursor: pointer;
  }

.read-more-btn-toggle
  display: block;
  
.read-more-btn-show 
  display: hide;


.vip-accordion
  padding: 10px
 
.vip-multiple-content-container
  display: flex
  @media only screen and (max-width: 1024px) {
  flex-direction: column
  }

.accordion-review-card
  margin-bottom: 30px;

.accordion-specification-spacing
  padding-top: 10px;

.accordion-specification
  padding: 15px 15px 0 15px;
 
.vip-accordion-content-extended-text
  max-height: 0px;
  padding-right: 15px;
  padding-left: 15px;
  border: 1px solid transparent


.vip-B
  width: 100%;

.vip__accordion-content-variant-b
  @media (max-width: 600px){
  padding-bottom: 0;
  }
  width: $page_width
  margin: 0 auto;
  +below($wide)
    width: 100%;
  h2
    font-size: 1.8rem
    padding-left:  $vip_headers_padding;
    +below($mobile)
      font-size 1.3rem

.vip__reviews-variant-b
  width: $page_width
  margin: 0 auto;
  border-top: 2px solid border-color
  +below($wide)
    width: 100%;
  h2
    font-size: 1.8rem
    border-left:  $vip_headers_border;
    padding-left:  $vip_headers_padding;
    +below($mobile)
      font-size 1.3rem
      margin: 0px 0px 10px 0px

.accordion-chevron{
  justify-content: center;
  align-items: center;
  align-self: center;
  transition: 0.2s
  svg{
      width: 15px
      height: 10px
  }
}
.rotate-chevron
  transform: rotate(180deg)
  transition: 0.2s
  padding: 0!important;

.accordion-chevron-review{
  margin-left: auto
  transition: 0.2s
  svg{
      width: 15px
      height: 10px
  }
}

.remove-margin
  margin: 0!important;

.accordion-mobile-review{
  @media (max-width: 768px){
  display:none;
  }
}
